import { setDataset,setDatasetData,uploadDataset,setShare } from "../actions/dataset";
import { setDatasets } from "../actions/datasets";
import { getUserProfile } from "../thunks/user";



export const getDataset = (id) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {
    dispatch(setDataset({ status:'loading', dataset: {}, error:'' }));
    dispatch(setDatasetData({ status:'loading', data: [], error:'' }));

    const response = await remote(
      "get",
      `/datasets/${id}`,
      null,
      "loading dataset summary data"
    );

    if (response.status !== 200) {
      // dispatch(setDataset({ dataset: {}, error: response.data.message }));
      dispatch(setDatasetData({ status: 'error', data: [], error: response.data.message }));
      return;
    }

    dispatch(setDataset({ status: 'loading', dataset: response.data.dataset, error:'' }));

    // now get the json data
    const signedURL = response.data.signedURL

    const s3response = await remote(
      "get",
      signedURL,
      null,
      "getting json from s3",
      false
    );

    // let dataset = createAggregations(s3response.data)
    let dataset = s3response.data //convert(s3response.data)
    dispatch(setDatasetData({ status: 'loaded', data: dataset, error:'' }));

  } catch (error) {
    console.log(error)
    dispatch(setDataset({ dataset: {}, error:'problem loading dataset' }));
    dispatch(setDatasetData({ status: 'error', data: [], error:'problem loading dataset' }));
  }
}

export const getDatasetSummary = (id) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setDatasetData({ status:'loading',  data:[], error:'' }));
    dispatch(setDataset({ status:'loading', dataset: {}, error:'' }));

    const response = await remote(
      "get",
      `/datasets/${id}`,
      null,
      "loading dataset summary data"
    );

    if (response.status !== 200) {
      dispatch(setDataset({ status: 'error', dataset: {}, error: response.data.message }));
      return;
    }

    dispatch(setDataset({ status: 'summary-loaded', dataset: response.data.dataset,  error:'' }));

  } catch (error) {
    console.log(error)
    // dispatch(setDataset({ dataset: {}, error:'problem loading dataset' }));
    dispatch(setDataset({ status: 'error', dataset: {}, error:'problem loading dataset' }));
  }
}


export const uploadDatasetFile = (type, name, file) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(uploadDataset({ uploading: true, error:'' }));
    let payload = { datasetTypeID:type,datasetName:name }

    // get the s3 presigned post request
    const response = await remote(
      "post",
      `datasets`,
      payload,
      "creating new dataset"
    );

    
    if (response.status !== 200) {
      dispatch(uploadDataset({ uploading: false, error:response.data.message }));
      return;
    }
    
    // now upload the file
    const presignedPost = response.data.data
    const formData = new FormData();
    formData.append("Content-Type", file.type);

    Object.entries(presignedPost.fields).forEach(([k, v]) => {
        formData.append(k, v);
    });

    formData.append("file", file);

    const s3response = await remote(
      "post",
      presignedPost.url,
      formData,
      "uploading to s3",
      false
      // ,'multipart/form-data'
    );

    if (s3response.status === 204) {
      // empty local datasets record to force reload after new updload
      dispatch(setDatasets({ status:'empty', data: [], error:'' }));

      dispatch(uploadDataset({ uploading: false,  error:'' }));
      dispatch(getUserProfile())
    } else {
      dispatch(uploadDataset({ uploading: false, error:response.data.message }));
    }

  } catch (error) {
    console.log(error);
    dispatch(uploadDataset({ loaded: false, error:'problem uploading dataset' }));
  }
};


export const appendDatasetFile = (id, file) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(uploadDataset({ uploading: true, error:'' }));
    // let payload = { datasetTypeID:type,datasetName:name }

    // get the s3 presigned post request
    const response = await remote(
      "put",
      `/datasets/${id}`,
      null,
      "adding to dataset"
    );

    
    if (response.status !== 200) {
      dispatch(uploadDataset({ uploading: false, error:response.data.message }));
      return;
    }
    
    // now upload the file
    const presignedPost = response.data.data
    const formData = new FormData();
    formData.append("Content-Type", file.type);

    Object.entries(presignedPost.fields).forEach(([k, v]) => {
        formData.append(k, v);
    });

  formData.append("file", file);

  const s3response = await remote(
    "post",
    presignedPost.url,
    formData,
    "uploading to s3",
    false
    // ,'multipart/form-data'
  );

  if (s3response.status === 204) {
    // empty local datasets record to force reload after new updload
    dispatch(setDatasets({ status:'empty', data: [], error:'' }));

    dispatch(uploadDataset({ uploading: false,  error:'' }));
    dispatch(getUserProfile())
  } else {
    dispatch(uploadDataset({ uploading: false, error:response.data.message }));
  }

  } catch (error) {
    console.log(error);
    dispatch(uploadDataset({ loaded: false, error:'problem uploading dataset' }));
  }
}

export const postShare = (id,email) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    let payload = { email:email }

    dispatch(setShare({ postingShare:true,  shareError:'' }));

    const response = await remote(
      "post",
      `/datasets/${id}/shares`,
      payload,
      "posting share request"
    );
    console.log(response)

    if (response.status !== 200) {
      dispatch(setShare({ postingShare:false,  shareError:response.data.message }));
      return;
    }

    dispatch(setShare({ postingShare:false,  shareError:'' }));

  } catch (error) {
    console.log(error)
    dispatch(setShare({ postingShare:false,  shareError:error.message }));
  }
}
