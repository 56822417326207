import React, { useRef, useEffect, useState } from 'react';
import { select } from 'd3';
import { useResizeObserver } from '../hooks/useResizeObserver';
import { touch2Mouse, shortMonthNames, isLeapYear, daysIntoYear, addDays } from '../utils/utils'

// Date.prototype.addDays = function(days) {
//   var date = new Date(this.valueOf());
//   date.setDate(date.getDate() + days);
//   return date;
// }

export const YearGrid = ({ data, selection, compareSelection, compareToSelection, onChange, maxValue }) => {

  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const dimensions = useResizeObserver(wrapperRef);
  const [mouseMove,setMouseMove] = useState(true)

  // find the offset for the first day of the year
  // let firstdate = new Date(data.daysdata.years[yearindex].date)
  let year = data.year
  let yearStartDate = new Date(year,0,1)
  let yearStartOffset = yearStartDate.getUTCDay()

  // determine how many weeks we need for the grid
  let daysInYear = isLeapYear(year) ? 366 : 365
  let weeksInGrid = daysInYear + yearStartOffset > 371 ? 54 : 53

  let compareGrain = null
  if (compareSelection && compareSelection.length === 10) {
    compareGrain = 'day'
  } else if (compareSelection && compareSelection.length === 7) {
    compareGrain = 'month'
  } else if (compareSelection && compareSelection.length === 4) {
    compareGrain = 'year'
  }

  // get the days for this year
  // let start = data.years[yearindex].dateindex
  // let end = yearindex >= data.years.length-1 ? data.days.length : data.years[yearindex+1].dateindex
  // let days = data.days.slice(start,end)

  
  
  useEffect(
    () => {
      
      const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();
      
      let selectedIndex = selection !== null && selection.length === 10 ? daysIntoYear(new Date(selection))+yearStartOffset-1 : null
      let selectedDate = selection !== null && selection.length === 10 ? new Date(selection) : null
      let shortDate = selectedDate !== null && selection.length === 10 ? shortMonthNames[selectedDate.getUTCMonth()] + ' ' + selectedDate.getUTCDate() : null


      let compareSelectedIndex = compareSelection && compareSelection.length === 10 && compareSelection.slice(0,4)===year ? daysIntoYear(new Date(compareSelection))+yearStartOffset-1 : null
      // let compareSelectedDate = compareSelection !== null && compareSelection.length === 10 && compareSelection.slice(0,4)===year ? new Date(compareSelection) : null
      // let compareShortDate = compareSelectedDate !== null && compareSelection.length === 10 && compareSelection.slice(0,4)===year ? shortMonthNames[compareSelectedDate.getUTCMonth()] + ' ' + compareSelectedDate.getUTCDate() : null
      
      let blue="#16B1FD"
      let darkblue="#0070D0"
      // let yellow="#EAA61B"
      let purple="#6341f6"
      let highlightColor = selection === compareToSelection ? purple : 'black'
      
      let margin = ({top: 30, right: 14, bottom: 10, left: 40});

      // build coordinates for grid
      let boxheight = (height-margin.top-margin.bottom)/7
      let boxwidth = (width-margin.left-margin.right)/53
      let boxes = []
      let monthStarts = []
      for (let i=0;i<weeksInGrid*7;i++){
        let date = (i<yearStartOffset || i>=yearStartOffset+daysInYear) ? null: addDays(yearStartDate,i-yearStartOffset)
        if (date && date.getDate() === 1) {
          monthStarts.push(i)
        }
        boxes.push({x:(Math.floor((i+7)/7)-1),y:i%7, scale: 0, date: date})
      }
      monthStarts.push(yearStartOffset+daysInYear)

      // fill grid with values
      for (let i=0;i<boxes.length;i++){
        if (boxes[i].date !== null) {
          let m = boxes[i].date.getUTCMonth()
          let d = boxes[i].date.getUTCDate()-1
          let value = (data.data[0][7].length<=m || data.data[0][7][m][7].length<=d) ? 0 : data.data[0][7][m][7][d][0]
          boxes[i].scale = value/maxValue
        }
      }

      let strokeColor = '#838297'
      let strokeWidth = 1

      const svg = select(svgRef.current)

      svg.selectAll('.grid')
        .data(boxes)
        .join('rect')  
        .attr('class','grid') 
        .attr("stroke", (d,i) => selection && selection===compareSelection && i===selectedIndex ? '#838297':'#f5f5f5' )
        .attr("fill", (d,i) => (i<yearStartOffset || i>yearStartOffset+daysInYear) ? 'white' : blue )
        .attr("fill-opacity", (d,i) => (i<yearStartOffset || i>yearStartOffset+daysInYear) ? 1.0 :d.scale )
        .attr("x", (d) => d.x*boxwidth+margin.left)
        .attr("y", d => d.y*boxheight+margin.top)
        .attr("width",boxwidth)
        .attr("height", boxheight)

      // draw the compare selection if it exists
        svg.selectAll('.compare').remove()
      if (compareSelectedIndex) {
        svg.append('rect')
          .attr('class','compare') 
          .attr("stroke", "#0070D0")
          .attr("stroke-width", 2 )
          .attr("stroke-opacity", 1.0)
          .attr("fill-opacity", 0.0 )
          .attr("x", boxes[compareSelectedIndex].x*boxwidth+margin.left)
          .attr("y", boxes[compareSelectedIndex].y*boxheight+margin.top)
          .attr("width",boxwidth)
          .attr("height", boxheight)
      } else if (compareGrain==='month'){

      }
      
      // draw day of week titles
      let week = ['S','M','T','W','T','F','S']
      svg.selectAll('.week')
        .data(week)
        .join('text')  
        .attr('class','week svg-label')
        .attr("x", margin.left-10)
        .attr("y", (d,i) => (i+1)*boxheight-(boxheight/2)+margin.top+3)
        .attr("text-anchor", "middle")
        .text(d=>d);

      // draw year
      svg.selectAll('.year').remove()
      svg.append('text')
        .attr('class',selection===year ? 'year svg-label3-darker' :'year svg-label3-dark')
        .attr("x", 0)
        .attr("y", height/2)
        .attr("text-anchor", "middle")
        .attr("transform", "translate(-60,80) rotate(-90)")
        .text(year);


      // draw month boundaries
      svg.selectAll('.month').remove()

      if (selection===year) {
        strokeColor = 'black'
        strokeWidth = 2
        if (selection===compareToSelection){
          strokeColor = purple
        }
      } else if (compareSelection===year) {
        strokeWidth = 2
        strokeColor = darkblue
      }

      // draw top line
      svg.append('line')
      .attr('class','month')
      .attr('x1', yearStartOffset === 0? margin.left: margin.left+boxwidth)
      .attr('y1', margin.top)
      .attr('x2', width-margin.right)
      .attr('y2', margin.top)
      .attr("style", `stroke:${strokeColor}`)
      .attr('stroke-width',strokeWidth)
  
      // draw bottom line
      svg.append('line')
      .attr('class','month')
      .attr('x1', margin.left)
      .attr('y1', height-margin.bottom)
      .attr('x2', yearStartOffset<6 ? width-margin.right-boxwidth : width-margin.right)
      .attr('y2', height-margin.bottom)
      .attr("style", `stroke:${strokeColor}`)
      .attr('stroke-width', strokeWidth)


      for (let i=0;i<monthStarts.length;i++){

        let startx = boxes[monthStarts[i]].x*boxwidth+margin.left
        if (boxes[monthStarts[i]].y>0) startx = startx + boxwidth
        let endx = width-margin.right
        if (i<11) endx = boxes[monthStarts[i+1]].y>0 ? boxes[monthStarts[i+1]].x*boxwidth+margin.left+boxwidth : boxes[monthStarts[i+1]].x*boxwidth+margin.left
        let xposition = (endx-startx)/2 + startx
        
        // if the compareSelection == this month, highlight dark blue
        // if the selection === the compare to selection and its this month, highlight purple
        // if the selection === this month highlight black
        // otherwise no highlight

        strokeColor = '#838297'
        strokeWidth = 1
        if ((selection && selection.length === 7 && (Number(selection.slice(5,7))===i+1 || Number(selection.slice(5,7))===i)) || (i===0 && selection===year) || (i===monthStarts.length-1 && selection===year)) {
          strokeColor = 'black'
          strokeWidth = 2
          if (selection === compareToSelection){
            strokeColor = purple
          } 
        } else if (compareSelection && compareSelection.length === 7 && compareSelection.slice(0,4)===year && ((Number(compareSelection.slice(5,7))===i+1 || Number(compareSelection.slice(5,7))===i) || (i===0 && compareSelection===year) || (i===monthStarts.length-1 && compareSelection===year))) {
          strokeColor = darkblue
          strokeWidth = 2
        }
        

          // draw month name
        svg.append('text')
          .attr('class',selection && selection.length === 7 && Number(selection.slice(5,7))===i+1 ? 'month svg-label2-darker':'month svg-label2')
          .attr("x", xposition)
          .attr("y",margin.top-12)
          .attr("text-anchor", "middle")
          .text(shortMonthNames[i]);

        // let strokeColor = '#838297'
        // let darker = (selection && selection.length === 7 && (Number(selection.slice(5,7))===i+1 || Number(selection.slice(5,7))===i)) || (i===0 && selection===year) || (i===monthStarts.length-1 && selection===year)
        // if (darker) strokeColor = 'black'
        

        // if this month is selected, draw the top and bottom highlighted lines
        if ((selection && selection.length === 7 && Number(selection.slice(5,7)) === i+1) || (compareSelection && compareSelection.length === 7 && Number(compareSelection.slice(5,7)) === i+1 && Number(compareSelection.slice(0,4)===year)) ) {
          // let s = Number(selection.slice(5,7))
          // draw top line
          svg.append('line')
            .attr('class','month')
            .attr('x1', startx)
            .attr('y1', margin.top)
            .attr('x2', endx)
            .attr('y2', margin.top)
            .attr("style", `stroke:${strokeColor}`)
            .attr('stroke-width',strokeWidth)

          // draw bottom line
          svg.append('line')
            .attr('class','month')
            .attr('x1', boxes[monthStarts[i]].x*boxwidth+margin.left)
            .attr('y1', height-margin.bottom)
            .attr('x2', boxes[monthStarts[i+1]].x*boxwidth+margin.left)
            .attr('y2', height-margin.bottom)
            .attr("style", `stroke:${strokeColor}`)
            .attr('stroke-width',strokeWidth)
        }
        

        // if it's not the top row, draw a line above the box
        if (boxes[monthStarts[i]].y>0) {
          svg.append('line')
            .attr('class', 'month')
            .attr('x1', boxes[monthStarts[i]].x*boxwidth+margin.left)
            .attr('y1', boxes[monthStarts[i]].y*boxheight+margin.top)
            .attr('x2', boxes[monthStarts[i]].x*boxwidth+margin.left+boxwidth)
            .attr('y2', boxes[monthStarts[i]].y*boxheight+margin.top)
            .attr("style", `stroke:${strokeColor}`)
            .attr('stroke-width',strokeWidth)

          svg.append('line')
            .attr('class','month')
            .attr('x1', boxes[monthStarts[i]].x*boxwidth+margin.left+boxwidth)
            .attr('y1', boxes[monthStarts[i]].y*boxheight+margin.top)
            .attr('x2', boxes[monthStarts[i]].x*boxwidth+margin.left+boxwidth)
            .attr('y2', margin.top)
            .attr("style", `stroke:${strokeColor}`)
            .attr('stroke-width',strokeWidth)
        }
        svg.append('line')
          .attr('class','month')
          .attr('x1', boxes[monthStarts[i]].x*boxwidth+margin.left)
          .attr('y1', boxes[monthStarts[i]].y*boxheight+margin.top)
          .attr('x2', boxes[monthStarts[i]].x*boxwidth+margin.left)
          .attr('y2', height-margin.bottom)
          .attr("style", `stroke:${strokeColor}`)
          .attr('stroke-width',strokeWidth)
      }
      
      svg.selectAll('.selected').remove()

      if (selection !== null && selection.length >8) {
        // draw the border around the selection
        svg.append('rect')  
          .attr('class','selected') 
          .attr("stroke", highlightColor)
          .attr("stroke-width", 2 )
          .attr('fill','none')
          // .attr("fill", purple )
          // .attr('fill-opacity',1.0)
          .attr("x", boxes[selectedIndex].x*boxwidth+margin.left)
          .attr("y", boxes[selectedIndex].y*boxheight+margin.top)
          .attr("width",boxwidth)
          .attr("height", boxheight)

        // draw the guide lines
        //right
        // svg.append('line')
        // .attr('class','selected dark')
        // .attr('x1', boxes[selectedIndex].x*boxwidth+margin.left+boxwidth)
        // .attr('y1', boxes[selectedIndex].y*boxheight+margin.top+boxheight/2)
        // .attr('x2', width)
        // .attr('y2', boxes[selectedIndex].y*boxheight+margin.top+boxheight/2)
        // .attr("stroke-opacity", 0.3 )

        // //left
        // svg.append('line')
        // .attr('class','selected dark fade')
        // .attr('x1', margin.left-4)
        // .attr('y1', boxes[selectedIndex].y*boxheight+margin.top+boxheight/2)
        // .attr('x2', boxes[selectedIndex].x*boxwidth+margin.left)
        // .attr('y2', boxes[selectedIndex].y*boxheight+margin.top+boxheight/2)

        //top
        svg.append('line')
        .attr('class','selected dark fade')
        .attr('x1', boxes[selectedIndex].x*boxwidth+margin.left+boxwidth/2)
        .attr('y1', boxes[selectedIndex].y*boxheight+margin.top-10)
        .attr('x2', boxes[selectedIndex].x*boxwidth+margin.left+boxwidth/2)
        .attr('y2', boxes[selectedIndex].y*boxheight+margin.top)

        // //bottom
        // svg.append('line')
        // .attr('class','selected dark fade')
        // .attr('x1', boxes[selectedIndex].x*boxwidth+margin.left+boxwidth/2)
        // .attr('y1', boxes[selectedIndex].y*boxheight+margin.top+boxheight)
        // .attr('x2', boxes[selectedIndex].x*boxwidth+margin.left+boxwidth/2)
        // .attr('y2', height)

        // draw selected date
        svg.append('rect')
          .attr('class','selected')
          .attr("x", boxes[selectedIndex].x*boxwidth+margin.left+boxwidth/2-25)
          .attr("y", boxes[selectedIndex].y*boxheight+margin.top-29)
          .attr("width", 50)
          .attr("height", 20)
          .attr("stroke", "#ddd")
          .attr("fill", "#fff")
        svg.append('text')
          .attr('class','selected svg-label-darker')
          .attr("x", boxes[selectedIndex].x*boxwidth+margin.left+boxwidth/2)
          .attr("y", boxes[selectedIndex].y*boxheight+margin.top-17)
          .attr("text-anchor", "middle")
          .text(shortDate);
      } 
      

      svg.on('touchstart', touch2Mouse,true )
      svg.on('touchmove', touch2Mouse,true )
      
      const handle = (e) => {
        if (!mouseMove) return;
        let x = e.clientX-svgRef.current.getBoundingClientRect().x
        let y = e.clientY-svgRef.current.getBoundingClientRect().y

        let column = Math.floor((x-margin.left)/boxwidth)
        let row = Math.floor((y-margin.top)/boxheight)

        // if the pointer is in the left margin or selection grain is year - select the year i.e. null
        if ((x<margin.left || compareGrain==='year') && compareGrain!=='day'  && compareGrain!=='month') {
          if (selection !== year) onChange(year,year)
          return
        }
        // if the pointer is in the top margin or selection grain is month - select the month 
        else if ((y<margin.top || compareGrain==='month') && compareGrain!=='day') {
          let monthNum = monthStarts.findIndex(i => i > column*7)
          if (monthNum<1) monthNum=1
          let mm = (monthNum<10) ? '0' + monthNum : '' + monthNum
          let s=''+year+'-'+mm
          if (selection !== s) onChange(s,year)
          return
        }
        // otherwise if the pointer is in the margins do nothing
        if (x<margin.left || x>width-margin.right-1
          || y<margin.top || y > height-margin.bottom-1) return

        // map to the selected index
        let index = column*7+row
        let d = boxes[index].date ? boxes[index].date.toISOString().substring(0,10) : year
        if (d && selection !== d) onChange(d, year)
      }

      const toggle = (e) => {
        setMouseMove((mouseMove) => !mouseMove)
      }


      svg.on('mousedown', handle, true)
      svg.on('mousemove', handle, true)
      svg.on('mouseup', toggle, true)
    },

    [ dimensions, selection, mouseMove, compareSelection, compareToSelection,compareGrain, data, daysInYear, maxValue, onChange, weeksInGrid, year, yearStartDate, yearStartOffset ])

      
  return (
      <div className="year-grid" ref={wrapperRef} style={{height: '100%', width: '100%'}}>
        <svg ref={svgRef} id='2021' style={{width:'100%',height:'100%'}}>
        </svg>
      </div>
  );
}