import React, { useRef, useEffect } from 'react';
import { select, scaleBand, scaleLinear, axisLeft, axisBottom, format, max, line } from 'd3';
import { useResizeObserver } from '../hooks/useResizeObserver';
import { touch2Mouse, findAncestor, dollarFormat, months, roundNumber } from '../utils/utils'


export const Scatter = ({ objects, indexes, adds, removals, ageOffset }) => {

  const colors = ['#46CEAC','#EB5564']
  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const dimensions = useResizeObserver(wrapperRef);

  
  useEffect(
    () => {

      const svg = select(svgRef.current)
      
      const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();


      // if there is no data remove the bars and return
      if (!indexes || indexes.length===0) {
        svg.selectAll('.bar').remove()
        svg.selectAll('.bar2').remove()
        return
      }

      
      let margin = ({top:10, right: 10, bottom:30, left: 30});

      let maxX = max(indexes, d => Number(ageOffset - objects[d][2]))
      let maxY = max(indexes, d => Number(objects[d][0]/100))
      
      const x = scaleLinear()
        .domain([0, maxX])
        .range([margin.left, width - margin.right])

      let y = scaleLinear()
        .domain([0, maxY])
        .range([(height-margin.bottom),margin.top])

      let xAxis, yAxis = null
      xAxis = g => g
        .attr("transform", `translate(0,${height - margin.bottom + 3})`)
        .call(axisBottom(x))
                // .tickSize(0)
                // .tickValues(x.domain().filter(function(d,i){ return !(i%(width>780 ? 1 : 5))})))
                // .tickFormat(form))
                // .tickFormat(labelFunction))
        .call(g => g.select(".domain").remove())
          
      yAxis = g => g
        .attr("transform", `translate(${width-10},0)`)
        .call(axisLeft(y).tickSize(width-margin.left-margin.right).ticks(2, format("3s")))
        .call(g => g.select(".domain").remove())

        svg.selectAll('.dots')
        .data(indexes)
        .join('circle')
        .attr('class','dots')
        .attr('cx', d => x(ageOffset - objects[d][2]))
        .attr('cy', d => y(objects[d][0]/100))
        .attr('r', 2)
        .attr('stroke','#16B1FD')
        .attr('fill','#16B1FD')
        .transition()

        svg.selectAll('.rmdots').remove()
        svg.selectAll('.rmdots')
          .data(removals)
          .join('circle')
          .attr('class','rmdots')
          .attr('cx', d => x(ageOffset - objects[d][2]))
          .attr('cy', d => y(objects[d][0]/100))
          .attr('r', 2)
          .attr('stroke','#EB5564')
          .attr('fill','#EB5564')

      if(adds.length>0) {
        svg.selectAll('.adddots').remove()
        svg.selectAll('.adddots')
            .data(adds)
            .join('circle')
            .attr('class','adddots')
            .attr('cx', d => x(ageOffset - objects[d][2]))
            .attr('cy', d => y(objects[d][0]/100))
            .attr('r', 2)
            .attr('stroke','#46CEAC')
            .attr('fill','#46CEAC')
      }

      svg.select('.y-axis')
        .call(yAxis)
        .select('.tick').remove()

      svg.select('.x-axis')
        .call(xAxis)
        .selectAll("text")
          .attr("y", 0)
          .attr("x", 9)
          .attr("dy", ".35em")
          .attr("transform", 'rotate(90)')
          .style("text-anchor", "start");

      svg.selectAll('.baseline').remove()
      svg.append('line')
        .attr('class','baseline')
        .attr('x1', x(0))
        .attr('y1', y(0))
        .attr('x2', x(maxX))
        .attr('y2', y(0))
        .attr('stroke','#ccc')
        .attr('fill','none')

        // tooltip code ...

        // let p = findAncestor(svgRef.current,"plot")
        // let t = p.querySelector('.tooltip')
        // t.style.visibility = 'hidden'
        // svg.selectAll('.highlight').remove()

        
        // // svg.on('touchstart', touch2Mouse,true )
        // svg.on('touchmove', touch2Mouse,true )
        // // svg.on('touchout', touch2Mouse,true )

        // const handle = (e) => {
        //   let x1 = e.clientX-svgRef.current.getBoundingClientRect().x
        //   let y1 = e.clientY-svgRef.current.getBoundingClientRect().y

        //   let index = x1
        //   index = index>=data.length ? index=data.length-1: index
        //   if (index<0 || y1<margin.top || x1>width-margin.right) {
        //     t.style.visibility = "hidden"
        //     svg.selectAll('.highlight').remove()
        //     return
        //   }

        //   let htmlString = `<h5 class="title" style="color:#444; padding-bottom:5px;">${data[index][0]}</h5>`;
        //   htmlString += `<p class="value" style="color:${colors[0]}">${data[index][1]}</span></p>`;
        //   htmlString += `<p class="value" style="color:${colors[1]}">${data[index][2]}</span></p>`;
          
        //   if (t) {
        //     t.style.visibility = "visible"
        //     let left = 'unset'
        //     let right = 'unset'
        //     let position = x(index)+x.bandwidth()/2
        //     if (e.clientX<width/2) {
        //       left = position+5 + 'px'
        //     } else {
        //       right = width-position+10 + 'px'
        //     }
        //     t.style.left = left
        //     t.style.right = right
        //     t.style.textalign = left
        //     t.innerHTML = htmlString;
        //   }
        //   svg.selectAll('.highlight').remove()
          
        //   svg.append('rect')
        //       .attr('class','highlight')
        //       .attr('x', x(index))
        //       .attr('y', margin.top)
        //       .attr('width', x.bandwidth())
        //       .attr('height', height-margin.bottom-margin.top)
        //       .attr('fill-opacity',0.0)
        //       .attr('stroke','#000')
          
        // }
        // svg.on('mousedown', handle,true )
        // svg.on('mousemove', handle,true)
      },

      [ objects, indexes, ageOffset, dimensions ])

  return (
    <div className="row border" >
       <div className="plot" ref={wrapperRef} style={{height:'400px',marginRight:'10px'}}>
        <div className="tooltip" styles={{visibility:'hidden'}}></div>
        <svg ref={svgRef} style={{width:'100%',height:'100%'}}>
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </div>
    </div>
  );
}