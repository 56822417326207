import React, { useRef, useEffect } from 'react';
import { select, scaleBand, scaleLinear, axisLeft, axisBottom, format, max, line } from 'd3';
import { useResizeObserver } from '../hooks/useResizeObserver';
import { touch2Mouse, findAncestor, dollarFormat, months, roundNumber, getMonth } from '../utils/utils'
// import {data} from '../../../testdata/splitBarTest'


export const SplitBar = ({data, selection, onChange}) => {

  const colors = ['#46CEAC','#EB5564']
  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const dimensions = useResizeObserver(wrapperRef);
  
  useEffect(
    () => {

      const svg = select(svgRef.current)
      
      const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

      // if there is no data remove the bars and return
      if (!data || data.length===0) {
        svg.selectAll('.bar').remove()
        svg.selectAll('.bar2').remove()
        return
      }

      
      let margin = ({top:10, right: 20, bottom:50, left: 20});
      
      const x = scaleBand()
        .range([margin.left, width - margin.right])
        .domain(data.map((d,i) => i ))
        .padding(0.3)

      const invertX = (pixels) => {
        const totalBandwidth = (width - margin.left - margin.right) / (data.length)
        if (pixels<margin.left) return -1
        let index = Number(Math.round((pixels-margin.left-margin.right)/totalBandwidth))
        return index
      }

      let maxUp = max(data, d => Number(d[1]))
      let maxDown = max(data, d => Number(d[2]))
      let maxValue = maxUp + maxDown
      let originOffset = maxUp/maxValue*(height-margin.bottom-margin.top)+margin.top
      
      let y = scaleLinear()
        .domain([0, maxUp])
        .range([0, (originOffset-margin.top)])
      
      let y2 = scaleLinear()
        .domain([0, maxDown])
        .range([ 0, (height-margin.bottom-originOffset)])

        const tickDensity = width>780 ? 1 : 5

        // let xAxis = g => g
        // .attr("transform", `translate(0,${height - margin.bottom+5})`)
        // .call(axisBottom(x)
        //         .tickSize(0)
        //         .tickValues(x.domain().filter(function(d,i){ return !(i%tickDensity)}))
        //         .tickFormat(i => (new Date(data[i][0]).getUTCDate())))
        // .call(g => g.select(".domain").remove())
        
        // let xAxis2 = g => g
        //   .attr("transform", `translate(0,${height - margin.bottom + 20})`)
        //   .call(axisBottom(x)
        //           .tickSize(0)
        //           .tickValues(x.domain().filter(function(d,i){ return !(i%tickDensity)}))
        //           .tickFormat(i => ((i === 0 || (getMonth(data[i][0]) !== getMonth(data[i-1][0]))) ? getMonth(data[i][0]) : '')))
        //   .call(g => g.select(".domain").remove())
        
       
        // let xAxis3 = g => g
        //   .attr("transform", `translate(0,${height - margin.bottom + 35})`)
        //   .call(axisBottom(x)
        //           .tickSize(0)
        //           .tickValues(x.domain().filter(function(d,i){ return !(i%tickDensity)}))
        //           .tickFormat(i => ((i === 0 || (new Date(data[i][0]).getUTCDate() === 1 && new Date(data[i][0]).getMonth() === 1)) ? new Date(data[i][0]).toLocaleString('en-us', { year: 'numeric' }) : '')))
        //   .call(g => g.select(".domain").remove())
      

      // svg.select('.x-axis').call(xAxis)
      // svg.select('.x-axis2').call(xAxis2)
      // svg.select('.x-axis3').call(xAxis3)

        svg.selectAll('.bar')
          .data(data)
          .join('rect')  
          .attr('class','bar')
          .attr("fill", colors[0])
          .style('transform','scale(1,-1')
          .attr("x", (d, i) => x(i))
          .attr("y", -originOffset)
          .attr("width", x.bandwidth())
          .attr("height", d => y(Number(d[1])))
        
        svg.selectAll('.bar2')
          .data(data)
          .join('rect')  
          .attr('class','bar2')
          .attr("fill", colors[1])
          .attr("x", (d, i) => x(i))
          .attr("y",  originOffset)
          .attr("width", x.bandwidth())
          .attr("height", d => y2(Number(d[2])))


        // tooltip code ...

        let p = findAncestor(svgRef.current,"plot")
        let t = p.querySelector('.tooltip')
        t.style.visibility = 'hidden'
        svg.selectAll('.highlight').remove()

        
        // svg.on('touchstart', touch2Mouse,true )
        svg.on('touchmove', touch2Mouse,true )
        // svg.on('touchout', touch2Mouse,true )

        const handle = (e) => {
          let x = e.clientX-svgRef.current.getBoundingClientRect().x
          const index = invertX(x)
          if (index>-1 && index<data.length) {
            if (index !== selection) onChange(index)
          }
        }
        // const handle = (e) => {
        //   let x1 = e.clientX-svgRef.current.getBoundingClientRect().x
        //   let y1 = e.clientY-svgRef.current.getBoundingClientRect().y

        //   let index = invertX(x1)
        //   index = index>=data.length ? index=data.length-1: index
        //   if (index<0 || y1<margin.top || x1>width-margin.right) {
        //     t.style.visibility = "hidden"
        //     svg.selectAll('.highlight').remove()
        //     return
        //   }

        //   let htmlString = `<h5 class="title" style="color:#444; padding-bottom:5px;">${data[index][0]}</h5>`;
        //   htmlString += `<p class="value" style="color:${colors[0]}">${data[index][1]}</span></p>`;
        //   htmlString += `<p class="value" style="color:${colors[1]}">${data[index][2]}</span></p>`;
          
        //   if (t) {
        //     t.style.visibility = "visible"
        //     let left = 'unset'
        //     let right = 'unset'
        //     let position = x(index)+x.bandwidth()/2
        //     if (e.clientX<width/2) {
        //       left = position+5 + 'px'
        //     } else {
        //       right = width-position+10 + 'px'
        //     }
        //     t.style.left = left
        //     t.style.right = right
        //     t.style.textalign = left
        //     t.innerHTML = htmlString;
        //   }
        if(selection>-1) {

          svg.selectAll('.highlight').remove()
          // svg.append('line')
          //     .attr('class','highlight')
          //     .attr('x1', x(selection)+x.bandwidth()/2)
          //     .attr('y1', height-margin.bottom)
          //     .attr('x2', x(selection)+x.bandwidth()/2)
          //     .attr('y2', height-margin.bottom+10)
          //     .attr('stroke','#ccc')
          //     .attr('fill','none')

          svg.append('text')
              .attr('class','highlight')
              .attr("x", x(selection)+x.bandwidth()/2)
              .attr("y", height-margin.bottom+15)
              .attr("text-anchor", "middle")
              .text(getMonth(data[selection][0])+' '+ (new Date(data[selection][0]).getUTCDate()));

          svg.append('text')
              .attr('class','highlight')
              .attr("x", x(selection)+x.bandwidth()/2)
              .attr("y", height-margin.bottom+30)
              .attr("text-anchor", "middle")
              .text(new Date(data[selection][0]).toLocaleString('en-us', { year: 'numeric' }));

          svg.append('rect')
              .attr('class','highlight')
              .attr('x', x(selection))
              .attr('y', margin.top)
              .attr('width', x.bandwidth())
              .attr('height', height-margin.bottom-margin.top)
              .attr('fill-opacity',0.0)
              .attr('stroke','#ccc')
        }
        svg.on('mousedown', handle,true )
        svg.on('mousemove', handle,true)
      },

      [ data, selection, dimensions ])

      
  return (
    <div className="row" >
       <div className="plot" ref={wrapperRef} style={{height:'140px',marginRight:'10px'}}>
        <div className="tooltip" styles={{visibility:'hidden'}}></div>
        <svg ref={svgRef} style={{width:'100%',height:'100%'}}>
        {/* <g className="x-axis" />
        <g className="x-axis2" />
        <g className="x-axis3" /> */}
        </svg>
      </div>
    </div>
  );
}