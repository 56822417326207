import React, {useState} from 'react';
import DotGrid from '../components/DotGrid'
import DropSelector from '../components/DropSelector'
// import data from '../customers' 
import '../css/Grid.css'


export default function Grid() {

  let data = []
  for (let i=0;i<20000;i++) {
    data.push({
      key1:i,
      key2:Math.round(Math.random()*100000)
    })
  }


  const [sortKey,setsortKey] = useState(Object.keys(data[0])[0])
  const [colorKey,setColorKey] = useState(Object.keys(data[0])[1])
  const [sortAscending,setSortAscending] = useState(false)
  const [size,setSize] = useState("small")

  function changeDirection(){
    setSortAscending(sortAscending => !sortAscending)
  }

  function changeSortKey(index){
    setsortKey(index)
  }

  function changeColorKey(index){
    setColorKey(index)
  }

  function changeSize(index){
    setSize(index)
  }

  return (
    <div className="page-body">
      <h1>Dot Grid</h1>
      <div className="grid">
        <div className="topline">
          <DropSelector label="Sort By ..." items={Object.keys(data[0])} selectedItem={sortKey} onChange={changeSortKey} />
          <DropSelector label="Sort Direction ..." items={["ascending","descending"]} selectedItem={sortAscending?"ascending": "descending"} onChange={changeDirection} />
          <DropSelector label="Grid Size ..." items={["small","medium","large"]} selectedItem={size} onChange={changeSize} />
          <DropSelector label="Color By ..." items={Object.keys(data[0])} selectedItem={colorKey} onChange={changeColorKey} />
        </div>
        <div>
          <DotGrid items={data} sortKey={sortKey} sortAscending={sortAscending} colorKey={colorKey} size={size}/>
        </div>
      </div>
      <p className="text-box">The idea here is to be able to visualize a large amount of data quickly with each small dot representiing an entity where the sort order and color opacity can represent two dimensions.</p>
    </div>)
    
}
