import React from 'react';
import { Link } from 'react-router-dom'


export default function Reset(props) {


  return (
    <div className="form-page">
      <h1>Password Successfully Reset</h1>
      <Link to="/login">login</Link>  
    </div>)
}