import React from 'react';

export default function Item({data, scale, size}) {
  // console.log(data)

  const entries = Object.entries(data)
  let sizes = ["small","medium","large"]
  const hsize = [20,40,100]
  let tooltipItems = []
  let i = 0;
  for (const [key, value] of entries) {
    tooltipItems.push(<p key={i}>{key}: <span>{value}</span></p>)
    i++;
  }
  let style = {backgroundColor:`hsl(200, 91%, ${scale}%)`, height:hsize[sizes.indexOf(size)], width:hsize[sizes.indexOf(size)]}
  let name = data.name.substring(0,2);
  if (size==="large") {
    name = data.name
  }
  return (
          <div className="item" style={style}>{size!=="small"?name:null}
            <div className="popup-note">
              {tooltipItems}
            </div>
          </div>
  );
}
