import React from 'react';
import Dial from '../components/Dial2'


export default function DialPage() {

  return (
    <div className="page-body">
      <h1>Dial2 Component</h1>
      <Dial />
      <p className="text-box">This is another kind of dial component. One of the advantages of a dial component is that is can be used for example to represent a timeline where the total length of the timeline can be many times around the circle giving fine grain control over a long list in a very small space. This would be especially good for cyclic values like timeline values (hours of the day, days of the year...)</p>
    </div>)
}
