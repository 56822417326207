
import { addDays, getDateString } from '../utils/utils'

export const process4 = (data) => {

    //fill null add values in dates array and add date number to objects array
    let last = 0;
    let next = 0;

    for (let i=0;i<data.dates.length;i++) {
      if (data.dates[i][1] === null) {
        data.dates[i][1]= data.dates[i-1][1]
      } else {
        next = data.dates[i][1]
        for (let j=last;j<next+1 && j<data.objects.length;j++) {
          data.objects[j].push(data.dates[i][0])
        }
        last = next+1
        if (last>data.objects.length-1) last = data.objects.length-1
      }
    }

    let x = data.dates.length-1;
    let splitBarData = []
    
    while (data.dates[x][2]) {
      let obj = []
      obj.push(getDateString(addDays(data.first_date,data.dates[x][0])))
      obj.push(data.dates[x][1]-data.dates[x-1][1])
      obj.push(data.dates[x][2]-data.dates[x-1][2])
      splitBarData.push(obj)
      x--;
    }
    data.splitBarData = splitBarData.reverse()
    data.offset = data.dates.length-splitBarData.length

    return data
}