import React, { useEffect } from 'react';
import { useQuery } from 'react-query'
import { request } from '../utils/request'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
// import { useDatasetData, useDatasetDetails} from '../hooks/useDatasetData';
import Spinner from '../components/Spinner'
import {Icon} from '../components/Icon'
import { DatasetType2 } from '../components/DatasetType2';
import { DatasetType3 } from '../components/DatasetType3';
import { DatasetType4 } from '../components/DatasetType4';
import { process4 } from '../utils/ProcessDataset4'
import toast from "react-hot-toast"
// import axios from 'axios'


export default function Dataset() {

  const location = useLocation()
  const navigate = useNavigate()
  let { id } = useParams();

  const fetchDataset = ({ queryKey }) => {
    return request('get', `/datasets/${queryKey[1]}`)
}

  const fetchDetails = ({queryKey}) => {
    return request('get',queryKey[2],null, false)
  }

  const { data:dataset } = useQuery(['dataset',id],fetchDataset,{
    keepPreviousData:true,
    staleTime:60000,
    retry:0,
    // retry:(failureCount, error) =>  failureCount < 3 && error?.response?.status !== 401,
    onError: (e) => {
      if (!e.response) {
        toast.error('Error retrieving dataset. Please check network connection and try again.')
      }
      else if (e.response.status === 401) {
        navigate("/login"+location.search,{ state:{from:location}});
      }
      else toast.error(e.response.data.message)
    }
  })
  const signedURL = dataset?.data?.signedURL

  // by setting the enabled flag, the fetch won't occur until the previous fetch returns a signedURL
  const result = useQuery(['dataset',id,signedURL], fetchDetails, {
    enabled:!!signedURL,
    keepPreviousData:true,
    staleTime:60000,
  })
  
  // useEffect(()=> {
  //   if (dataset?.response?.status===401) {
  //       navigate("/login"+location.search,{ state:{from:location}});
  //   }
  // },[dataset,navigate,location])

  // if (dataset?.response?.status===403) {
  //   return (
  //     <div className="panel">
  //       <h3>You do not have permission to view this dataset.</h3>
  //     </div>)
  // }

  // if (result.isError) { 
  //   return (
  //     <div className="panel">
  //       <h3>{result.error.message}</h3>
  //     </div>)
  // }

  if (!result.isSuccess) { 
    return (
      <div className="panel">
        <Spinner />
      </div>)
  }

  
  const list = (e) => {
    navigate("/datasets");
  }

  
  const share = (e) => {
    navigate(`/shares/${id}`);
  }


  let panel = <Spinner />
  switch(dataset.data.dataset.datasetTypeID) {
    // case 1:
    //   panel = <Dataset_Type1 data={dataset.data}  /> 
    //   break;
    case 2:
      panel = <DatasetType2 data={result.data.data}  /> 
      break;
    case 3:
      panel = <DatasetType3 data={result.data.data}  /> 
      break;
    case 4:
      panel = <DatasetType4 data={process4(result.data.data)}  /> 
      break;
    default:
  }

  return (
    <div className="page-body">
      <div className="row">
        <Icon onClick={list} type="list" active={false} />
        <h3>{dataset.data.dataset.datasetName}</h3>
        <Icon onClick={share} type="person" active={false} />
      </div>
      <br/>
      {panel}
    </div>)
}