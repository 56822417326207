import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { combineReducers } from "./reducers/";


// import "core-js/stable"; // required for babel 7+
// import "regenerator-runtime/runtime"; // required for babel 7+

import { render } from "react-dom";

// import Layout from "./layout";
import configureStore from "./store";
// import Modal from "react-modal";
// import { loadConfig } from "./modules/Config/action";

// const renderSite = (config, reducers, App) => {
  const { history, store } = configureStore(combineReducers);

  // store.dispatch(loadConfig(config));
//   Modal.setAppElement(document.getElementById("root"));
  render(<App store={store} history={history}  />,document.getElementById("root"));
// };


// render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
