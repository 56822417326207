export const findAncestor = (el, cls) => {
  if (el.classList.contains(cls)) { return el }
  while ((el = el.parentElement) && !el.classList.contains(cls)) {}
  return el
}
  
export const monthDiff = (dateFrom, dateTo) => {
  let months =  dateTo.getMonth() - dateFrom.getMonth() + 
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  return months;
}

export const addMonths = (date, numMonths) => {
  let lowDate = new Date(date);
  let newDate = new Date(date)
  newDate.setFullYear(lowDate.getFullYear()+ Math.floor(numMonths/12))
  newDate.setMonth(lowDate.getMonth()+ (numMonths % 12))
  return newDate;
}
  
export const windowLength = {
  hourly: 24,
  daily: 31,
  weekly: 26,
  monthly: 24,
  quarterly:20,
  yearly: 15
}

export const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

export const roundNumber = (num, decimals) => {
  let numString = num.toFixed(decimals);
  if (isNaN(parseFloat(numString))) { return 0 }
  else { return numString }
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isValidEmail = (str) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(str);
}

export const isValidOrg = (str) => {
  var re = /^[_.A-z0-9-]{4,20}\d*$/i;
  return re.test(str);
}

export const isValidPassword = (str) => {
  var re = /^.{5,30}\d*$/i;
  return re.test(str);
}

// gets a shortened month string from a date string formatted like yyyy-mm-dd
export const getMonth = (date) => {
  return (months[Number(date.substring(5,7))-1])
}

export const getYear = (date) => {
  return (date.substring(0,4))
}

export const getDay = (date) => {
  let day = date.substring(8)
  if (day.substring(0,1)=== '0'){
    day = day.substring(1)
  }
  return (day)
}

export const isWeekend = (date) => {
  let dt = new Date(date)
  let day = dt.getUTCDay()
  if (day === 0 || day === 6) return true
  return false
}

export const isYearStart = (date) => {
  return (date.substring(5,10)==='01-01')
}

export const getQuarter = (date) => {
  let monthNum = date.getMonth()
  if (monthNum<3) return 'Q1'
  if (monthNum<6) return 'Q2'
  if (monthNum<9) return 'Q3'
  return 'Q4'
}
export const getHour = (date) => {

  let hournum = date.getHours()
  if (hournum>11) return (hournum-12) + 'pm'
  return hournum + 'am'
}

export const getWeekNumber = (date) => {
  // Copy date so don't modify original
  let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  // Return array of year and week number
  return weekNo;
}

export const round = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)

export const format = (value, type) => { 
  
  switch(type) {
    case 'number':
      return numberWithCommas(round(value,2))
    case 'currency':
      return '$ ' + numberWithCommas(round(value,2))
    case 'percent':
      return numberWithCommas(round(value,2)) + '%'
    default:
  }
}

export const touch2Mouse = (e) => {
  
  // console.log(e.type, e.changedTouches[0].clientX)
  var theTouch = e.changedTouches[0];
  var mouseEv;

  switch(e.type)
  {
    case "touchstart": mouseEv="mousedown"; break;  
    case "touchend":   mouseEv="mouseup"; break;
    case "touchmove":  mouseEv="mousemove"; break;
    default: return;
  }

  var init = {
    canBubble : true,
    cancelable : true,
    detail: window,
    screenX: theTouch.screenX,
    screenY: theTouch.screenY,
    clientX: theTouch.clientX,
    clientY: theTouch.clientY,
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    metaKey: false,
    button: 0,
    relatedTarget: null
  }

  const mouseEvent = new MouseEvent(mouseEv, init);
  // console.log(mouseEvent)

  // var mouseEvent = document.createEvent("MouseEvent");
  // mouseEvent.initMouseEvent(mouseEv, true, true, window, 1, theTouch.screenX, theTouch.screenY, theTouch.clientX, theTouch.clientY, false, false, false, false, 0, null);
  theTouch.target.dispatchEvent(mouseEvent);

  // this preventDefault prevents actions unrelated to the current action (like page scrolling) from occuuring
  e.preventDefault();
}

export const daysIntoYear = (date) => {
  return (Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) - Date.UTC(date.getUTCFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
}

export const isLeapYear = (year) => (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))

export const daysInMonth = (month, year) => { 
  // Use 1 for January, 2 for February, etc.
  return new Date(year, month, 0).getUTCDate();
}

export const getDateString = (date) => {
  let mm = date.getUTCMonth()+1;
  if (mm<10) mm = '0'+mm
  let dd = date.getUTCDate();
  if (dd<10) dd = '0'+dd
  let yyyy = date.getUTCFullYear();
  return yyyy+'-'+mm+'-'+dd
}

export const previousDate = (dateString) => { 
  let date = new Date(dateString);
  date.setUTCDate(date.getUTCDate() - 1);
  return getDateString(date)
}

export const daysDifference = (first,second) => {
  return Math.round((second-first)/(1000*60*60*24))
}

export const addDays = (toDate, days) => {
  var date = new Date(toDate.valueOf())
  date.setDate(date.getDate() + days);
  return date;
}

export const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];


export const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


export const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday","Saturday"];


export const dollarFormat = (value) => numberWithCommas(roundNumber(Number(value),2))