import React, {useState} from 'react';
import { SplitDiv } from '../components/SplitDiv'


export default function Split() {

  const [pct,setPct] = useState(75)

  return (
    <div className="page-body">
      <h1>SplitDiv Component</h1>
      <SplitDiv value={1000000} pct={pct} onChange={(p)=>setPct(p)} />
    </div>)
}
