import React,{ useEffect, useState, useRef } from "react";
import { useResizeObserver } from '../hooks/useResizeObserver';
import "../css/Dial.css";
import { touch2Mouse } from '../utils/utils'


export default function PaymentDial() {

  // let colors = ["#16B1FD",'#46CEAC',"#EB5564"]

  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const dimensions = useResizeObserver(wrapperRef);
  const [startAngle, setStartAngle] = useState(0)
  const [angle, setAngle] = useState(0)
  const [index, setIndex] = useState(0)
  const [interest, setInterest] = useState(5)
  const [loanAmount, setLoanAmount] = useState(500000)
  const [term, setTerm] = useState(30)
  const [active, _setActive] = useState(false)
  const activeRef = React.useRef(active);
  const setActive = data => {
    activeRef.current = data;
    _setActive(data);
  };
  
  
  useEffect(() => {

    const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();
    
    const mouseUp = (e) => {
      e.preventDefault();
      let svg = svgRef.current;
      console.log(svg,'up')
      // svg.removeEventListener("mousemove", mouseMove);
      // svg.removeEventListener("touchmove", touch2Mouse, true);
      setActive(false)
    }
  
    const mouseDown = (e) => {
      e.preventDefault(); 
      // register the touch move

      let svg = svgRef.current;
      console.log(svg,'down')
      // svg.addEventListener("mousemove", mouseMove);
      // svg.addEventListener("touchmove", touch2Mouse, true);
      // svg.addEventListener("mouseup", mouseUp);
      // svg.addEventListener("touchend", touch2Mouse, true);
      let rect = svg.getBoundingClientRect()
      
      // // calculate the start angle
      let x = e.clientX-rect.x
      let y = e.clientY-rect.y
      let slope = (y-150)/(x-150)
      
      let angle = Math.atan(slope) * 180/Math.PI + 90
      if (x<150) angle += 180
      setStartAngle(angle)
      setActive(true)
    }
  
    const mouseMove = (e) => {
      if (!activeRef.current) return
      e.preventDefault(); 

      let svg = svgRef.current;
      let rect = svg.getBoundingClientRect()
     
      // calculate the start angle 
      let x = e.clientX-rect.x
      let y = e.clientY-rect.y
      let slope = (y-150)/(x-150)
      let angle = Math.atan(slope) * 180/Math.PI + 90
      if (x<150) angle += 180
      let diff = (startAngle+angle)%360
      setAngle(diff)

      let t = Math.floor(diff)+12
      if (t<1) t=1
      else if(t>360) t=360
      setTerm(t)
    }

    let svg = svgRef.current;
    svg.addEventListener("mousedown", mouseDown);
    svg.addEventListener("mousemove", mouseMove);
    window.addEventListener("mouseup", mouseUp);
    svg.addEventListener("touchstart", touch2Mouse, true);
    window.addEventListener("touchend", touch2Mouse, true);
    window.addEventListener("touchmove", touch2Mouse, true);
    
    return () => {
      let svg = svgRef.current;
      svg.removeEventListener("mousedown", mouseDown);
      svg.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
      svg.removeEventListener("touchstart", touch2Mouse, true);
      svg.removeEventListener("touchmove", touch2Mouse, true);
      window.removeEventListener("touchend", touch2Mouse, true);
    };

  }, [startAngle]);

  let lines = []
  for (let i=0;i<100;i++) {
    let line = 
    <g key={i} transform={`rotate(${360/100*i} 150 150)`}>
      <line x1="150" y1='10' x2="150" y2='40' style={{ stroke: `${i%10===0 ? '#16B1FD':'#dadada'}`,strokeWidth:1,strokeLinecap:"round", fill: "none"}}/>
    </g>
    lines.push(line)
  }

  let i = (loanAmount * (interest * 0.01))/term;
  let payment = ((loanAmount/term) + i);


  return (
    <div ref={wrapperRef} className="dial">
      <svg
        ref={svgRef}
        version="1.1"
        baseProfile="full"
        id="plot-svg"
        className="plot-svg"
        width={300}
        height={300}
        xmlns="http://www.w3.org/2000/svg"
        // preserveAspectRatio="none"
        // viewBox="0 0 300 300"
      >
        <circle cx="150" cy="150" r="148" style={{ stroke: "#16B1FD",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />
        <circle cx="150" cy="150" r="100" style={{ stroke: "#16B1FD",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />

        <g transform={`rotate(${angle} 150 150)`}>
          {lines}
        </g>
        <text
          className="dial-metric"
          x={150}
          y={88}
          textAnchor="middle">
          {Math.round(term)}
        </text>
        <text
          x={150}
          y={100}
          textAnchor="middle">
          loan term (months)
        </text> 
        <text
          className="dial-metric"
          x={150}
          y={133}
          textAnchor="middle">
          {loanAmount}
        </text>
        <text
          x={150}
          y={145}
          textAnchor="middle">
          loan amount
        </text> 
        <text
          className="dial-metric"
          x={150}
          y={178}
          textAnchor="middle">
          {Math.round(payment,2)}
        </text>
        <text
          x={150}
          y={190}
          textAnchor="middle">
          payment
        </text>
        <text
          className="dial-metric"
          x={150}
          y={218}
          textAnchor="middle">
          {Math.round(interest,2)}
        </text>
        <text
          x={150}
          y={230}
          textAnchor="middle">
          interest
        </text>
      </svg>
    </div>
  );
}
