import React from 'react';
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import './css/App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import Routes from './routes'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Toaster } from "react-hot-toast"
// import { ErrorBoundary } from 'react-error-boundary'
// import {Error} from './pages/Error'

const queryClient = new QueryClient()

function App({ store, history }) {

  // const errorHandler = (error, errorInfo) => {
  //   console.log(error,errorInfo)
  // }

  return (
    // <ErrorBoundary FallbackComponent={Error} onError={errorHandler}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Provider store={store}>
          <BrowserRouter>
            <div className="App">
              <Header />
              <Routes />
              <Footer />
              <Toaster />
            </div>
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    // </ErrorBoundary>
  );
}

export default App;
