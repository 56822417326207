import React,{ useEffect, useState } from "react";
// import "../css/Dial.css";
import { touch2Mouse } from '../utils/utils'


export default function Dial() {

  
  const [angle, setAngle] = useState(0)
  const [index, setIndex] = useState(0)
  

  const calculateAngle = (x,y) => {
    let range = 365
    let slope = (y-150)/(x-150)
    let angle = Math.atan(slope) * 180/Math.PI + 90
    if (x<150) angle += 180
    // find the index within the range
    let index = Math.floor(angle * range/360)
    // now reset the angle to snap to range index
    angle = Math.floor(360/range*index)
    setAngle(angle)
    setIndex(index)
  }

  
  useEffect(() => {
    
    const mouseUp = (e) => {
      // e.preventDefault();
      console.log('up')
      let svg = document.getElementById("plot-svg");
      svg.removeEventListener("mousemove", mouseMove);
      svg.removeEventListener("touchmove", touch2Mouse, true);
    }

  
    const mouseDown = (e) => {
      console.log('down')
      e.preventDefault(); 
      // register the touch move

      let svg = document.getElementById("plot-svg");
      let rect = svg.getBoundingClientRect()

      svg.addEventListener("mousemove", mouseMove);
      svg.addEventListener("touchmove", touch2Mouse, true);
      window.addEventListener("mouseup", mouseUp);
      window.addEventListener("touchend", touch2Mouse, true);
      
      // calculate the  angle
      calculateAngle(e.clientX-rect.x,e.clientY-rect.y)
    }
  
    const mouseMove = (e) => {
      console.log('move')
      e.preventDefault(); 

      let svg = document.getElementById("plot-svg");
      let rect = svg.getBoundingClientRect()
     
      // calculate the  angle
      calculateAngle(e.clientX-rect.x,e.clientY-rect.y)
    }
  

    let svg = document.getElementById("plot-svg");
    svg.addEventListener("mousedown", mouseDown);
    svg.addEventListener("touchstart", touch2Mouse, true);
    
    return () => {
      let svg = document.getElementById("plot-svg");
      svg.removeEventListener("mousedown", mouseDown);
      svg.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
      svg.removeEventListener("touchstart", touch2Mouse, true);
      svg.removeEventListener("touchmove", touch2Mouse);
      window.removeEventListener("touchend", touch2Mouse, true);
    };

  }, []);

  return (
    <div className="dial">
        <svg
          version="1.1"
          baseProfile="full"
          id="plot-svg"
          className="plot-svg"
          width={300}
          height={300}
          xmlns="http://www.w3.org/2000/svg"
          // preserveAspectRatio="none"
          viewBox="0 0 300 300"
        >
              <circle cx="150" cy="150" r="144" style={{ stroke: "#ccc",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />
              {/* <circle cx="150" cy="150" r="100" style={{ stroke: "#16B1FD",strokeWidth:1,strokeLinecap:"round", fill: "none"}} /> */}

              <g transform={`rotate(${angle} 150 150)`}>
                <circle cx="150" cy="6" r="5" style={{ stroke: "#666",strokeWidth:1,strokeLinecap:"round", fill: "white", fillOpacity:0.5}} />
                <line x1="150" y1='11' x2="150" y2='15' style={{ stroke: "#888",strokeWidth:1,strokeLinecap:"round", fill: "none"}}/>
              {/* {lines} */}
              </g>
              <text
              style={{ fill: "#16B1FD", fontSize:24}}
              x={150}
              y={150}
              textAnchor="middle">
              {index}
            </text> 
        </svg>
    </div>
  );
}
