import React, { useState } from 'react';
import { numberWithCommas, findAncestor, dollarFormat, shortMonthNames } from '../utils/utils'
import { YearGrid } from './YearGrid_3';
import { IconSelector } from './IconSelector'
import { Icon } from './Icon'
import { BarPlot } from './BarPlot'
import { ParetoPlot } from './ParetoPlot'

export const DatasetType3 = ({ data }) => {

  let startYear = Number(data.years[0].year)
  
  const [selection,setSelection] = useState(['2019','2020','2021'])
  const [tabSelection,setTabSelection] = useState([null,null,null])
  const [compareSelection,setCompareSelection] = useState(null)
  const [compareToSelection,setCompareToSelection] = useState(null)
  const [compareToData,setCompareToData] = useState(null)
  const [plotType, setPlotType] = useState('barChart')

  function onChange(index,year) {

    if (compareSelection) {
      // compare is active to set the compare to selection
      let compareGrain = 'year'
      if (compareSelection.length === 10) {
        compareGrain = 'day'
      } else if (compareSelection.length=== 7) {
        compareGrain = 'month'
      }
      let sg = 'year'
      let sd = data.years[year-startYear].data[0][7]
      if (index && index.length === 10){
        let mm = Number(index.slice(5,7))-1
        let dd = Number(index.slice(8,10))-1
        sg="day"
        sd = data.years[year-startYear].data[0][7][mm][7][dd][7]
      } else if (index && index.length === 7){
        let mm = Number(index.slice(5,7))-1
        sg="month"
        sd = data.years[year-startYear].data[0][7][mm][7]
      }
      // grain of compare and compare to must match
      if (compareGrain === sg) {
        setCompareToSelection(index)
        setCompareToData(sd)
      }
    }
    setSelection(s => { let s1 = s.slice();s1[year-startYear] = index; return s1})
  }

  function toggle(e) {
    let id=e.currentTarget.id.slice(3,4)
    let c = document.getElementById(`detail${id}`)
    let expanded = false

    // toggle the clicked item
    let tab = findAncestor(e.target,'tab')
    let tabID = parseInt(tab.id.slice(3,4))
    let t = tabSelection.slice(0)
    t[id] = tabID
    setTabSelection(t)
    
    // get all the tabs and clear the other items
    let parent = findAncestor(e.target,'tab-list')
    let tabs = parent.getElementsByClassName('tab')
    for (let i=0;i<tabs.length;i++) {
      if (tabs[i]!==tab) tabs[i].classList.remove('tab-selected')
    }

    if (tab.classList.contains('tab-selected')) {
      tab.classList.remove('tab-selected')
    } else {
      tab.classList.add('tab-selected')
      expanded=true
    }
    
    // toggle the detail area expansion
    if (expanded) {
      if(!c.classList.contains('expanded')) {
        c.classList.add('expanded')
      } 
    } else c.classList.remove('expanded')

    if (tabID !== 0 || !expanded) {
      setCompareSelection(null);
      setCompareToSelection(null)
      setCompareToData(null)
    }
  }

  const clickCompare = (t,id) => {
    let y = 0
    let y1 = null
    if (id && id.slice(7)) {
      y = id.slice(7)
      console.log(y)
      y1 = selection[y]
      if (y1 === null) {
        y1 = data.years[y].year
      }
      // if this compare is on then turn it off, otherwise set the compare selection
      if (compareSelection && (compareSelection.slice(0,4) === y1.slice(0,4))) {
        setCompareSelection(null);
        setCompareToSelection(null)
        setCompareToData(null)
      }
      else { 
        setCompareSelection(y1) 
        setCompareToSelection(y1) 
        let sd = data.years[data.years[y].year-startYear].data[0][7]
        if (y1 && y1.length === 10){
          let mm = Number(y1.slice(5,7))-1
          let dd = Number(y1.slice(8,10))-1
          sd = data.years[y].data[0][7][mm][7][dd][7]
        } else if (y1 && y1.length === 7){
          let mm = Number(y1.slice(5,7))-1
          sd = data.years[y].data[0][7][mm][7]
        }
        console.log(y1,sd)
        setCompareToData(sd)
      }
    }
  }
  

  let maxSales = 0;

  // loop through every day and find the highest day sales
  for (let y=0;y<data.years.length;y++) {
    for (let m=0;m<data.years[y].data[0][7].length; m++) {
      for (let d=0;d<data.years[y].data[0][7][m][7].length;d++) {
        maxSales = Math.max(Number(data.years[y].data[0][7][m][7][d][0]),maxSales)
      }
    }
  }
  
  let years = data.years.map((d,i) => {
    let selectedData = d.data[0]
    let selectedGrain = 'year'
    let plotDate = d.year
    let compareDate = d.year
    let plotTitle = plotDate + ' - Monthly Sales'

    if (compareSelection && compareSelection.slice(0,4)===d.year) {
      console.log(compareSelection,compareToSelection)
      if (compareSelection.length === 10){
        let mm = Number(compareSelection.slice(5,7))-1
        let dd = Number(compareSelection.slice(8,10))-1
        let m1 = Number(compareToSelection.slice(5,7))-1
        let d1 = Number(compareToSelection.slice(8,10))-1
        let y1 = Number(compareToSelection.slice(0,4))
        selectedGrain="day"
        selectedData = d.data[0][7][mm][7][dd]
        plotDate = shortMonthNames[mm] + ' ' + (dd+1) + ', ' + d.year
        compareDate = shortMonthNames[m1] + ' ' + (d1+1) + ', ' + y1
        plotTitle = plotDate + ' vs ' + compareDate
      } else if (compareSelection.length === 7){
        let mm = Number(compareSelection.slice(5,7))-1
        let m1 = Number(compareToSelection.slice(5,7))-1
        let y1 = Number(compareToSelection.slice(0,4))
        selectedGrain="month"
        selectedData = d.data[0][7][mm]
        plotDate = shortMonthNames[mm] + ' ' + d.year
        compareDate = shortMonthNames[m1] + ' ' + y1
        plotTitle = plotDate + ' vs ' + compareDate
      }
    } else {
      if (selection[i] !== null && selection[i].length===10) { 
        let mm = Number(selection[i].slice(5,7))-1
        let dd = Number(selection[i].slice(8,10))-1
        selectedGrain="day"
        selectedData = d.data[0][7][mm][7][dd]
        plotDate = shortMonthNames[mm] + ' ' + (dd+1) + ', ' + d.year
        plotTitle = plotDate + ' - Hourly Sales'
      }
      else if (selection[i] !== null && selection[i].length===7) { 
        let mm = Number(selection[i].slice(5,7))-1
        selectedGrain="month"
        selectedData = d.data[0][7][mm]
        plotDate = shortMonthNames[mm] + ' ' + d.year
        plotTitle = plotDate + ' - Daily Sales'
      }
    }

    let sales = selectedData ? dollarFormat(Number(selectedData[0])) : 0
    let customers = selectedData ? numberWithCommas(selectedData[3]) : 0
    let products = selectedData ? numberWithCommas(selectedData[4]) : 0
    let states = selectedData ? numberWithCommas(selectedData[5]) : 0
    let stores = selectedData ? numberWithCommas(selectedData[6]) : 0
    

    let detailArea = <h3>test</h3>

    switch(tabSelection[i]) {
      case 0:
        switch(selectedGrain) {
          case 'year':
            detailArea = <div className='row'>
                <BarPlot data={selectedData&&selectedData[7]?selectedData[7]:null} title={plotTitle} plotType={plotType} compareData={compareSelection && compareSelection.slice(0,4)===d.year ? compareToData: null} timeGrain='month' labelIndex={0} valueIndex={0} />
                <div className='column' style={{height:'170px'}}>
                  <Icon id={`compare${i}`} onClick={clickCompare} type={'compare'} active={compareSelection && compareSelection.slice(0,4)===d.year} />
                  <IconSelector items={['barChart','lineChart']} activeItem={plotType} onChange={(e)=>{console.log(e);setPlotType(e)}} orientation='vertical' />
                </div>
              </div>
            break;
          case 'month':
            detailArea = <div className='row'>
                <BarPlot data={selectedData&&selectedData[7]?selectedData[7]:null} title={plotTitle} plotType={plotType} compareData={compareSelection && compareSelection.slice(0,4)===d.year ? compareToData: null} timeGrain='day' labelIndex={0} valueIndex={0} />
                <div className='column' style={{height:'170px'}}>
                  <Icon id={`compare${i}`} onClick={clickCompare} type={'compare'} active={compareSelection && compareSelection.slice(0,4)===d.year} />
                  <IconSelector items={['barChart','lineChart']} activeItem={plotType} onChange={(e)=>{console.log(e);setPlotType(e)}} orientation='vertical' />
                </div>
              </div>
            break;
          case 'day':
            detailArea = <div className='row'>
                <BarPlot data={selectedData&&selectedData[7]?selectedData[7]:null} title={plotTitle} plotType={plotType} compareData={compareSelection && compareSelection.slice(0,4)===d.year ? compareToData: null} timeGrain='hour' labelIndex={0} valueIndex={0} />
                <div className='column' style={{height:'170px'}}>
                  <Icon id={`compare${i}`} onClick={clickCompare} type={'compare'} active={compareSelection && compareSelection.slice(0,4)===d.year} />
                  <IconSelector items={['barChart','lineChart']} activeItem={plotType} onChange={(e)=>{console.log(e);setPlotType(e)}} orientation='vertical' />
                </div>
              </div>
            break;
          default:
        }
        break;
      case 1:
        plotTitle = plotDate + ' - Top Customers'
        detailArea = <ParetoPlot data={selectedData[8]} title={plotTitle} label='customers' labelIndex={1} valueIndex={2} total={selectedData[0]} count={selectedData[3]} />
        break;
      case 2:
        plotTitle = plotDate + ' - Top Products'
        detailArea = <ParetoPlot data={selectedData[9]} title={plotTitle} label='products'  labelIndex={0} valueIndex={1} total={selectedData[0]} count={selectedData[4]} />
        break;
      case 3:
        plotTitle = plotDate + ' - Top States'
        detailArea = <ParetoPlot data={selectedData[11]} title={plotTitle} label='states'  labelIndex={0} valueIndex={1} total={selectedData[0]} count={selectedData[5]} />
        break;
      case 4:
        plotTitle = plotDate + ' - Top Stores'
        detailArea = <ParetoPlot data={selectedData[10]} title={plotTitle} label='stores'  labelIndex={0} valueIndex={1} total={selectedData[0]} count={selectedData[6]} />
        break;
      default:
    }

    let sel = selection[i]
    if (compareToSelection && compareToSelection.slice(0,4)!==d.year) {
      sel = null
    }

    return (
    <div key={i}>
      <YearGrid data={data.years[i]} selection={sel} compareSelection={compareSelection} compareToSelection={compareToSelection} onChange={onChange} maxValue={maxSales} />
      <div className='tab-list' onClick={toggle} id={`top${i}`} style={{ display: 'flex', flexDirection:'row', justifyContent:'space-around'}}>
        <div id="tab0" className='tab' style={{textAlign:'center',flexGrow:1}}>
          <p style={{fontSize:'11px', color: tabSelection[i] === 0 ? "#16B1FD":"#bbb", marginBottom: '6px'}}>sales</p>
          <p style={{fontSize:'12px', color: "#333", marginTop:0, marginBottom: '5px' }}>$ {sales}</p>
        </div>
        <div id="tab1" className='tab'  style={{textAlign:'center',flexGrow:1}}>
          <p style={{fontSize:'11px', color: "#bbb", marginBottom: '6px' }}>customers</p>
          <p style={{fontSize:'12px', color: "#333", marginTop:0, marginBottom:'5px'  }}>{customers}</p>
        </div>
        <div id="tab2" className='tab'  style={{textAlign:'center',flexGrow:1}}>
          <p style={{fontSize:'11px', color: "#bbb", marginBottom: '6px' }}>products</p>
          <p style={{fontSize:'12px', color: "#333", marginTop:0 }}>{products}</p>
        </div>
        <div id="tab3" className='tab'  style={{textAlign:'center',flexGrow:1}}>
          <p style={{fontSize:'11px', color: "#bbb", marginBottom: '6px' }}>states</p>
          <p style={{fontSize:'12px', color: "#333", marginTop:0 }}>{states}</p>
        </div>
        <div id="tab4" className='tab'  style={{textAlign:'center',flexGrow:1}}>
          <p style={{fontSize:'11px', color: "#bbb", marginBottom: '10px' }}>stores</p>
          <p style={{fontSize:'12px', color: "#333", marginTop:0 }}>{stores}</p>
        </div>
      </div>
      <div id={`detail${i}`} className='details'>
        {detailArea}
      </div>
      <hr style={{ marginBottom:'0.5rem'}} />
    </div>
    )
  })
  years = years.reverse()


  return (
    <div style={{width:'100%'}}>{years}</div>)
}
