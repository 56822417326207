import React, {useEffect} from "react";
import { Routes, Route } from "react-router-dom";
// import ProtectedRoute from '../components/ProtectedRoute'
import Spinner from '../components/Spinner'
import Home from '../pages/Home'
import Experiments from '../pages/Experiments'
import Grid from '../pages/Grid'
import DotGrid from '../pages/DotGrid'
import Radial from '../pages/Radial'
import Dial from '../pages/Dial'
import Dial2 from '../pages/Dial2'
import Dial3 from '../pages/Dial3'
import Dial4 from '../pages/Dial4'
import Split from '../pages/SplitDiv'
import Signup from '../pages/Signup'
import Login from '../pages/Login'
import Forgot from '../pages/Forgot'
import Account from '../pages/Account'
import Upload from '../pages/Upload'
import Append from '../pages/Append'
import Reset from '../pages/Reset'
import ResetSuccess from '../pages/ResetSuccess'
import Types from '../pages/Types'
import NotFound from '../pages/NotFound'
import Datasets from '../pages/Datasets'
import Dataset from '../pages/Dataset'
import Shares from '../pages/Shares'
import UpdateSuccess from '../pages/UpdateSuccess'
import { useSelector, useDispatch } from "react-redux";
import { checkUser } from '../thunks/user' 
import { ProtectedRoute } from "../components/ProtectedRoute";

export default () => {


  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.checked && !user.checking) {
      dispatch(checkUser());
    }
  }, [user.checked, user.checking, dispatch]);
  
  if (!user.checked) { 
    return (
      <div className="panel">
        <Spinner />
      </div>)
  }

  return (
  <Routes>
    <Route exact path="/" element={<Home />} />
    <Route exact path="/grid" element={<Grid/>} />
    <Route exact path="/dotgrid" element={<DotGrid/>} />
    <Route exact path="/radial" element={<Radial/>} />
    {/* <Route exact path="/timeline" element={<Timeline/>} /> */}
    {/* <Route exact path="/bar" element={<Bar/>} /> */}
    {/* <Route exact path="/line" element={<Line/>} /> */}
    <Route exact path="/dial" element={<Dial/>} />
    <Route exact path="/dial2" element={<Dial2/>} />
    <Route exact path="/dial3" element={<Dial3/>} />
    <Route exact path="/dial4" element={<Dial4/>} />
    <Route exact path="/splitdiv" element={<Split />} />
    <Route exact path="/experiments" element={<Experiments/>} />
    {/* <ProtectedRoute exact path="/dashboard" element={<Dashboard/>} /> */}
    <Route exact path="/datasets" element={<Datasets/>} />
    <Route exact path="/dataset/:id" element={<Dataset/>} />
    <Route exact path="/shares/:id" element={<Shares/>} />
    <Route exact path="/account" element={<ProtectedRoute><Account/></ProtectedRoute>} />
    <Route exact path="/upload" element={<ProtectedRoute><Upload/></ProtectedRoute>} />
    <Route exact path="/append/:id" element={<ProtectedRoute><Append/></ProtectedRoute>} />
    <Route exact path="/pwupdatesuccess" element={<UpdateSuccess/>} />
    <Route exact path="/signup" element={<Signup/>} />
    <Route exact path="/types" element={<Types/>} />
    <Route exact path="/login" element={<Login/>} />
    <Route exact path="/forgot" element={<Forgot/>} />
    <Route exact path="/reset/:id/:code" element={<Reset/>} />
    <Route exact path="/resetsuccess" element={<ResetSuccess/>} />
    <Route path='*' element={<NotFound/>} />
    {/* <ProtectedRoute element={<Dashboard/>} /> */}
  </Routes>)
};
