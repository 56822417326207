import React from 'react';
import '../css/radial-page.css'
import RadialPlot from '../components/RadialPlot'


export default function Radial() {

  

  return (
    <div className="radial-page">
      <div style={{height:"600px, width:600px", margin:"0"}}>
        <RadialPlot /> 
      </div>
    </div>)
}
