import React, { useState } from 'react';
import { numberWithCommas, shortMonthNames } from '../utils/utils'
import { YearGrid } from './YearGrid_2';
import { IconSelector } from './IconSelector'
import { Icon } from './Icon'
import { BarPlot } from './BarPlot'

export const DatasetType2 = ({ data }) => {

  // let startYear = Number(data.years[0].year)
  let defaultSelection = []
  for (let i=0; i<data.years.length;i++) {
    defaultSelection.push(String(data.years[i].year))
  }


  const getDataByDate = (dateString) => {

    if (!dateString || dateString.length<4) return null
    let yy = dateString.slice(0,4)

    let yindex = -1
    for (let i=0;i<data.years.length;i++) {
      if (String(data.years[i].year) === yy) {
        yindex = i
      }
    }
    if (yindex === -1) return null

    let mm = dateString.length>6 ? Number(dateString.slice(5,7))-1 : null
    let dd = dateString.length>9 ? Number(dateString.slice(8,10))-1 : null
    
    if (mm === null)
      return data.years[yindex].data[0]

    if (dd === null && data.years[yindex].data[0][1].length>mm-1)
      return data.years[yindex].data[0][1][mm]
    
    if (data.years[yindex].data[0][1].length>mm && data.years[yindex].data[0][1][mm][1].length>dd)
      return data.years[yindex].data[0][1][mm][1][dd]
    
    return [0,[0]]

  }
  
  const [selection,setSelection] = useState(defaultSelection)
  // const [tabSelection,setTabSelection] = useState([null,null,null])
  const [compareSelection,setCompareSelection] = useState(null)
  const [compareToSelection,setCompareToSelection] = useState(null)
  const [compareToData,setCompareToData] = useState(null)
  const [plotType, setPlotType] = useState('barChart')

  function onChange(index,year) {

    if (compareSelection) {
      // compare is active to set the compare to selection
      let compareGrain = 'year'
      if (compareSelection.length === 10) {
        compareGrain = 'day'
      } else if (compareSelection.length=== 7) {
        compareGrain = 'month'
      }
      let sg = 'year'
      let sd = getDataByDate(index)
      
      if (index && index.length === 10){
        sg="day"
      } else if (index && index.length === 7){
        sg="month"
      }
      // grain of compare and compare to must match
      if (compareGrain === sg) {
        setCompareToSelection(index)
        setCompareToData(sd)
      }
    }
    let yindex = -1
    for (let i=0;i<data.years.length;i++) {
      if (String(data.years[i].year) === year) {
        yindex = i
      }
    }
    if (yindex === -1) return
    setSelection(s => { let s1 = s.slice();s1[yindex] = index; return s1})
  }


  const clickCompare = (t,id) => {
    let y = 0
    let y1 = null
    if (id && id.slice(7)) {
      y = id.slice(7)
      y1 = selection[y]
      if (y1 === null) {
        y1 = String(data.years[y].year)
      }
      // if this compare is on then turn it off, otherwise set the compare selection
      if (compareSelection && (compareSelection.slice(0,4) === y1.slice(0,4))) {
        setCompareSelection(null);
        setCompareToSelection(null)
        setCompareToData(null)
      }
      else { 
        setCompareSelection(y1) 
        setCompareToSelection(y1) 
        let sd = getDataByDate(y1)
        // let sd = data.years[data.years[y].year-startYear].data[0][1]
        // if (y1 && y1.length === 10){
        //   let mm = Number(y1.slice(5,7))-1
        //   let dd = Number(y1.slice(8,10))-1
        //   sd = data.years[y].data[0][1][mm][1][dd][1]
        // } else if (y1 && y1.length === 7){
        //   let mm = Number(y1.slice(5,7))-1
        //   sd = data.years[y].data[0][1][mm][1]
        // }
        setCompareToData(sd)
      }
    }
  }
  

  let maxSales = 0;

  // loop through every day and find the highest day sales
  for (let y=0;y<data.years.length;y++) {
    for (let m=0;m<data.years[y].data[0][1].length; m++) {
      for (let d=0;d<data.years[y].data[0][1][m][1].length;d++) {
        maxSales = Math.max(Number(data.years[y].data[0][1][m][1][d][0]),maxSales)
      }
    }
  }
  
  let years = data.years.map((d,i) => {
    let selectedData = d.data[0]
    // let selectedGrain = 'year'
    let plotGrain = 'month'
    let plotDate = String(d.year)
    let compareDate = String(d.year)
    let plotTitle = plotDate

    if (compareSelection && compareSelection.slice(0,4)===String(d.year)) {
      if (compareSelection.length === 10){
        let mm = Number(compareSelection.slice(5,7))-1
        let dd = Number(compareSelection.slice(8,10))-1
        let m1 = Number(compareToSelection.slice(5,7))-1
        let d1 = Number(compareToSelection.slice(8,10))-1
        let y1 = Number(compareToSelection.slice(0,4))
        // selectedGrain="day"
        plotGrain = 'hour'
        selectedData = getDataByDate(compareSelection)
         //d.data[0][1][mm][1][dd]
        plotDate = shortMonthNames[mm] + ' ' + (dd+1) + ', ' + d.year
        compareDate = shortMonthNames[m1] + ' ' + (d1+1) + ', ' + y1
        plotTitle = plotDate + ' vs ' + compareDate
      } else if (compareSelection.length === 7){
        let mm = Number(compareSelection.slice(5,7))-1
        let m1 = Number(compareToSelection.slice(5,7))-1
        let y1 = Number(compareToSelection.slice(0,4))
        // selectedGrain="month"
        plotGrain = 'day'
        selectedData = getDataByDate(compareSelection)

        // selectedData = d.data[0][1][mm]
        plotDate = shortMonthNames[mm] + ' ' + d.year
        compareDate = shortMonthNames[m1] + ' ' + y1
        plotTitle = plotDate + ' vs ' + compareDate
      }
    } else {
      if (selection[i] !== null && selection[i].length===10) { 
        let mm = Number(selection[i].slice(5,7))-1
        let dd = Number(selection[i].slice(8,10))-1
        // selectedGrain="day"
        plotGrain = 'hour'
        selectedData = getDataByDate(selection[i])
        // selectedData = d.data[0][1][mm][1][dd]
        plotDate = shortMonthNames[mm] + ' ' + (dd+1) + ', ' + d.year
        plotTitle = plotDate
      }
      else if (selection[i] !== null && selection[i].length===7) { 
        let mm = Number(selection[i].slice(5,7))-1
        // selectedGrain="month"
        plotGrain = 'day'
        selectedData = getDataByDate(selection[i])
        plotDate = shortMonthNames[mm] + ' ' + d.year
        plotTitle = plotDate
      }
    }

    let rightTitle = selectedData ? numberWithCommas(selectedData[0]) : '0'

    let detailArea = <div className='row'>
            <BarPlot data={selectedData&&selectedData[1]?selectedData[1]:null} leftTitle={plotTitle} rightTitle={rightTitle} plotType={plotType} compareData={compareSelection && compareSelection.slice(0,4)===String(d.year) ? compareToData[1]: null} timeGrain={plotGrain} labelIndex={plotGrain==='hour'?null:0} valueIndex={plotGrain==='hour'?null:0} />
            <div className='column' style={{height:'170px'}}>
              <Icon id={`compare${i}`} onClick={clickCompare} type={'compare'} active={compareSelection && compareSelection.slice(0,4)===String(d.year)} />
              <IconSelector items={['barChart','lineChart']} activeItem={plotType} onChange={(e)=>{setPlotType(e)}} orientation='vertical' />
            </div>
          </div>

    let sel = selection[i]
    if (compareToSelection && compareToSelection.slice(0,4)!==String(d.year)) {
      sel = null
    }

    return (
    <div key={i}>
      <YearGrid data={data.years[i]} selection={sel} compareSelection={compareSelection} compareToSelection={compareToSelection} onChange={onChange} maxValue={maxSales} />
      {/* <div id={`detail${i}`} className='details expanded'> */}
        {detailArea}
      {/* 
      </div> */}
      <hr style={{ marginBottom:'0.5rem'}} />
    </div>
    )
  })


  years = years.reverse()

  return (
    <div style={{width:'100%'}}>{years}</div>)
}
