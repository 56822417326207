import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { isValidPassword } from '../utils/utils';
import { resetPassword } from '../thunks/user'
import Spinner from '../components/Spinner'


export default function Reset(props) {


  const dispatch = useDispatch();

  const [error,setError] = useState('')
  const [password,setPassword] = useState('')
  const [password2,setPassword2] = useState('')
  let { id, code } = useParams();

  const user = useSelector(state => state.user);

  function buttonPress(e) {
    e.preventDefault()

    // validate fields
    if (!isValidPassword(password)) {
      setError('password must be between 5 and 30 characters long')
    }
    if (password !== password2) {
      setError('passwords must match')
    }

    dispatch(resetPassword({userID:id, code:code, password:password}));
  }

  function changePassword(e) {
    setPassword(e.target.value)
  }

  function changePassword2(e) {
    setPassword2(e.target.value)
  }

  return (
    <div className="form-page">
      <h1>Reset Password</h1>
      <form  onSubmit={buttonPress}>
        <input type="password" name="password" placeholder="new password" value={password} onChange={changePassword} />
        <input type="password" name="password2" placeholder="repeat new password" value={password2} onChange={changePassword2} />
        {error ? <p className='error'>{error}</p>: '' }
        {user.error !== '' ? <p className='error'>{user.error}</p>: '' }
         <br/><br/>
        {user.loading?<Spinner />:<button>Reset Password</button>}  
      </form>
    </div>)
}