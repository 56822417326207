import React from 'react';
import PaymentDial from '../components/PaymentDial'


export default function Dial4() {

  return (
    <div className="page-body">
      <h1>Payment Dial Component</h1>
      <PaymentDial />
      <p className="text-box">Simple payment calculator. Every field except the payment amount can be edited. To edit click on a field to highlight it, then spin the dial.</p>
    </div>)
}
