import React from 'react';

export default function Dot({data, scale, size}) {
  // console.log(data)

  const entries = Object.entries(data)
  let sizes = ["small","medium","large"]
  const hsize = [4,8,16]
  let tooltipItems = []
  let i = 0;
  for (const [key, value] of entries) {
    tooltipItems.push(<p key={i}>{key}: <span>{value}</span></p>)
    i++;
  }
  let style = {backgroundColor:`hsl(200, 91%, ${scale}%)`, height:hsize[sizes.indexOf(size)], width:hsize[sizes.indexOf(size)], borderRadius:'50%'}
  
  
  return (
          <div className="item" style={style}>
            <div className="popup-note">
              {tooltipItems}
            </div>
          </div>
  );
}
