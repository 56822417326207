import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Spinner from '../components/Spinner'
import { Link, useParams } from 'react-router-dom'
import { appendDatasetFile} from '../thunks/dataset'
import cloudIcon from '../images/cloud-icon.svg'


export default function Append() {

  let { id } = useParams();
  const [file, setFile] = useState({name:""})
  const [error,setError] = useState('')
  const dispatch = useDispatch();
  const datasets = useSelector(state => state.datasets);
  const dataset = useSelector(state => state.dataset);

  
  function buttonPress(e) {

    e.preventDefault()

    // make sure a file has been selected and a name has been provided
    if (file.name === '') { setError('You must select a file to upload'); return;}
    dispatch(appendDatasetFile(id, file ));

  }
  
  function fileSelected(e) {
    if (e.target.files.length===0) {
      setFile({name:""})
    } else {
      setFile(e.target.files[0])
    }
  }


  // make sure the dataset is in the list and get the name
  let datasetExists = false
  let datasetName = ''
  console.log(datasets,id)
  for (let i=0;i<datasets.data.length;i++) {
    if (datasets.data[i].datasetID.toString() === id) {
      datasetExists = true;
      datasetName = datasets.data[i].datasetName
    }
  }
  if (!datasetExists) 
    return (
      <div className="page-body">
        <h1>Add Data To Dataset</h1>
        <p className='error'>Dataset Not Found</p>
      </div>)

  return (
    <div className="page-body">
      <h1>Add Data To Dataset - <span>{datasetName}</span></h1>
      <p>Select a file to upload.</p>
      <p>The file must be in the proper format for this dataset type.</p>
      <p> For a detailed list of dataset types, <Link to="/types">click here</Link></p>
      <form  onSubmit={buttonPress}>
        
        <input type="file" name="file" id="file" accept=".csv" onChange={fileSelected} className="inputfile"  />
        <label htmlFor="file">{file.name===''? 'choose a file' : file.name}</label>
        <br/>
        {error ? <p className='error'>{error}</p>: '' }<br/>
        {dataset.uploading ? <Spinner /> :<button><img src={cloudIcon} alt="upload icon"/>Upload</button>}
      </form>
      <br/><br/><br/>
    </div>)
}
