import React from 'react';
import Item from './Item'

export default function ItemGrid({items, sortKey, colorKey, sortAscending, size}) {

  let numbers = items.map(a => a[colorKey])
  let max = Math.max.apply(Math, numbers);
  // let min = Math.min.apply(Math, numbers);
  let ratio = max/80;
   
  // let data = items.map((item, index)=>{ 
  //   let x = item[colorKey]/ratio
  //   let y = x+10
  //   let z = 100-y

  //   100-((row[colorKey]/ratio)+10)
    
  //   return {
  //     ...item,
  //     text: item.Name,
  //     scale: Math.round(z)
  //   }
  //   })
    
    if (sortAscending) { items.sort((a, b) => a[sortKey]-b[sortKey]) }
    else { items.sort((a, b) => b[sortKey]-a[sortKey]) }

  return (
    <div className="item-grid">
      <div>
        { items.map((row,i) => <Item key={i} data={row} scale={100-((row[colorKey]/ratio)+5)} size={size} />) }
      </div>
    </div>
  );
}
