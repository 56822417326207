export const register_user = "user.register";
export const load_user = "user.load";
export const set_user_checking = "user.checking";
export const set_user_profile = "user.profile";
export const set_user_checked = "user.check";
export const set_dataset = "dataset.load"
export const set_datasets = "datasets.load"
export const set_dataset_data = "dataset.load_dataset"
export const set_share = "dataset.set_share"
export const upload_dataset = "dataset.upload"
