import React from "react";
import "../css/Plot.css";

export default function Plot() {

  let colors = ["#16B1FD",'#46CEAC',"#EB5564"]
  
  let lines = []
  let numLines = 100;
  for (let i=0;i<numLines;i++) {
    let line = 
    <g transform={`rotate(${360/numLines*i} 300 300)`}>
      <line x1="300" y1="320" x2="300" y2={Math.random()*280 + 320} style={{ stroke: `${colors[Math.floor(Math.random()*2.9999%3)]}`,strokeWidth:1,strokeLinecap:"round", fill: "none"}}/>
    </g>
    lines.push(line)
  }

  return (
    <div className="plot">
      <div className="frame">
        <div className="tooltip">
          <h6>Label</h6>
          <p>Value</p>
        </div>
        <svg
          version="1.1"
          baseProfile="full"
          className="plot-svg"
          width={600}
          height={600}
          xmlns="http://www.w3.org/2000/svg"
          // preserveAspectRatio="none"
          viewBox="0 0 600 600"
        >
              <circle cx="300" cy="300" r="300" style={{ stroke: "#eee",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />
              <circle cx="300" cy="300" r="200" style={{ stroke: "#eee",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />
              <circle cx="300" cy="300" r="20" style={{ stroke: "#eee",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />
              <circle cx="300" cy="300" r="100" style={{ stroke: "#eee",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />
              {lines}
        </svg>
      </div>
    </div>
  );
}
