import React, { useState, useRef, useEffect } from 'react';
import { Scatter } from '../components/Scatter';
import { SplitBar } from '../components/SplitBar';
import data from '../customers';
import { addDays, getDateString } from '../utils/utils'
import {Icon} from '../components/Icon'


export const DatasetType4 = ({ data }) => {

  const [selection,setSelection] = useState(data.splitBarData.length-1)
  const [playing,setPlaying] = useState(false)
  const playRef = useRef(playing);
  playRef.current = playing;

  // this function removes a list of values from and array and returns the new smaller array
  const removeValues = (array,values) => {
    let newArray = [...array]
    // let tempIndexes = indexes.sort((a, b) => a - b )
    for (let i=values.length-1;i>-1;i--) {
      newArray.splice(newArray.indexOf(values[i]), 1)
    }
    return newArray
  }

  const getAdds = (index) => {
    //get the index into the objects Array
    if (!index) return null
    let minIndex = data.dates[index-1][1]+1
    let maxIndex = data.dates[index][1]
    let size = maxIndex-minIndex+1
    return [...Array(size).keys()].map(i => i + minIndex);
  }

  const getRemoves = (index) => {
    //get the index into the objects Array
    if (!index) return null
    let remIndexes = data.removals.slice(data.dates[index-1][2],data.dates[index][2])
    return remIndexes
  }

  const getInv = (index) => {
    //get the index into the objects Array
    if (!index) return null
    let addIndexes = [...Array(data.dates[index][1]).keys()]
    let remIndexes = data.removals.slice(0,data.dates[index][2])
    return removeValues(addIndexes,remIndexes)
  }


  function onChange(s) {
    setSelection(s)
  }

  
  let scatterData = getInv(selection+data.offset)
  let addData = getAdds(selection+data.offset)
  let removeData = getRemoves(selection+data.offset)
  let ageOffset = data.dates[selection+data.offset][0]


  const tick = () => {
    if (playRef.current) {
      setSelection(s => s===data.splitBarData.length-1?0:s+1)
      setTimeout(tick,1000)
    } else return;
  }

  const play = (e) => {
    setTimeout(tick,1000)
    setPlaying(x => !x)
  }

  return (
    <div style={{width:'100%'}}>
      <Scatter objects={data.objects} indexes={scatterData} adds={addData} removals={removeData} ageOffset={ageOffset} />
      <div className='row'>
        <Icon onClick={play} type={playing?'pause':'play'} active={false} />
        <SplitBar data={data.splitBarData} selection={selection} onChange={onChange} />
      </div>
    </div>)
}
