import React,{ useEffect, useState } from "react";
import "../css/Dial.css";
import customers from '../customers'
import { touch2Mouse } from '../utils/utils'


export default function Dial() {

  // let colors = ["#16B1FD",'#46CEAC',"#EB5564"]

  const [startAngle, setStartAngle] = useState(0)
  const [angle, setAngle] = useState(0)
  const [index, setIndex] = useState(0)
  
  // const clickOff = (e) => {
  //   e.preventDefault();

  //   let t = findAncestor(e.target,"tooltip")
  //   if (t) t.style.visibility = "hidden";
  //   setLinex(0)
  // }

  
  useEffect(() => {
    
    const mouseUp = (e) => {
      e.preventDefault();
      let svg = document.getElementById("plot-svg");
      svg.removeEventListener("mousemove", mouseMove);
      svg.removeEventListener("touchmove", touch2Mouse, true);
    }

    // const mouseMove = (e) => {
    //   e.preventDefault();
    //   // calculate new angle and get difference from start angle
    //   let slope = (e.offsetY-150)/(e.offsetX-150)
    //   let angle = Math.atan(slope) * 180/Math.PI + 90
    //   if (e.offsetX<150) angle += 180
    //   let diff = (startAngle+angle)%360
    //   console.log(startAngle,diff)
    //   setAngle(diff)
    //   let index = Math.floor(diff * customers.length/360)
    //   setIndex(index)
    // }
  
    const mouseDown = (e) => {
      e.preventDefault(); 
      // register the touch move

      let svg = document.getElementById("plot-svg");
      svg.addEventListener("mousemove", mouseMove);
      svg.addEventListener("touchmove", touch2Mouse, true);
      svg.addEventListener("mouseup", mouseUp);
      svg.addEventListener("touchend", touch2Mouse, true);
      let rect = svg.getBoundingClientRect()
      
      // // calculate the start angle
      let x = e.clientX-rect.x
      let y = e.clientY-rect.y
      let slope = (y-150)/(x-150)
      // // console.log('slope',slope)
      let angle = Math.atan(slope) * 180/Math.PI + 90
      if (x<150) angle += 180
      setStartAngle(angle)
    }
  
    const mouseMove = (e) => {
      e.preventDefault(); 

      let svg = document.getElementById("plot-svg");
      let rect = svg.getBoundingClientRect()
     
      // calculate the start angle 
      // let x = e.touches[0].clientX-rect.x
      // let y = e.touches[0].clientY-rect.y
      let x = e.clientX-rect.x
      let y = e.clientY-rect.y
      let slope = (y-150)/(x-150)
      let angle = Math.atan(slope) * 180/Math.PI + 90
      if (x<150) angle += 180
      let diff = (startAngle+angle)%360
      setAngle(diff)
      let index = Math.floor(diff * customers.length/360)
      setIndex(index)
    }
  
    // const mouseDown = (e) => {
    //   // e.preventDefault();
    //   // console.log(e.offsetX,e.offsetY)

    //   // get the main svg element
    //   // register the mouse move
    //   let svg = document.getElementById("plot-svg");
    //   svg.addEventListener("mousemove", mouseMove);
    //   svg.addEventListener("touchmove", touch2Mouse);
    //   svg.addEventListener("touchmove", touchMove);

    //   // calculate the start angle
    //   let slope = (e.offsetY-150)/(e.offsetX-150)
    //   // console.log('slope',slope)
    //   let angle = Math.atan(slope) * 180/Math.PI + 90
    //   if (e.offsetX<150) angle += 180
    //   setStartAngle(angle)
    // }

    let svg = document.getElementById("plot-svg");
    svg.addEventListener("mousedown", mouseDown);
    // window.addEventListener("mouseup", mouseUp);
    svg.addEventListener("touchstart", touch2Mouse, true);
    // window.addEventListener("touchend", touch2Mouse, true);
    
    return () => {
      let svg = document.getElementById("plot-svg");
      svg.removeEventListener("mousedown", mouseDown);
      // svg.removeEventListener("mousemove", mouseMove);
      svg.removeEventListener("mouseup", mouseUp);
      svg.removeEventListener("touchstart", touch2Mouse, true);
      // svg.removeEventListener("touchmove", touch2Mouse);
      svg.removeEventListener("touchend", touch2Mouse, true);
    };

  }, [startAngle]);

  let lines = []
  for (let i=0;i<100;i++) {
    let line = 
    <g key={i} transform={`rotate(${360/100*i} 150 150)`}>
      <line x1="150" y1='10' x2="150" y2='40' style={{ stroke: `${i%10===0 ? '#16B1FD':'#dadada'}`,strokeWidth:1,strokeLinecap:"round", fill: "none"}}/>
    </g>
    lines.push(line)
  }

  return (
    <div className="dial">
        <svg
          version="1.1"
          baseProfile="full"
          id="plot-svg"
          className="plot-svg"
          width={300}
          height={300}
          xmlns="http://www.w3.org/2000/svg"
          // preserveAspectRatio="none"
          viewBox="0 0 300 300"
        >
              <circle cx="150" cy="150" r="148" style={{ stroke: "#16B1FD",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />
              <circle cx="150" cy="150" r="100" style={{ stroke: "#16B1FD",strokeWidth:1,strokeLinecap:"round", fill: "none"}} />

              <g transform={`rotate(${angle} 150 150)`}>
                {lines}
              </g>
              <text
              className="dial-title"
              x={150}
              y={100}
              textAnchor="middle">
              {index}
            </text> 
            <text
              className="dial-metric"
              x={150}
              y={135}
              textAnchor="middle">
              {Math.round(customers[index].totalSales*100)/100}
            </text>
            <text
              x={150}
              y={150}
              textAnchor="middle">
              Total Sales
            </text> 
            <text
              className="dial-metric"
              x={150}
              y={185}
              textAnchor="middle">
              {Math.round(customers[index].avgOrderSize*100)/100}
            </text>
            <text
              x={150}
              y={200}
              textAnchor="middle">
              Average Order Value
            </text>
        </svg>
    </div>
  );
}
