import React from 'react';
import logo from '../images/logo-name-white.svg'
import { Link } from 'react-router-dom'

export default function Footer() {

    return (
        <div className="footer">
          <div className="main"><img src={logo} alt="clarity logo"/></div>
          <div className="strip">
            <p>Version 0.9.69</p>
            <div>
              <Link to="/experiments">experiments</Link>
            </div>
          </div>
        </div>
    );
  }
