import React, { useState, useRef, useEffect } from 'react';
import { useSearchParams,useNavigate, useLocation  } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import { isValidEmail, isValidPassword } from '../utils/utils';
import Checkbox from '../components/Checkbox'
import { createUser } from '../thunks/user'
import Spinner from '../components/Spinner'


export default function Signup() {


  const dispatch = useDispatch();

  const [password,setPassword] = useState('')
  const [error,setError] = useState('')
  const [email,setEmail] = useState('')
  const [checked,setChecked] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  let [searchParams] = useSearchParams();
  let initialEmail = searchParams.get("email");

  const formElement = useRef(null);
  
  const user = useSelector(state => state.user);

  useEffect(()=>{if (initialEmail) setEmail(initialEmail)},[initialEmail])


  useEffect(() => {
    if (user.profileLoaded) {
      if(location?.state?.from){
        navigate(location.state.from)
      } else {
        navigate('/datasets')
      }
    }
  }
  ,[user.profileLoaded,navigate,location])

  function buttonPress(e) {
    e.preventDefault()

    // validate fields
    if (!isValidEmail(email)) {
      setError('invalid email')
    }
    else if (!isValidPassword(password)) {
      setError('password must be between 5 and 30 characters long')
    }

    dispatch(createUser({email:email,password:password, autoRenew:checked}));

  }

  function changeEmail(e) {
    setEmail(e.target.value)
  }

  function changePassword(e) {
    setPassword(e.target.value)
  }

  function changeChecked(e) {
    setChecked(e.target.checked)
  }


  return (
    <div className="form-page">
      <h1>Create New Account</h1>
      <form  onSubmit={buttonPress} ref={formElement}>
        <input type="text" name="email" placeholder="email" value={email} onChange={changeEmail} />
        <input type="password" placeholder="password" value={password} onChange={changePassword} />
        {error ? <p className='error'>{error}</p>: '' }
        {user.error !== '' ? <p className='error'>{user.error}</p>: '' }
        <Checkbox text="Keep me logged in on this device." checked={checked} onChange={changeChecked}/>
        <br/><br/>
        {user.loading?<Spinner />:<button>Create New Account</button>}
      <hr/>
      <p>Already have an account?</p>
      {/* this link will pass along the email to the logn page in the url if it exists */}
      <Link to={"/login" + (email !== '' ? "?email=" + email : "")}>Login</Link>
        
      </form>
    </div>)
}