import { set_datasets } from "../actions/constant";

const initialState = {
  status: 'empty',
  data: [],
  error:''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case set_datasets:
      return {
        ...state,
        status: action.payload.status,
        data: action.payload.data,
        error: action.payload.error
      };
  
    default:
      return state;
  }
};