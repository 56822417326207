import React from 'react';
import '../css/home-page.css'
import shareIcon from '../images/share-icon-large.svg'
import deviceIcon from '../images/device-icon.svg'
import chartIcon from '../images/chart-icon.svg'
import lineIcon from '../images/line-blue.svg'
import line2Icon from '../images/line-white.svg'
import cloud from '../images/cloud.svg'
import visuals from '../images/visuals.svg'
import phoneImage from '../images/phoneImage.png'
import { Link } from 'react-router-dom'

export default function Home() {

  // const ImageLink = ({image, title,to}) => {
  //   return (
  //     <Link to={to}>
  //       <div className="image-link">
  //         <img alt={title} src={image} />
  //         <p>{title}</p>
  //       </div>
  //     </Link>
  //   )
  // }



  return (
    <div className="home-page">
      <div className="top-background">
        <svg height="800" width="100%">
          <circle cx="150" cy="400" r="60" stroke="white" strokeOpacity="0.2" fill="transparent"/>
          <circle cx="250" cy="150" r="50" stroke="white" strokeOpacity="0.1" fill="transparent"/>
          <circle cx="400" cy="300" r="30" stroke="white" strokeOpacity="0.15" fill="transparent"/>
          <circle cx="600" cy="400" r="60" stroke="white" strokeOpacity="0.2" fill="transparent"/>
          <circle cx="800" cy="200" r="20" stroke="white" strokeOpacity="0.2" fill="transparent"/>
          <circle cx="1200" cy="400" r="50" stroke="white" strokeOpacity="0.2" fill="transparent"/>
        </svg>
      </div>

      <div className="top">
        <div className="main-copy">
          <h1>Instant <span>Clarity</span><br/>from your data</h1>
          <h4>Instantly view, share, and interact with <br/>data from any spreadsheet or database.</h4>
          <h4>Insights at your fingertips</h4><br/><br/><br/>
          <Link to="/signup"><button>TRY IT NOW</button></Link>
        </div>
        <img height="450" src={phoneImage} alt="phone"/>
      </div>

      <div className="icon-section">
        <div className="icon-item">
          <img src={chartIcon} alt="chart icon"/>
          <p>Visualize<br/>Your Data</p>
        </div>
        <div className="icon-item">
        <img src={deviceIcon} alt="device icon"/>
          <p>On Any<br/>Device</p>
        </div>
        <div className="icon-item">
        <img src={shareIcon} alt="share icon"/>
          <p>Share<br/>Securely</p>
        </div>
      </div>

      <div className="line1">
        <img src={lineIcon} alt="line icon"/>
      </div>

      <div className="middle">
        <div className="line1">
          <img src={line2Icon} alt="line icon"/>
        </div>

        <div className="middle-row">
          <div className="text-area">
            <h2>Upload Your Data</h2>
            <p>Take data from any source,<br/>convert it to a CSV, and<br/>upload it to our secure cloud.</p>
          </div>
          <img src={cloud} style={{width:"220px", height:"200px"}} alt="upload icon"/>
        </div>

        <div className="middle-row">
          <img src={visuals} style={{width:"220px", height:"200px"}} alt="chart visual"/>
          <div className="text-area">
            <h2>Get Instant Clarity</h2>
            <p>Get an interactive visualization<br/>that can be shared with anyone<br/>and viewed on any device.</p>
          </div>
        </div>
        <br/><br/><br/><br/><br/>
      </div>
    </div>)
}
