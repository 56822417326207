import React from 'react';
import '../css/Checkbox.css'

export default function Checkbox({text,checked,onChange}) {

  return (
    <div className="checkbox">
      <input type="checkbox" id="checkbox" checked={checked} onChange={onChange} />
      <label htmlFor="checkbox"><span>{text}</span></label>
    </div>
  );
}
