import React from 'react';
import {Icon} from '../components/Icon'


export const IconSelector = ({items, activeItem, onChange, orientation}) => {

  let dimensions = items.map((item,index)=> { 
    return <Icon key={index} onClick={onChange} type={item} active={item === activeItem} />
  })

  let style = orientation==='vertical' ? {height:items.length*34} : {width:items.length*34}


  return (
    <div className={orientation==='vertical'?'column':'row'} style={style}>
      {dimensions}
    </div>
  );
}
