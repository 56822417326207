import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { isValidEmail } from '../utils/utils';
import { forgotUser } from '../thunks/user'
import Spinner from '../components/Spinner'


export default function Login() {


  const dispatch = useDispatch();

  const [error,setError] = useState('')
  const [email,setEmail] = useState('')

  const user = useSelector(state => state.user);

  function buttonPress(e) {
    e.preventDefault()

    // validate fields
    if (!isValidEmail(email)) {
      setError('invalid email')
    }

    dispatch(forgotUser({email:email}));

  }

  function changeEmail(e) {
    setEmail(e.target.value)
  }

  return (
    <div className="form-page">
    <h1>Forgot Password</h1>
      <form  onSubmit={buttonPress}>
        <p>Enter the email address associated with your account and we will send you a link to reset your password.</p>
        <input type="text" name="email" placeholder="email" value={email} onChange={changeEmail} />
        {error ? <p className='error'>{error}</p>: '' }
        {user.error !== '' ? <p className='error'>{user.error}</p>: '' }
         <br/><br/>
        {user.loading?<Spinner />:<button>Request Password Reset Email</button>}  
      </form>
    </div>)
}