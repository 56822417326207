import React from 'react'
import { Link } from 'react-router-dom'

export default function Experiments() {

  return (
    <div className="page-body">
      <h1>Experiments</h1>
      <Link to="/dial">dial</Link>
      <br/>
      <Link to="/dial2">dial2</Link>
      <br/>
      <Link to="/dial3">dial3</Link>
      <br/>
      <Link to="/dial4">payment dial</Link>
      <br/>
      <Link to="/splitdiv">split div</Link>
      <br/>
      <Link to="/grid">grid</Link>
      <br/>
      <Link to="/dotgrid">dot grid</Link>
      <br/>
      <Link to="/radial">radial</Link>
    </div>)
}