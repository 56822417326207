import { setDatasets } from "../actions/datasets";



export const getDatasets = () => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    // dispatch(setDataset({ status:dataset: {}, error:'' }));
    dispatch(setDatasets({ status:'loading', data:[], error:'' }));

    const response = await remote(
      "get",
      `/datasets`,
      null,
      "loading datasets summary"
    );

    if (response.status !== 200) {
      dispatch(setDatasets({ status: 'loaded', data: [], error: response.data.message }));
      return;
    }

    dispatch(setDatasets({ status:'loaded', data: response.data, error:'' }));

  } catch (error) {
    console.log(error)
    // dispatch(setDataset({ dataset: {}, error:'problem loading dataset' }));
    dispatch(setDatasets({ status: 'loaded', data: [], error:'problem loading dataset' }));
  }
}