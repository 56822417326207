import React from 'react';
import logo from '../images/logo-name-white.svg'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from '../thunks/user'

export default function Header() {

const dispatch = useDispatch();
const user = useSelector(state => state.user);
 
  return (
    <nav>
      <Link className="logo" to="/">
          <img src={logo} alt="clarity logo"/>
      </Link>
      { (user.checked && user.profileLoaded ) ?
      <div>
        <NavLink className={({isActive}) => (isActive ? 'is-active' : null)} to="/datasets">datasets</NavLink>
        <NavLink className={({isActive}) => (isActive ? 'is-active' : null)} to="/account">account</NavLink>
        <NavLink className={({isActive}) => (isActive ? 'is-active' : null)} to="/login" onClick={(e)=>{ dispatch(logoutUser()) }}>logout</NavLink>
      </div>
      : 
      <NavLink to="/login">login</NavLink> }
    </nav>
  );
}
