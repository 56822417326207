import React, { useRef, useEffect } from 'react';
import { select, scaleBand, scaleLinear, axisLeft, axisBottom, format, max, line } from 'd3';
import { useResizeObserver } from '../hooks/useResizeObserver';
import { touch2Mouse, findAncestor, dollarFormat, months, roundNumber } from '../utils/utils'


export const ParetoPlot = ({ data, timeGrain, label, labelIndex, valueIndex, total, count, title }) => {

  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const dimensions = useResizeObserver(wrapperRef);

  // const clickOff = (e) => {
  //   e.preventDefault();
  //   let t = findAncestor(e.target,"tooltip")
  //   if (t) t.style.visibility = "hidden";
  // }
  
  useEffect(
    () => {

      if (data.length===0) return

      // create the plot data
      let len = data.length
      let plotData = []
      for (let i =0;i<len;i++) {
        plotData[i] = []
        plotData[i][0] = i<data.length ? data[i][labelIndex] : 0
        plotData[i][1] = i<data.length ? data[i][valueIndex] : 0
      }
      let sumData = []
      let sum = 0
      if (total) {
        for (let i =0;i<plotData.length;i++) {
          sum += i<data.length ? Number(data[i][valueIndex]) : 0
          sumData[i] = sum
        }
        if (Number(roundNumber(sum,2))<Number(roundNumber(Number(total),2))) {
          let l =  plotData.length
          plotData[l] = []
          let allOthers = count-plotData.length+1
          plotData[l][0] = allOthers + " Others"
          plotData[l][1] = total-sum
          sumData[sumData.length] = total
        }
      }

      sum = 0
      
      const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

      let blue="#16B1FD"
      let tan = '#EAA61B'

      let form = (i) => plotData[i][0];
        if (timeGrain === 'year') {
          form = (i) => data[i].year
        } else if (timeGrain === 'month') {
          form = (i) => months[i]
        } else if (timeGrain === 'day') {
          form = (i) => i+1
        } else if (timeGrain === 'hour') {
          form = (i) => i
        }

      // get the maximum label length and use it to determine the bottom margin
      let maxLabelLength = 0
      for (let i=0;i<plotData.length;i++){
        maxLabelLength = Math.max(maxLabelLength,form(i).toString().length)
      }
      if (total) maxLabelLength = Math.max(maxLabelLength,plotData[plotData.length-1][1].length)
      let bottomMargin = 70
      if (maxLabelLength<8) {
        bottomMargin = 30;
      }
      
      let margin = ({top:30, right: 0, bottom:bottomMargin, left: 30});
      const tickDensity = width>780 ? 1 : 5

      let xAxis, yAxis = null;

      const x = scaleBand()
        .range([margin.left, width - margin.right])
        .domain(plotData.map((d,i) => i ))
        .padding(0.2)

      const invertX = (pixels) => {
        const totalBandwidth = (width - margin.left - margin.right) / (plotData.length)
        if (pixels<margin.left) return -1
        let index = Number(Math.floor((pixels-margin.left)/totalBandwidth))
        return index
      }

      let maxValue = 0;
      maxValue = max(plotData, d => Number(d[1]))
      
      let y = scaleLinear()
        .domain([0, (maxValue)]).nice()
        .range([height - margin.bottom, margin.top])

      let y1 = scaleLinear()
      .domain([0, (total)]).nice()
      .range([height - margin.bottom, margin.top])

      xAxis = g => g
          .attr("transform", `translate(0,${height - margin.bottom + 3})`)
          .call(axisBottom(x)
                  .tickSize(0)
                  .tickValues(x.domain().filter(function(d,i){ return !(i%tickDensity)}))
                  .tickFormat(form))
                  // .tickFormat(labelFunction))
          .call(g => g.select(".domain").remove())
          
      yAxis = g => g
        .attr("transform", `translate(${width},0)`)
        .call(axisLeft(y).tickSize(width-margin.left-margin.right).ticks(2, format("3s")))
        .call(g => g.select(".domain").remove())
      
        const svg = select(svgRef.current)

        svg.select(".path").attr("stroke-opacity", 0.0)
        svg.selectAll('.bar')
          .data(plotData)
          .join('rect')  
          .attr('class','bar')
          .attr("fill", blue)
          .style('transform','scale(1,-1')
          .attr("x", (d, i) => x(i))
          .attr("y", -y(0))
          .attr("width", x.bandwidth())
          .attr("height", d => y(0) - y(Number(d[1])))
          
        // svg.select(".path").attr("stroke-opacity", 0.0)
        svg.select("path")
        .datum(sumData)
        .attr("fill", "none")
        .attr("stroke",tan)
        .attr("stroke-width", 1.5)
        .attr("stroke-opacity", 1.0)
        .attr("d", line()
          .x(function(d,i) { return x(i)+x.bandwidth()/2 })
          .y(function(d,i) { return y1(d) })
          )

        svg.select('.y-axis')
          .call(yAxis)
          .select('.tick').remove()

        svg.select('.x-axis')
          .call(xAxis)
          .selectAll("text")
            .attr("y", 0)
            .attr("x", 9)
            .attr("dy", ".35em")
            .attr("transform", 'rotate(90)')
            .style("text-anchor", "start");
        svg.select('.title').remove()
        svg.append('text')
          .attr('class','title')
          .attr("x", width/2)
          .attr("y", 15)
          .attr("text-anchor", "middle")
          .text(title);
            

        let p = findAncestor(svgRef.current,"plot")
        let t = p.querySelector('.tooltip')
        t.style.visibility = 'hidden'
        svg.selectAll('.highlight').remove()

        
        svg.on('touchstart', touch2Mouse,true )
        svg.on('touchmove', touch2Mouse,true )
        svg.on('touchout', touch2Mouse,true )

        const handle = (e) => {
          let x1 = e.clientX-svgRef.current.getBoundingClientRect().x
          let y1 = e.clientY-svgRef.current.getBoundingClientRect().y

          const index = invertX(x1)
          if (index<0 || y1<margin.top || x1>width-margin.right) {
            t.style.visibility = "hidden"
            svg.selectAll('.highlight').remove()
            return
          }

          let pctTotal = 0
          let pctCount = 0
          let cnt = 0
          let htmlString = `<h5 class="title" style="color:#444">${form(index)}</h5>`;
          htmlString += `<p class="value" style="color:${blue}">$ ${dollarFormat(plotData[index][1])}</p>`;
          if (total) {
            pctTotal = sumData[index]/total*100
            pctCount = index/count*100
            cnt = index+1
            if (index === 30) {
              pctCount = 100
              cnt = count
            }
            htmlString += `<p class="value" style="color:${blue}">Top ${cnt} ${label}, ${roundNumber(pctCount,2)}%</p>`;
            htmlString += `<p class="value" style="color:${tan}">= ${roundNumber(pctTotal,2)}% of sales</p>`;
          }
    
          if (t) {
            t.style.visibility = "visible"
            let left = 'unset'
            let right = 'unset'
            if (e.clientX<width/2) {
              left = x(index)+x.bandwidth()/2+10 + 'px'
            } else {
              right = width-margin.left-x(index)+x.bandwidth()/2+10 + 'px'
            }
            t.style.left = left
            t.style.right = right
            t.innerHTML = htmlString;
          }
          svg.selectAll('.highlight').remove()
         
          svg.append('rect')
              .attr('class','highlight')
              .attr('x', x(index))
              .attr('y', margin.top)
              .attr('width', x.bandwidth())
              .attr('height', height-margin.bottom-margin.top)
              .attr('fill-opacity',0.0)
              .attr('stroke','#000')
          
        }
        svg.on('mousedown', handle,true )
        svg.on('mousemove', handle,true)
      },

      [ data, count, label, labelIndex, valueIndex, total, timeGrain, title, dimensions ])

      
  return (
    <div className="row" >
      <div className="plot" ref={wrapperRef} style={{height:'170px',marginRight:'10px'}}>
        <div className="tooltip" styles={{visibility:'hidden'}}></div>
        <svg ref={svgRef} style={{width:'100%',height:'100%'}}>
          <g className="x-axis" />
          <g className="y-axis" />
          <path className="path" />
          <path className="path2" />
        </svg>
      </div>
    </div>
  );
}