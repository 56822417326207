import React from "react";
import "../css/Spinner.css";

export default function Spinner() {
  return (
    <div className="spinner">
      <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="20" />
      </svg>
    </div>
  );
}
