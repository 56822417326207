import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { isValidPassword } from '../utils/utils';
import { updatePassword } from '../thunks/user'
import Spinner from '../components/Spinner'


export default function Reset() {


  const dispatch = useDispatch();

  const [error,setError] = useState('')
  const [password,setPassword] = useState('')
  const [password2,setPassword2] = useState('')
  const [show,setShow] = useState(false)

  const user = useSelector(state => state.user);

  function buttonPress(e) {
    e.preventDefault()

    // validate fields
    if (!isValidPassword(password)) {
      setError('password must be between 5 and 30 characters long')
    }
    if (password !== password2) {
      setError('passwords must match')
    }

    dispatch(updatePassword({password:password}));
  }

  function changePassword(e) {
    setPassword(e.target.value)
  }

  function changePassword2(e) {
    setPassword2(e.target.value)
  }

  function toggle() {
    setShow((s)=> !s)
  }

  return (
    <div className="page-body">
      <h1>My Account</h1>
      <p><span>email:</span> {user.data.email}</p>
      {!show ? <button className="small-white-button" onClick={toggle}>Update Password</button> : 
      <form  onSubmit={buttonPress}>
        <input type="password" name="password" placeholder="new password" value={password} onChange={changePassword} />
        <input type="password" name="password2" placeholder="repeat new password" value={password2} onChange={changePassword2} />
        {error ? <p className='error'>{error}</p>: '' }
        {user.error !== '' ? <p className='error'>{user.error}</p>: '' }
         <br/><br/>
        {user.loading?<Spinner />:<button>Update Password</button>}  
      </form>}
    </div>)
}