import { set_dataset,set_dataset_data, upload_dataset,set_share } from "./constant";

export const setDataset = val => ({
  type: set_dataset,
  payload: val
});

export const setDatasetData = val => ({
  type: set_dataset_data,
  payload: val
});

export const uploadDataset = val => ({
  type: upload_dataset,
  payload: val
});

export const setShare = val => ({
  type: set_share,
  payload: val
});