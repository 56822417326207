import React, {useState,useEffect, useRef} from 'react';
import '../css/DropSelector.css'
import downArrow from '../images/down-arrow.svg'
import { findAncestor } from '../utils/utils'


export default function DropSelector({items, selectedItem, onChange, label}) {

  const [open,setOpen] = useState(false);
  const dropRef = useRef(null)

  function clicky(e){
      setOpen((open)=> !open);
  }
  
  function selectDim(e){
    let el = findAncestor(e.target, "option")
    let key = el.getElementsByClassName("dimension")[0].innerHTML;
    onChange(key)
  }

  function handleClickOutside(e){ 
    if (dropRef.current && !dropRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropRef]);


  let dimensions = items.map((item,index)=> { 
    if (item===selectedItem) {
      return <div key={index} className="option selected" onClick={selectDim}><p className="dimension">{item}</p></div>
    } else {
      return <div key={index} className="option" onClick={selectDim}><p className="dimension">{item}</p></div>
    }
  })

  let cls = open ? 'drop-selector open': 'drop-selector'
      
  return (
    <div ref={dropRef} className={cls} id="drop" onClick={clicky}>
      <p className="drop-selector-label">{label}</p>
      <div className="drop-selector-frame">
      <div className="popup">{dimensions}</div>
      <div className="top-part">
        {/* <img src={sales} id='sort-icon' alt="sort icon"/> */}
        <div className="option selected">
          {/* <img alt="icon" src={sales} /> */}
          <p>{selectedItem}</p>
        </div>
        <img className="arrow-icon" alt="down arrow" src={downArrow} />
      </div>
      </div>
    </div>
  );
}
