import React, {useState} from 'react';
import ItemGrid from '../components/ItemGrid'
import DropSelector from '../components/DropSelector'
import data from '../customers' 
import '../css/Grid.css'


export default function Grid() {


  const [sortKey,setsortKey] = useState(Object.keys(data[0])[3])
  const [colorKey,setColorKey] = useState(Object.keys(data[0])[6])
  const [sortAscending,setSortAscending] = useState(false)
  const [size,setSize] = useState("medium")

  function changeDirection(){
    setSortAscending(sortAscending => !sortAscending)
  }

  function changeSortKey(index){
    setsortKey(index)
  }

  function changeColorKey(index){
    setColorKey(index)
  }

  function changeSize(index){
    setSize(index)
  }

  return (
    <div className="page-body">
      <h1>Grid</h1>
      <div className="grid">
        <div className="topline">
          <DropSelector label="Sort By ..." items={Object.keys(data[0])} selectedItem={sortKey} onChange={changeSortKey} />
          <DropSelector label="Sort Direction ..." items={["ascending","descending"]} selectedItem={sortAscending?"ascending": "descending"} onChange={changeDirection} />
          <DropSelector label="Grid Size ..." items={["small","medium","large"]} selectedItem={size} onChange={changeSize} />
          <DropSelector label="Color By ..." items={Object.keys(data[0])} selectedItem={colorKey} onChange={changeColorKey} />
        </div>
        <div>
          <ItemGrid items={data} sortKey={sortKey} sortAscending={sortAscending} colorKey={colorKey} size={size}/>
        </div>
      </div>
      <p className="text-box">This is a grid concept which displays information in a grid of fixed sized squares. One dimension can be used to color the squares and a different one can be used to sort the square. A rollover provides a popup with more details. May be useful for quickly visualizing multiple dimentions on a reasonably large chunk of items. Inspired by the stock market grid I've seen on business news.</p>
    </div>)
    
}
