import axios from "axios";
// import { setLoading } from "./action";
// import { redirectToLogin } from "../Security/thunk";
// import { showSessionExpiredModal } from "../Security/action";

// const config = {};

// let sessionTimeout;
// let sessionExpiresInMS = 1620000; // default to 27 minutes.. session is currently 30 minutes!

// export const init = (baseURL, appId) => {
//   config.baseURL = baseURL;
//   config.appId = appId;
// };

/**
 * Send HTTP request
 *
 * @async
 * @param {String} method - HTTP request methods
 * @param {String} path - URL Path
 * @param {(Object | null)} body - HTTP request body object
 * @param {String} description - description of HTTP request
 * @param {Dispatch} dispatch - React Redux Dispatch function
 * @returns {Promise} Promise object represents the Response or Error provided from server
 */
export const remote = async (method, path, body, description, credentials=true) => {
  try {
    // dispatch(setLoading(true));

    // const isLogoutRoute = method === "delete" && path === "authentication";

    // if (sessionTimeout !== undefined && config.appId === "") {
    //   clearTimeout(sessionTimeout);
    // }

    const axiosConfig = {
      baseURL: 'https://api.clarityquick.com/',
      timeout: 180000, // 3 minute timeout
      responseType: "json",
      withCredentials: credentials
    };

    const newInstance = axios.create(axiosConfig);
    const response = await newInstance[method](path, body);

    return response;
  } catch (error) {
    console.log(error)
    console.error(`Error: ${description}`, error.response, new Date());
    // dispatch(setLoading(false));

    // if (error.response.status === 401) {
    //   dispatch(redirectToLogin());

    //   return;
    // }

    return error.response;
  }
};
