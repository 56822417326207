import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Spinner from '../components/Spinner'
import { Link } from 'react-router-dom'
import { uploadDatasetFile} from '../thunks/dataset'
import cloudIcon from '../images/cloud-icon.svg'


export default function Upload() {

  const [file, setFile] = useState({name:""})
  const [type, setType] = useState('Single Column')
  const [datasetName, setDatasetName] = useState('')
  const [error,setError] = useState('')
  const dispatch = useDispatch();
  // const orders = useSelector(state => state.orders);
  const datasetTypes = useSelector(state => state.user.profile.datasetTypes);
  const datasets = useSelector(state => state.user.profile.datasets);
  const dataset = useSelector(state => state.dataset);

  
  function buttonPress(e) {

    e.preventDefault()

    // get type id from type name
    let typeID = 0
    for (let i=0;i<datasetTypes.length; i++){
      if (datasetTypes[i].datasetTypeName === type) {
        typeID= datasetTypes[i].datasetTypeID
      }
    }

    // make sure a file has been selected and a name has been provided
    if (file.name === '') { setError('You must select a file to upload'); return;}
    if (datasetName === '') { setError('You must provide a name for the dataset'); return;}

    // if the dataset already exists show an error
    for (let i=0;i<datasets.length;i++) {
      if (datasets[i].datasetName === datasetName) {
        setError('You have already used this name. Dataset names must be unique.');
        return;
      }
    }
    
    dispatch(uploadDatasetFile(typeID, datasetName, file ));

  }
  
  function fileSelected(e) {
    if (e.target.files.length===0) {
      setFile({name:""})
    } else {
      setFile(e.target.files[0])
    }
  }
  
  function typeChanged(e) {
    setType(e.target.value)
  }
  
  function nameChanged(e) {
    setDatasetName(e.target.value)
  }

  let types = datasetTypes.map((item, index) => <option key={index} value={item.datasetTypeName}>{item.datasetTypeName}</option>)

  let names = datasets.map((item, index) => <option key={index} value={item.datasetName} />)

  return (
    <div className="page-body">
      <h1>Upload Data</h1>
      <p>Select the type of dataset you want to upload and give it a unique name. For a detailed list of dataset types, <Link to="/types">click here</Link>.</p>
      <form  onSubmit={buttonPress}>
        <div className="row" style={{margin:'10px 0', justifyContent:'flex-start'}}>
          <label style={{width:'70px', textAlign:'left'}} htmlFor="types">Type:</label>
          <div className="select">
            <select id="types" onChange={typeChanged}>
              {types}
            </select>
          </div>
        </div>
        {/* <input list="types" name="type" value={type} onChange={typeChanged} placeholder="dataset type" />
        <datalist id="types">
          {types}
        </datalist> */}
        <input list="datasetName" name="datasetName" value={datasetName} onChange={nameChanged} placeholder="dataset name" />
        <datalist id="names">
          {names}
        </datalist>
        <input type="file" name="file" id="file" accept=".csv" onChange={fileSelected} className="inputfile"  />
        <label htmlFor="file">{file.name===''? 'choose a file' : file.name}</label>
        <br/>
        {error ? <p className='error'>{error}</p>: '' }<br/>
        {dataset.uploading ? <Spinner /> :<button><img src={cloudIcon} alt="upload icon"/>Upload</button>}
      </form>
      <br/><br/><br/>
    </div>)
}
