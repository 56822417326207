import React from 'react';
import Dial from '../components/Dial'


export default function DialPage() {

  return (
    <div className="page-body">
      <h1>Dial Component</h1>
      <Dial />
      <p className="text-box">This is a dial component that can show data for one of a large list of items that can be quickly scrolled through by "turning" the dial. I'm not sure what this would be useful for, but I like the look and feel of it.</p>
    </div>)
}
