import { set_dataset, set_dataset_data, upload_dataset, set_share } from "../actions/constant";

const initialState = {
  status: 'empty',
  uploading: false,
  dataset: {},
  data: [],
  postingShare: false,
  shareError: false,
  error:''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case set_dataset:
      return {
        ...state,
        status: action.payload.status,
        dataset: action.payload.dataset,
        error: action.payload.error
      };

    case set_dataset_data:
      return {
        ...state,
        status: action.payload.status,
        data: action.payload.data,
        error: action.payload.error
      };
      
    case upload_dataset:
      return {
        ...state,
        uploading: action.payload.uploading,
        error: action.payload.error
      };
    
    case set_share:
      return {
        ...state,
        postingShare: action.payload.postingShare ,
        shareError: action.payload.shareError
      };
  
    default:
      return state;
  }
};