let data = [
  {
    "id": "5eb822882a0f22dd4875a10a",
    "name": "Bridgett Roman",
    "gender": "female",
    "totalSales": 1185.853939,
    "firstOrderDate": "2018-05-22",
    "avgOrderSize": 90.231055,
    "AvgDaysBetweenOrders": 33
  },
  {
    "id": "5eb8228883abfef55fa46149",
    "name": "Rosario Kemp",
    "gender": "female",
    "totalSales": 9083.305587,
    "firstOrderDate": "2016-10-23",
    "avgOrderSize": 269.023076,
    "AvgDaysBetweenOrders": 39
  },
  {
    "id": "5eb822884b280edc7e4059aa",
    "name": "Delores Sampson",
    "gender": "female",
    "totalSales": 6090.458735,
    "firstOrderDate": "2014-06-23",
    "avgOrderSize": 127.240067,
    "AvgDaysBetweenOrders": 85
  },
  {
    "id": "5eb82288a7d99631c1dbddc4",
    "name": "Mabel Deleon",
    "gender": "female",
    "totalSales": 8122.667582,
    "firstOrderDate": "2015-01-14",
    "avgOrderSize": 222.245188,
    "AvgDaysBetweenOrders": 127
  },
  {
    "id": "5eb822888bc46e102d7fbdbe",
    "name": "Powers Stanton",
    "gender": "male",
    "totalSales": 1596.521314,
    "firstOrderDate": "2019-10-19",
    "avgOrderSize": 28.457027,
    "AvgDaysBetweenOrders": 218
  },
  {
    "id": "5eb82288850ce21b2e56ddbc",
    "name": "Staci Berger",
    "gender": "female",
    "totalSales": 9220.988003,
    "firstOrderDate": "2014-05-14",
    "avgOrderSize": 193.198272,
    "AvgDaysBetweenOrders": 196
  },
  {
    "id": "5eb8228853bc9df102b0b9cf",
    "name": "Trina Wynn",
    "gender": "female",
    "totalSales": 8195.570302,
    "firstOrderDate": "2015-12-13",
    "avgOrderSize": 75.465266,
    "AvgDaysBetweenOrders": 205
  },
  {
    "id": "5eb82288a45f8bb3e3128d18",
    "name": "Alvarez Anthony",
    "gender": "male",
    "totalSales": 4414.750337,
    "firstOrderDate": "2018-08-18",
    "avgOrderSize": 240.890011,
    "AvgDaysBetweenOrders": 140
  },
  {
    "id": "5eb82288da8b18e7a50161e5",
    "name": "Clayton Walter",
    "gender": "male",
    "totalSales": 2383.803996,
    "firstOrderDate": "2018-10-06",
    "avgOrderSize": 30.142781,
    "AvgDaysBetweenOrders": 284
  },
  {
    "id": "5eb822883debabd77dc23a94",
    "name": "Fletcher Horton",
    "gender": "male",
    "totalSales": 2883.554584,
    "firstOrderDate": "2017-10-29",
    "avgOrderSize": 71.451658,
    "AvgDaysBetweenOrders": 133
  },
  {
    "id": "5eb822881019882dc0dc711e",
    "name": "Frazier Mcknight",
    "gender": "male",
    "totalSales": 6939.49412,
    "firstOrderDate": "2018-03-11",
    "avgOrderSize": 286.099751,
    "AvgDaysBetweenOrders": 96
  },
  {
    "id": "5eb8228835e61f1cf2c7f86d",
    "name": "Barnett Levine",
    "gender": "male",
    "totalSales": 1301.460048,
    "firstOrderDate": "2015-08-11",
    "avgOrderSize": 154.883657,
    "AvgDaysBetweenOrders": 157
  },
  {
    "id": "5eb822884cced232adb2492a",
    "name": "Jaclyn Ingram",
    "gender": "female",
    "totalSales": 1612.506464,
    "firstOrderDate": "2018-10-05",
    "avgOrderSize": 221.200354,
    "AvgDaysBetweenOrders": 215
  },
  {
    "id": "5eb822881994642f4272449f",
    "name": "Richard Mccall",
    "gender": "male",
    "totalSales": 2031.151039,
    "firstOrderDate": "2016-08-27",
    "avgOrderSize": 3.806446,
    "AvgDaysBetweenOrders": 272
  },
  {
    "id": "5eb82288324f41bdb248139d",
    "name": "Mcintosh Edwards",
    "gender": "male",
    "totalSales": 1307.204413,
    "firstOrderDate": "2018-08-28",
    "avgOrderSize": 256.137486,
    "AvgDaysBetweenOrders": 214
  },
  {
    "id": "5eb822886a30968c65713388",
    "name": "Chaney Bowman",
    "gender": "male",
    "totalSales": 4493.867551,
    "firstOrderDate": "2014-09-02",
    "avgOrderSize": 17.862564,
    "AvgDaysBetweenOrders": 235
  },
  {
    "id": "5eb82288a898791a7951a0f2",
    "name": "Eula Moran",
    "gender": "female",
    "totalSales": 3333.590847,
    "firstOrderDate": "2014-10-27",
    "avgOrderSize": 279.716652,
    "AvgDaysBetweenOrders": 258
  },
  {
    "id": "5eb82288edbd24cb97e75216",
    "name": "Nora Lawrence",
    "gender": "female",
    "totalSales": 1606.506807,
    "firstOrderDate": "2018-05-16",
    "avgOrderSize": 212.044061,
    "AvgDaysBetweenOrders": 114
  },
  {
    "id": "5eb8228855c2750c90eba700",
    "name": "Adams Cooley",
    "gender": "male",
    "totalSales": 8982.805609,
    "firstOrderDate": "2018-10-04",
    "avgOrderSize": 92.526366,
    "AvgDaysBetweenOrders": 10
  },
  {
    "id": "5eb82288100d30af87258bee",
    "name": "Hodges Wells",
    "gender": "male",
    "totalSales": 8501.391029,
    "firstOrderDate": "2018-04-09",
    "avgOrderSize": 110.511762,
    "AvgDaysBetweenOrders": 236
  },
  {
    "id": "5eb82288874fe850854f100f",
    "name": "Ruth Mills",
    "gender": "female",
    "totalSales": 8286.631312,
    "firstOrderDate": "2017-10-01",
    "avgOrderSize": 30.483525,
    "AvgDaysBetweenOrders": 17
  },
  {
    "id": "5eb822885ac72cc0396a4e42",
    "name": "Stevenson Stephenson",
    "gender": "male",
    "totalSales": 3508.122353,
    "firstOrderDate": "2020-03-03",
    "avgOrderSize": 186.122129,
    "AvgDaysBetweenOrders": 233
  },
  {
    "id": "5eb8228835d06a79bbb946eb",
    "name": "Hodge Weiss",
    "gender": "male",
    "totalSales": 4993.960053,
    "firstOrderDate": "2014-12-14",
    "avgOrderSize": 19.314681,
    "AvgDaysBetweenOrders": 125
  },
  {
    "id": "5eb8228815ac7101d2c16a1d",
    "name": "Alexandria Rocha",
    "gender": "female",
    "totalSales": 1010.807477,
    "firstOrderDate": "2017-06-18",
    "avgOrderSize": 90.856671,
    "AvgDaysBetweenOrders": 27
  },
  {
    "id": "5eb82288616fb17d09211e33",
    "name": "Joyce Gilliam",
    "gender": "male",
    "totalSales": 4830.846287,
    "firstOrderDate": "2020-02-10",
    "avgOrderSize": 152.815302,
    "AvgDaysBetweenOrders": 134
  },
  {
    "id": "5eb8228819d8969066f3e33e",
    "name": "Eileen Raymond",
    "gender": "female",
    "totalSales": 3124.726394,
    "firstOrderDate": "2020-01-07",
    "avgOrderSize": 174.54855,
    "AvgDaysBetweenOrders": 139
  },
  {
    "id": "5eb82288cbe68575f5ca597f",
    "name": "Estes Spears",
    "gender": "male",
    "totalSales": 3141.09279,
    "firstOrderDate": "2015-10-17",
    "avgOrderSize": 179.624849,
    "AvgDaysBetweenOrders": 83
  },
  {
    "id": "5eb822886eeee3915059322f",
    "name": "Duncan Bates",
    "gender": "male",
    "totalSales": 5521.398385,
    "firstOrderDate": "2020-03-30",
    "avgOrderSize": 211.947702,
    "AvgDaysBetweenOrders": 90
  },
  {
    "id": "5eb82288de6218cef609f5e7",
    "name": "Mullen Gutierrez",
    "gender": "male",
    "totalSales": 8954.019417,
    "firstOrderDate": "2018-11-24",
    "avgOrderSize": 270.823942,
    "AvgDaysBetweenOrders": 291
  },
  {
    "id": "5eb822889a3db9b81bfc10b2",
    "name": "Therese Mccarthy",
    "gender": "female",
    "totalSales": 256.164972,
    "firstOrderDate": "2016-10-12",
    "avgOrderSize": 54.109407,
    "AvgDaysBetweenOrders": 208
  },
  {
    "id": "5eb822888f278f467d943ba3",
    "name": "Dixie Norton",
    "gender": "female",
    "totalSales": 9103.533056,
    "firstOrderDate": "2019-06-04",
    "avgOrderSize": 34.100665,
    "AvgDaysBetweenOrders": 134
  },
  {
    "id": "5eb822889c97ef9975fb4d5e",
    "name": "Wells Howell",
    "gender": "male",
    "totalSales": 5759.373193,
    "firstOrderDate": "2018-01-20",
    "avgOrderSize": 31.893008,
    "AvgDaysBetweenOrders": 286
  },
  {
    "id": "5eb822886049033ce68b50fc",
    "name": "Lindsey Ortega",
    "gender": "male",
    "totalSales": 3855.455919,
    "firstOrderDate": "2019-02-28",
    "avgOrderSize": 124.597305,
    "AvgDaysBetweenOrders": 108
  },
  {
    "id": "5eb822880c9070356a769eb5",
    "name": "Murray Saunders",
    "gender": "male",
    "totalSales": 9386.454235,
    "firstOrderDate": "2015-10-06",
    "avgOrderSize": 177.004926,
    "AvgDaysBetweenOrders": 255
  },
  {
    "id": "5eb8228862b3558997a6a381",
    "name": "Mae Everett",
    "gender": "female",
    "totalSales": 4204.967548,
    "firstOrderDate": "2019-01-26",
    "avgOrderSize": 153.269191,
    "AvgDaysBetweenOrders": 250
  },
  {
    "id": "5eb8228892e083a6fdba3af8",
    "name": "Yolanda Dodson",
    "gender": "female",
    "totalSales": 991.575191,
    "firstOrderDate": "2014-08-22",
    "avgOrderSize": 90.017601,
    "AvgDaysBetweenOrders": 264
  },
  {
    "id": "5eb822886501647335df8343",
    "name": "Juana Roach",
    "gender": "female",
    "totalSales": 6233.069886,
    "firstOrderDate": "2019-10-13",
    "avgOrderSize": 55.87854,
    "AvgDaysBetweenOrders": 144
  },
  {
    "id": "5eb82288a6a06393e59ef81f",
    "name": "Estelle Morrison",
    "gender": "female",
    "totalSales": 4500.938914,
    "firstOrderDate": "2019-06-23",
    "avgOrderSize": 162.78207,
    "AvgDaysBetweenOrders": 37
  },
  {
    "id": "5eb822880e5cdc46f1624df8",
    "name": "Mendez Dorsey",
    "gender": "male",
    "totalSales": 757.692904,
    "firstOrderDate": "2020-04-13",
    "avgOrderSize": 25.88549,
    "AvgDaysBetweenOrders": 127
  },
  {
    "id": "5eb822885ca9e961a49ea579",
    "name": "Minerva Vance",
    "gender": "female",
    "totalSales": 7687.466677,
    "firstOrderDate": "2017-03-10",
    "avgOrderSize": 282.482419,
    "AvgDaysBetweenOrders": 175
  },
  {
    "id": "5eb82288a4f0ad2941294d85",
    "name": "Garza Mccoy",
    "gender": "male",
    "totalSales": 7315.007954,
    "firstOrderDate": "2014-11-03",
    "avgOrderSize": 298.818751,
    "AvgDaysBetweenOrders": 191
  },
  {
    "id": "5eb822885f047f18e3ea7da7",
    "name": "Penelope Hernandez",
    "gender": "female",
    "totalSales": 4939.527274,
    "firstOrderDate": "2017-06-11",
    "avgOrderSize": 60.449452,
    "AvgDaysBetweenOrders": 207
  },
  {
    "id": "5eb82288db45dd644bd8924f",
    "name": "Alana Maddox",
    "gender": "female",
    "totalSales": 3302.907582,
    "firstOrderDate": "2017-03-23",
    "avgOrderSize": 107.006159,
    "AvgDaysBetweenOrders": 288
  },
  {
    "id": "5eb82288b0eb7929a1fe1993",
    "name": "Leta Bright",
    "gender": "female",
    "totalSales": 1384.267886,
    "firstOrderDate": "2018-07-22",
    "avgOrderSize": 283.717815,
    "AvgDaysBetweenOrders": 251
  },
  {
    "id": "5eb82288c0ebc60e2b82d0f3",
    "name": "Nita Kirk",
    "gender": "female",
    "totalSales": 7321.993575,
    "firstOrderDate": "2017-03-14",
    "avgOrderSize": 184.495549,
    "AvgDaysBetweenOrders": 170
  },
  {
    "id": "5eb8228881c0b4f53918c707",
    "name": "Spence Chen",
    "gender": "male",
    "totalSales": 7953.993125,
    "firstOrderDate": "2016-09-17",
    "avgOrderSize": 293.698799,
    "AvgDaysBetweenOrders": 300
  },
  {
    "id": "5eb8228848a6f77f2e355981",
    "name": "Charlotte Keller",
    "gender": "female",
    "totalSales": 3941.667472,
    "firstOrderDate": "2016-01-08",
    "avgOrderSize": 122.582217,
    "AvgDaysBetweenOrders": 66
  },
  {
    "id": "5eb822889c2329a9bfa9839d",
    "name": "Brennan Valenzuela",
    "gender": "male",
    "totalSales": 517.148808,
    "firstOrderDate": "2018-12-25",
    "avgOrderSize": 41.918575,
    "AvgDaysBetweenOrders": 47
  },
  {
    "id": "5eb8228869362f950e29ba35",
    "name": "Bettye Phillips",
    "gender": "female",
    "totalSales": 3237.237731,
    "firstOrderDate": "2017-03-04",
    "avgOrderSize": 84.707389,
    "AvgDaysBetweenOrders": 162
  },
  {
    "id": "5eb822883905ca90aba4e9d4",
    "name": "Branch Caldwell",
    "gender": "male",
    "totalSales": 5187.325368,
    "firstOrderDate": "2016-04-04",
    "avgOrderSize": 206.240762,
    "AvgDaysBetweenOrders": 147
  },
  {
    "id": "5eb8228899472da4f5cc4bc2",
    "name": "Lillie Sweeney",
    "gender": "female",
    "totalSales": 9393.556926,
    "firstOrderDate": "2019-04-21",
    "avgOrderSize": 20.680324,
    "AvgDaysBetweenOrders": 113
  },
  {
    "id": "5eb822883355f799357119ca",
    "name": "Horne Hinton",
    "gender": "male",
    "totalSales": 5119.41958,
    "firstOrderDate": "2015-03-27",
    "avgOrderSize": 240.624091,
    "AvgDaysBetweenOrders": 215
  },
  {
    "id": "5eb822885d9125e2f331db67",
    "name": "Mathews Bowers",
    "gender": "male",
    "totalSales": 5301.678769,
    "firstOrderDate": "2014-11-14",
    "avgOrderSize": 286.280442,
    "AvgDaysBetweenOrders": 107
  },
  {
    "id": "5eb82288a2fbba4c2399ae69",
    "name": "Kris Pope",
    "gender": "female",
    "totalSales": 5479.616679,
    "firstOrderDate": "2014-01-25",
    "avgOrderSize": 259.941764,
    "AvgDaysBetweenOrders": 166
  },
  {
    "id": "5eb822883d97f71b0d94a7ce",
    "name": "Mccray Frederick",
    "gender": "male",
    "totalSales": 2731.318269,
    "firstOrderDate": "2019-09-12",
    "avgOrderSize": 181.905422,
    "AvgDaysBetweenOrders": 233
  },
  {
    "id": "5eb82288990c14d8570992c0",
    "name": "Lowery Justice",
    "gender": "male",
    "totalSales": 7539.550826,
    "firstOrderDate": "2017-01-07",
    "avgOrderSize": 183.440481,
    "AvgDaysBetweenOrders": 66
  },
  {
    "id": "5eb82288a00519ed7104fee5",
    "name": "Acosta Nicholson",
    "gender": "male",
    "totalSales": 4452.20623,
    "firstOrderDate": "2014-09-11",
    "avgOrderSize": 93.27581,
    "AvgDaysBetweenOrders": 27
  },
  {
    "id": "5eb8228874ea55e7f651b0db",
    "name": "Angelique Charles",
    "gender": "female",
    "totalSales": 3906.552592,
    "firstOrderDate": "2015-06-03",
    "avgOrderSize": 267.486169,
    "AvgDaysBetweenOrders": 261
  },
  {
    "id": "5eb82288daab6a63de519698",
    "name": "Good Mcgee",
    "gender": "male",
    "totalSales": 2847.894982,
    "firstOrderDate": "2019-11-26",
    "avgOrderSize": 133.609501,
    "AvgDaysBetweenOrders": 147
  },
  {
    "id": "5eb822888c7d0fa12a7456a0",
    "name": "Tran Preston",
    "gender": "male",
    "totalSales": 7941.58923,
    "firstOrderDate": "2016-11-10",
    "avgOrderSize": 275.195932,
    "AvgDaysBetweenOrders": 106
  },
  {
    "id": "5eb82288fa47fd9863bf3eb7",
    "name": "Jeanette Guy",
    "gender": "female",
    "totalSales": 2898.647685,
    "firstOrderDate": "2015-03-15",
    "avgOrderSize": 119.555035,
    "AvgDaysBetweenOrders": 30
  },
  {
    "id": "5eb82288af7aabed9f1dfb92",
    "name": "Wendy Baird",
    "gender": "female",
    "totalSales": 3154.260611,
    "firstOrderDate": "2019-06-10",
    "avgOrderSize": 298.584468,
    "AvgDaysBetweenOrders": 283
  },
  {
    "id": "5eb822881abb1bf8145566a3",
    "name": "Kirsten Spencer",
    "gender": "female",
    "totalSales": 6304.300533,
    "firstOrderDate": "2014-06-07",
    "avgOrderSize": 293.571585,
    "AvgDaysBetweenOrders": 35
  },
  {
    "id": "5eb82288ddd7229642e588e6",
    "name": "Francine Lyons",
    "gender": "female",
    "totalSales": 1583.427811,
    "firstOrderDate": "2018-04-10",
    "avgOrderSize": 282.643377,
    "AvgDaysBetweenOrders": 77
  },
  {
    "id": "5eb82288d3d41fd97fb3b4dd",
    "name": "Denise Dawson",
    "gender": "female",
    "totalSales": 2520.360274,
    "firstOrderDate": "2015-02-03",
    "avgOrderSize": 159.562021,
    "AvgDaysBetweenOrders": 196
  },
  {
    "id": "5eb82288dad13da15ec8d7e2",
    "name": "Logan Conner",
    "gender": "male",
    "totalSales": 2686.929907,
    "firstOrderDate": "2014-03-05",
    "avgOrderSize": 165.788344,
    "AvgDaysBetweenOrders": 121
  },
  {
    "id": "5eb82288462c10e91b918e47",
    "name": "Stanley Barker",
    "gender": "male",
    "totalSales": 1694.583177,
    "firstOrderDate": "2017-09-01",
    "avgOrderSize": 223.211902,
    "AvgDaysBetweenOrders": 137
  },
  {
    "id": "5eb82288e88b5a258af4ebba",
    "name": "Nelda Rivera",
    "gender": "female",
    "totalSales": 4278.970296,
    "firstOrderDate": "2014-01-24",
    "avgOrderSize": 179.225586,
    "AvgDaysBetweenOrders": 121
  },
  {
    "id": "5eb82288fa179069a0e5fe37",
    "name": "Benson Thomas",
    "gender": "male",
    "totalSales": 2681.933465,
    "firstOrderDate": "2014-07-19",
    "avgOrderSize": 240.917905,
    "AvgDaysBetweenOrders": 279
  },
  {
    "id": "5eb82288adaea3648bce7a70",
    "name": "Eloise Juarez",
    "gender": "female",
    "totalSales": 5629.628478,
    "firstOrderDate": "2018-04-17",
    "avgOrderSize": 130.192832,
    "AvgDaysBetweenOrders": 75
  },
  {
    "id": "5eb822887bcbcd298f4bb666",
    "name": "Johns Mayo",
    "gender": "male",
    "totalSales": 843.419249,
    "firstOrderDate": "2019-12-16",
    "avgOrderSize": 19.298164,
    "AvgDaysBetweenOrders": 284
  },
  {
    "id": "5eb82288d7d667d680a440f1",
    "name": "Patty Garrett",
    "gender": "female",
    "totalSales": 3704.091714,
    "firstOrderDate": "2019-01-27",
    "avgOrderSize": 274.327802,
    "AvgDaysBetweenOrders": 219
  },
  {
    "id": "5eb822884876187e93efb2d9",
    "name": "Graham Ortiz",
    "gender": "male",
    "totalSales": 2863.524902,
    "firstOrderDate": "2014-05-04",
    "avgOrderSize": 221.110925,
    "AvgDaysBetweenOrders": 281
  },
  {
    "id": "5eb822887340b0d1f408cea0",
    "name": "Elisa Kelly",
    "gender": "female",
    "totalSales": 958.34002,
    "firstOrderDate": "2018-02-25",
    "avgOrderSize": 163.860553,
    "AvgDaysBetweenOrders": 24
  },
  {
    "id": "5eb82288d1fd3eaf9ff92700",
    "name": "Boyer Velazquez",
    "gender": "male",
    "totalSales": 7677.943788,
    "firstOrderDate": "2016-02-03",
    "avgOrderSize": 151.962352,
    "AvgDaysBetweenOrders": 89
  },
  {
    "id": "5eb82288959d655706c2c225",
    "name": "Jewell Blair",
    "gender": "female",
    "totalSales": 9458.627652,
    "firstOrderDate": "2018-08-20",
    "avgOrderSize": 269.371748,
    "AvgDaysBetweenOrders": 219
  },
  {
    "id": "5eb8228837d7341f05d90842",
    "name": "Blanchard Mcconnell",
    "gender": "male",
    "totalSales": 5661.530876,
    "firstOrderDate": "2018-04-25",
    "avgOrderSize": 67.351548,
    "AvgDaysBetweenOrders": 227
  },
  {
    "id": "5eb822889ca080832de12d61",
    "name": "Melton Lawson",
    "gender": "male",
    "totalSales": 7754.184869,
    "firstOrderDate": "2015-08-10",
    "avgOrderSize": 289.279604,
    "AvgDaysBetweenOrders": 11
  },
  {
    "id": "5eb822884d6e08eafa47d402",
    "name": "Rodgers Watkins",
    "gender": "male",
    "totalSales": 3115.109569,
    "firstOrderDate": "2017-08-12",
    "avgOrderSize": 7.203511,
    "AvgDaysBetweenOrders": 171
  },
  {
    "id": "5eb82288b2377a5c053a7221",
    "name": "Barr Eaton",
    "gender": "male",
    "totalSales": 222.040201,
    "firstOrderDate": "2018-09-02",
    "avgOrderSize": 224.340449,
    "AvgDaysBetweenOrders": 191
  },
  {
    "id": "5eb82288db83a495ea73f1bd",
    "name": "Simon Osborne",
    "gender": "male",
    "totalSales": 2326.022165,
    "firstOrderDate": "2017-04-11",
    "avgOrderSize": 25.778335,
    "AvgDaysBetweenOrders": 103
  },
  {
    "id": "5eb82288aa99fdf828732ba7",
    "name": "Serrano Townsend",
    "gender": "male",
    "totalSales": 8239.444299,
    "firstOrderDate": "2017-02-18",
    "avgOrderSize": 231.298481,
    "AvgDaysBetweenOrders": 78
  },
  {
    "id": "5eb82288d719a4b22f84ffc7",
    "name": "Bolton Bell",
    "gender": "male",
    "totalSales": 9222.335366,
    "firstOrderDate": "2014-12-10",
    "avgOrderSize": 255.581119,
    "AvgDaysBetweenOrders": 67
  },
  {
    "id": "5eb82288f33d78013fa3f81c",
    "name": "Georgette Leach",
    "gender": "female",
    "totalSales": 4097.271023,
    "firstOrderDate": "2016-05-01",
    "avgOrderSize": 58.756533,
    "AvgDaysBetweenOrders": 212
  },
  {
    "id": "5eb8228890fc9cc5a9b5ff39",
    "name": "Freeman Wolf",
    "gender": "male",
    "totalSales": 2895.391379,
    "firstOrderDate": "2015-01-01",
    "avgOrderSize": 224.363825,
    "AvgDaysBetweenOrders": 60
  },
  {
    "id": "5eb822887480269d90a88b35",
    "name": "Rosales Booker",
    "gender": "male",
    "totalSales": 3527.533724,
    "firstOrderDate": "2014-10-06",
    "avgOrderSize": 290.270683,
    "AvgDaysBetweenOrders": 55
  },
  {
    "id": "5eb8228825796d41c59d092a",
    "name": "Louise Noble",
    "gender": "female",
    "totalSales": 3867.343666,
    "firstOrderDate": "2015-10-09",
    "avgOrderSize": 208.672443,
    "AvgDaysBetweenOrders": 170
  },
  {
    "id": "5eb82288fd243caa4c5bbdcc",
    "name": "Sexton Workman",
    "gender": "male",
    "totalSales": 5514.500418,
    "firstOrderDate": "2019-05-25",
    "avgOrderSize": 77.852511,
    "AvgDaysBetweenOrders": 116
  },
  {
    "id": "5eb8228835a54591a92f5f36",
    "name": "Heather Higgins",
    "gender": "female",
    "totalSales": 6424.478241,
    "firstOrderDate": "2018-03-19",
    "avgOrderSize": 21.129109,
    "AvgDaysBetweenOrders": 91
  },
  {
    "id": "5eb822883446a79c79159c38",
    "name": "Bernadine Tillman",
    "gender": "female",
    "totalSales": 3791.221226,
    "firstOrderDate": "2014-08-13",
    "avgOrderSize": 141.266247,
    "AvgDaysBetweenOrders": 118
  },
  {
    "id": "5eb82288165b9a531aa74f58",
    "name": "Bartlett Espinoza",
    "gender": "male",
    "totalSales": 7324.737596,
    "firstOrderDate": "2019-05-19",
    "avgOrderSize": 135.422342,
    "AvgDaysBetweenOrders": 225
  },
  {
    "id": "5eb822888fe7024bcb8d57d6",
    "name": "Perry Cain",
    "gender": "male",
    "totalSales": 9590.38793,
    "firstOrderDate": "2015-01-01",
    "avgOrderSize": 206.620365,
    "AvgDaysBetweenOrders": 80
  },
  {
    "id": "5eb82288ec600659580c3f0c",
    "name": "Ortiz Kennedy",
    "gender": "male",
    "totalSales": 4282.10493,
    "firstOrderDate": "2016-06-16",
    "avgOrderSize": 66.991954,
    "AvgDaysBetweenOrders": 138
  },
  {
    "id": "5eb822887f3304bea34d2fed",
    "name": "Nash Sparks",
    "gender": "male",
    "totalSales": 1522.400663,
    "firstOrderDate": "2015-08-11",
    "avgOrderSize": 218.490227,
    "AvgDaysBetweenOrders": 62
  },
  {
    "id": "5eb82288a790cdf5cda9dae0",
    "name": "Coffey Adams",
    "gender": "male",
    "totalSales": 5801.628039,
    "firstOrderDate": "2014-12-09",
    "avgOrderSize": 262.964481,
    "AvgDaysBetweenOrders": 210
  },
  {
    "id": "5eb82288ccc39699a92113d9",
    "name": "Liz Gallegos",
    "gender": "female",
    "totalSales": 214.888676,
    "firstOrderDate": "2017-06-26",
    "avgOrderSize": 66.056756,
    "AvgDaysBetweenOrders": 195
  },
  {
    "id": "5eb822889bd1861c56bef938",
    "name": "Sherman Chaney",
    "gender": "male",
    "totalSales": 8913.752923,
    "firstOrderDate": "2015-04-12",
    "avgOrderSize": 278.324935,
    "AvgDaysBetweenOrders": 159
  },
  {
    "id": "5eb82288f1c1ed538498f269",
    "name": "Concetta Pollard",
    "gender": "female",
    "totalSales": 6514.456839,
    "firstOrderDate": "2016-06-28",
    "avgOrderSize": 162.83169,
    "AvgDaysBetweenOrders": 138
  },
  {
    "id": "5eb8228821e2738b74b4565d",
    "name": "Richmond Clements",
    "gender": "male",
    "totalSales": 4479.967581,
    "firstOrderDate": "2016-08-21",
    "avgOrderSize": 291.88267,
    "AvgDaysBetweenOrders": 125
  },
  {
    "id": "5eb8228828866f1e9265cb04",
    "name": "Rosemarie Ellis",
    "gender": "female",
    "totalSales": 5313.53006,
    "firstOrderDate": "2019-08-03",
    "avgOrderSize": 251.304306,
    "AvgDaysBetweenOrders": 59
  },
  {
    "id": "5eb82288a7818fa5a4a4befb",
    "name": "Sonja Sharp",
    "gender": "female",
    "totalSales": 4031.992361,
    "firstOrderDate": "2015-10-30",
    "avgOrderSize": 276.072708,
    "AvgDaysBetweenOrders": 267
  },
  {
    "id": "5eb822881ddad78ab8fbe12a",
    "name": "Cummings Ball",
    "gender": "male",
    "totalSales": 3347.316089,
    "firstOrderDate": "2016-04-13",
    "avgOrderSize": 267.583696,
    "AvgDaysBetweenOrders": 300
  },
  {
    "id": "5eb8228840e6e9a8abdab328",
    "name": "Marina Snider",
    "gender": "female",
    "totalSales": 1729.565592,
    "firstOrderDate": "2018-11-17",
    "avgOrderSize": 220.135693,
    "AvgDaysBetweenOrders": 15
  },
  {
    "id": "5eb8228815f83bea5200fe3e",
    "name": "Lorie Walters",
    "gender": "female",
    "totalSales": 578.323812,
    "firstOrderDate": "2018-03-25",
    "avgOrderSize": 158.67347,
    "AvgDaysBetweenOrders": 74
  },
  {
    "id": "5eb822884414ed03a6ccd30b",
    "name": "Lamb Rodgers",
    "gender": "male",
    "totalSales": 1867.496356,
    "firstOrderDate": "2020-03-25",
    "avgOrderSize": 133.662354,
    "AvgDaysBetweenOrders": 118
  },
  {
    "id": "5eb8228838579a8bb1ca6667",
    "name": "Pam Whitfield",
    "gender": "female",
    "totalSales": 7944.379015,
    "firstOrderDate": "2014-03-28",
    "avgOrderSize": 34.816056,
    "AvgDaysBetweenOrders": 288
  },
  {
    "id": "5eb822885d6178f9c43cad7f",
    "name": "Mcguire Vinson",
    "gender": "male",
    "totalSales": 2055.622155,
    "firstOrderDate": "2017-11-15",
    "avgOrderSize": 176.175682,
    "AvgDaysBetweenOrders": 174
  },
  {
    "id": "5eb822882cec7d29d07d1174",
    "name": "Lidia Coffey",
    "gender": "female",
    "totalSales": 3813.589185,
    "firstOrderDate": "2017-08-14",
    "avgOrderSize": 80.356922,
    "AvgDaysBetweenOrders": 23
  },
  {
    "id": "5eb822880269e0a2a6125d28",
    "name": "Matthews Pena",
    "gender": "male",
    "totalSales": 8406.745527,
    "firstOrderDate": "2019-09-25",
    "avgOrderSize": 49.625614,
    "AvgDaysBetweenOrders": 29
  },
  {
    "id": "5eb822889f12018242101e4d",
    "name": "Janelle Cortez",
    "gender": "female",
    "totalSales": 3446.243569,
    "firstOrderDate": "2018-03-15",
    "avgOrderSize": 174.296594,
    "AvgDaysBetweenOrders": 208
  },
  {
    "id": "5eb822881e53a2c4b979e212",
    "name": "Whitehead Dale",
    "gender": "male",
    "totalSales": 3656.371538,
    "firstOrderDate": "2016-03-10",
    "avgOrderSize": 217.361105,
    "AvgDaysBetweenOrders": 73
  },
  {
    "id": "5eb82288ee81bd677654d624",
    "name": "Cain Mcintyre",
    "gender": "male",
    "totalSales": 6194.070317,
    "firstOrderDate": "2016-11-04",
    "avgOrderSize": 145.977064,
    "AvgDaysBetweenOrders": 31
  },
  {
    "id": "5eb82288d9aa9741bcca7139",
    "name": "Garner Suarez",
    "gender": "male",
    "totalSales": 1222.158125,
    "firstOrderDate": "2015-06-08",
    "avgOrderSize": 113.976856,
    "AvgDaysBetweenOrders": 100
  },
  {
    "id": "5eb822887b47b189f4f6e33c",
    "name": "Hansen Frank",
    "gender": "male",
    "totalSales": 4626.858305,
    "firstOrderDate": "2015-02-23",
    "avgOrderSize": 163.193136,
    "AvgDaysBetweenOrders": 190
  },
  {
    "id": "5eb822881d000dd10d73a7d9",
    "name": "Jasmine Woodard",
    "gender": "female",
    "totalSales": 4302.445331,
    "firstOrderDate": "2018-05-25",
    "avgOrderSize": 5.841142,
    "AvgDaysBetweenOrders": 12
  },
  {
    "id": "5eb8228887ae457b5c2c5dcd",
    "name": "Lupe Reed",
    "gender": "female",
    "totalSales": 6819.303369,
    "firstOrderDate": "2019-11-06",
    "avgOrderSize": 35.007514,
    "AvgDaysBetweenOrders": 225
  },
  {
    "id": "5eb82288e1b670f4aee0c497",
    "name": "Rosario French",
    "gender": "male",
    "totalSales": 1623.103211,
    "firstOrderDate": "2017-11-16",
    "avgOrderSize": 141.378945,
    "AvgDaysBetweenOrders": 91
  },
  {
    "id": "5eb822882a0dd1a0dd02a706",
    "name": "Roach Boyer",
    "gender": "male",
    "totalSales": 6813.237093,
    "firstOrderDate": "2018-02-18",
    "avgOrderSize": 153.234167,
    "AvgDaysBetweenOrders": 60
  },
  {
    "id": "5eb82288a916fe9016eaec08",
    "name": "Ingram Velasquez",
    "gender": "male",
    "totalSales": 5743.014585,
    "firstOrderDate": "2018-09-02",
    "avgOrderSize": 295.574309,
    "AvgDaysBetweenOrders": 118
  },
  {
    "id": "5eb8228848b29d5d5861d245",
    "name": "Miles Meyer",
    "gender": "male",
    "totalSales": 7420.018568,
    "firstOrderDate": "2016-03-01",
    "avgOrderSize": 247.422417,
    "AvgDaysBetweenOrders": 80
  },
  {
    "id": "5eb82288477414051063c14c",
    "name": "Sweeney Booth",
    "gender": "male",
    "totalSales": 7621.440199,
    "firstOrderDate": "2017-05-12",
    "avgOrderSize": 111.857661,
    "AvgDaysBetweenOrders": 47
  },
  {
    "id": "5eb82288f16c8d607969450c",
    "name": "Howell Clayton",
    "gender": "male",
    "totalSales": 8847.567567,
    "firstOrderDate": "2016-12-31",
    "avgOrderSize": 1.473048,
    "AvgDaysBetweenOrders": 35
  },
  {
    "id": "5eb822887b15f1bb99babb71",
    "name": "Delacruz Rivas",
    "gender": "male",
    "totalSales": 8570.534118,
    "firstOrderDate": "2018-04-03",
    "avgOrderSize": 164.992892,
    "AvgDaysBetweenOrders": 231
  },
  {
    "id": "5eb82288c2f865d61eed51f8",
    "name": "Tammi Montgomery",
    "gender": "female",
    "totalSales": 29.590527,
    "firstOrderDate": "2018-03-21",
    "avgOrderSize": 37.185101,
    "AvgDaysBetweenOrders": 101
  },
  {
    "id": "5eb82288bff39eb2f7e1b05f",
    "name": "Nancy Parrish",
    "gender": "female",
    "totalSales": 972.13883,
    "firstOrderDate": "2016-07-02",
    "avgOrderSize": 282.449372,
    "AvgDaysBetweenOrders": 55
  },
  {
    "id": "5eb82288a188c6a87d7e85ae",
    "name": "Aimee Farrell",
    "gender": "female",
    "totalSales": 5138.942053,
    "firstOrderDate": "2014-11-14",
    "avgOrderSize": 144.932825,
    "AvgDaysBetweenOrders": 285
  },
  {
    "id": "5eb822886fe6949357dce0a5",
    "name": "Turner Ramirez",
    "gender": "male",
    "totalSales": 185.318029,
    "firstOrderDate": "2016-06-09",
    "avgOrderSize": 63.838499,
    "AvgDaysBetweenOrders": 3
  },
  {
    "id": "5eb82288fcbbaf3349479ead",
    "name": "Faith Myers",
    "gender": "female",
    "totalSales": 5254.006896,
    "firstOrderDate": "2014-08-10",
    "avgOrderSize": 184.281415,
    "AvgDaysBetweenOrders": 51
  },
  {
    "id": "5eb8228846b10de9ff642a19",
    "name": "Dunn Aguirre",
    "gender": "male",
    "totalSales": 9643.058542,
    "firstOrderDate": "2015-06-22",
    "avgOrderSize": 164.653433,
    "AvgDaysBetweenOrders": 68
  },
  {
    "id": "5eb82288355bfe6eed56b285",
    "name": "Rich Mendez",
    "gender": "male",
    "totalSales": 6004.833745,
    "firstOrderDate": "2017-05-25",
    "avgOrderSize": 185.639134,
    "AvgDaysBetweenOrders": 136
  },
  {
    "id": "5eb822881fcda7ce2854da70",
    "name": "Henson Carey",
    "gender": "male",
    "totalSales": 8494.410103,
    "firstOrderDate": "2018-07-09",
    "avgOrderSize": 30.279719,
    "AvgDaysBetweenOrders": 211
  },
  {
    "id": "5eb8228883d43836693cf752",
    "name": "Gena Hale",
    "gender": "female",
    "totalSales": 1163.69194,
    "firstOrderDate": "2016-01-01",
    "avgOrderSize": 168.965219,
    "AvgDaysBetweenOrders": 78
  },
  {
    "id": "5eb822881a3217a6b9932cc7",
    "name": "Leslie Snow",
    "gender": "female",
    "totalSales": 9542.447388,
    "firstOrderDate": "2017-07-01",
    "avgOrderSize": 77.241799,
    "AvgDaysBetweenOrders": 69
  },
  {
    "id": "5eb822880fc60e1eab18e30e",
    "name": "Tracey Jensen",
    "gender": "female",
    "totalSales": 6671.182709,
    "firstOrderDate": "2018-11-07",
    "avgOrderSize": 10.594053,
    "AvgDaysBetweenOrders": 82
  },
  {
    "id": "5eb82288617fbe6d2e6c820c",
    "name": "Bowen Mccullough",
    "gender": "male",
    "totalSales": 331.824621,
    "firstOrderDate": "2016-03-11",
    "avgOrderSize": 22.575994,
    "AvgDaysBetweenOrders": 227
  },
  {
    "id": "5eb82288b51d9ffd7db90dc0",
    "name": "Mccormick Moore",
    "gender": "male",
    "totalSales": 9249.503745,
    "firstOrderDate": "2016-01-02",
    "avgOrderSize": 4.826508,
    "AvgDaysBetweenOrders": 88
  },
  {
    "id": "5eb82288723ca8fc3ebe4802",
    "name": "Haney Richmond",
    "gender": "male",
    "totalSales": 3288.376073,
    "firstOrderDate": "2019-11-14",
    "avgOrderSize": 192.114951,
    "AvgDaysBetweenOrders": 212
  },
  {
    "id": "5eb822889dee7e94933fe756",
    "name": "Thelma Hutchinson",
    "gender": "female",
    "totalSales": 3201.960843,
    "firstOrderDate": "2014-06-19",
    "avgOrderSize": 274.586423,
    "AvgDaysBetweenOrders": 138
  },
  {
    "id": "5eb8228804408ce725ee9010",
    "name": "Cobb Salas",
    "gender": "male",
    "totalSales": 575.986085,
    "firstOrderDate": "2015-08-12",
    "avgOrderSize": 170.764689,
    "AvgDaysBetweenOrders": 68
  },
  {
    "id": "5eb82288ac1c19d05499aacd",
    "name": "Bentley Patterson",
    "gender": "male",
    "totalSales": 1582.677933,
    "firstOrderDate": "2017-01-05",
    "avgOrderSize": 77.211568,
    "AvgDaysBetweenOrders": 258
  },
  {
    "id": "5eb8228863a8f6555dbc6e75",
    "name": "Jeannie Allen",
    "gender": "female",
    "totalSales": 1141.30571,
    "firstOrderDate": "2017-10-28",
    "avgOrderSize": 149.443265,
    "AvgDaysBetweenOrders": 289
  },
  {
    "id": "5eb82288748e4247b36c6cf8",
    "name": "Newton Donaldson",
    "gender": "male",
    "totalSales": 6071.48907,
    "firstOrderDate": "2019-09-15",
    "avgOrderSize": 213.349993,
    "AvgDaysBetweenOrders": 186
  },
  {
    "id": "5eb82288907d0e3fe2f2cf9b",
    "name": "Wong Yang",
    "gender": "male",
    "totalSales": 276.938369,
    "firstOrderDate": "2020-02-27",
    "avgOrderSize": 94.844287,
    "AvgDaysBetweenOrders": 16
  },
  {
    "id": "5eb82288fcc20806cb3cf915",
    "name": "Margaret Riley",
    "gender": "female",
    "totalSales": 8958.005989,
    "firstOrderDate": "2014-09-20",
    "avgOrderSize": 230.100245,
    "AvgDaysBetweenOrders": 43
  },
  {
    "id": "5eb82288f75a4838455fb90c",
    "name": "Noel Barr",
    "gender": "male",
    "totalSales": 825.518288,
    "firstOrderDate": "2018-03-27",
    "avgOrderSize": 130.948208,
    "AvgDaysBetweenOrders": 240
  },
  {
    "id": "5eb82288548ee19890a3d131",
    "name": "Guzman Lester",
    "gender": "male",
    "totalSales": 6904.965824,
    "firstOrderDate": "2016-08-09",
    "avgOrderSize": 43.540752,
    "AvgDaysBetweenOrders": 198
  },
  {
    "id": "5eb82288223a09f756eb39e1",
    "name": "Lou Travis",
    "gender": "female",
    "totalSales": 2003.43512,
    "firstOrderDate": "2018-06-20",
    "avgOrderSize": 172.793101,
    "AvgDaysBetweenOrders": 112
  },
  {
    "id": "5eb82288aebbd13a27d5c703",
    "name": "Marietta Lloyd",
    "gender": "female",
    "totalSales": 3731.271119,
    "firstOrderDate": "2014-07-26",
    "avgOrderSize": 14.995211,
    "AvgDaysBetweenOrders": 11
  },
  {
    "id": "5eb822886779ca40668fa3ea",
    "name": "Rachelle Tate",
    "gender": "female",
    "totalSales": 5212.848233,
    "firstOrderDate": "2018-12-31",
    "avgOrderSize": 3.026705,
    "AvgDaysBetweenOrders": 189
  },
  {
    "id": "5eb82288debd09bd4e6c62b6",
    "name": "Horn Drake",
    "gender": "male",
    "totalSales": 9507.193016,
    "firstOrderDate": "2017-08-17",
    "avgOrderSize": 37.544915,
    "AvgDaysBetweenOrders": 206
  },
  {
    "id": "5eb82288d41ec238381e34cf",
    "name": "Susanna Hall",
    "gender": "female",
    "totalSales": 9733.331744,
    "firstOrderDate": "2020-04-28",
    "avgOrderSize": 244.622101,
    "AvgDaysBetweenOrders": 109
  },
  {
    "id": "5eb82288e747d846429b279c",
    "name": "Harmon Schwartz",
    "gender": "male",
    "totalSales": 6849.013634,
    "firstOrderDate": "2015-12-15",
    "avgOrderSize": 92.734582,
    "AvgDaysBetweenOrders": 260
  },
  {
    "id": "5eb822884c0d4c8cb8c6ba72",
    "name": "Wilkerson Jones",
    "gender": "male",
    "totalSales": 8221.482316,
    "firstOrderDate": "2018-10-24",
    "avgOrderSize": 134.48785,
    "AvgDaysBetweenOrders": 154
  },
  {
    "id": "5eb82288b0091c5aaf2fb01b",
    "name": "Travis Barnett",
    "gender": "male",
    "totalSales": 4417.706311,
    "firstOrderDate": "2019-05-04",
    "avgOrderSize": 239.353965,
    "AvgDaysBetweenOrders": 198
  },
  {
    "id": "5eb82288f865e0153e79660c",
    "name": "Miranda Dickson",
    "gender": "female",
    "totalSales": 5002.917461,
    "firstOrderDate": "2014-06-24",
    "avgOrderSize": 198.498399,
    "AvgDaysBetweenOrders": 272
  },
  {
    "id": "5eb82288ebe6b9b444b27362",
    "name": "Corina Oliver",
    "gender": "female",
    "totalSales": 6588.715429,
    "firstOrderDate": "2014-01-05",
    "avgOrderSize": 294.772026,
    "AvgDaysBetweenOrders": 24
  },
  {
    "id": "5eb822889958da3d905dc4bc",
    "name": "Cook Nixon",
    "gender": "male",
    "totalSales": 4653.875579,
    "firstOrderDate": "2015-02-11",
    "avgOrderSize": 197.962131,
    "AvgDaysBetweenOrders": 267
  },
  {
    "id": "5eb822886d7df075a457eed8",
    "name": "Muriel Hopper",
    "gender": "female",
    "totalSales": 9163.13953,
    "firstOrderDate": "2014-08-04",
    "avgOrderSize": 47.012539,
    "AvgDaysBetweenOrders": 92
  },
  {
    "id": "5eb8228866ac6d295fa89e52",
    "name": "Tyler Vazquez",
    "gender": "male",
    "totalSales": 6152.814583,
    "firstOrderDate": "2020-02-23",
    "avgOrderSize": 203.063398,
    "AvgDaysBetweenOrders": 233
  },
  {
    "id": "5eb82288502c90e4041005a9",
    "name": "Snyder Santiago",
    "gender": "male",
    "totalSales": 9872.75054,
    "firstOrderDate": "2017-05-13",
    "avgOrderSize": 151.734693,
    "AvgDaysBetweenOrders": 2
  },
  {
    "id": "5eb82288ba9e9a043b4c1464",
    "name": "Mercado Dickerson",
    "gender": "male",
    "totalSales": 6904.894252,
    "firstOrderDate": "2018-05-26",
    "avgOrderSize": 33.728829,
    "AvgDaysBetweenOrders": 193
  },
  {
    "id": "5eb8228882f4d89b690846f3",
    "name": "Agnes Manning",
    "gender": "female",
    "totalSales": 7309.496857,
    "firstOrderDate": "2018-12-22",
    "avgOrderSize": 50.559235,
    "AvgDaysBetweenOrders": 7
  },
  {
    "id": "5eb8228869f36fc32a8b72a0",
    "name": "Jerri Blankenship",
    "gender": "female",
    "totalSales": 8359.54218,
    "firstOrderDate": "2015-03-24",
    "avgOrderSize": 33.878028,
    "AvgDaysBetweenOrders": 158
  },
  {
    "id": "5eb82288c78611d8eaed2a34",
    "name": "Selena Howard",
    "gender": "female",
    "totalSales": 3940.8598,
    "firstOrderDate": "2017-06-01",
    "avgOrderSize": 154.561459,
    "AvgDaysBetweenOrders": 16
  },
  {
    "id": "5eb822889dfac1a9c867786f",
    "name": "Beach Garza",
    "gender": "male",
    "totalSales": 1343.170894,
    "firstOrderDate": "2015-04-08",
    "avgOrderSize": 68.79263,
    "AvgDaysBetweenOrders": 154
  },
  {
    "id": "5eb82288fb9e17d6abdaed98",
    "name": "Vaughn Marks",
    "gender": "male",
    "totalSales": 1706.274748,
    "firstOrderDate": "2018-04-11",
    "avgOrderSize": 213.537599,
    "AvgDaysBetweenOrders": 78
  },
  {
    "id": "5eb82288cd9acb8ef406b7a1",
    "name": "Patrick Martin",
    "gender": "male",
    "totalSales": 5828.832521,
    "firstOrderDate": "2015-11-01",
    "avgOrderSize": 87.105887,
    "AvgDaysBetweenOrders": 60
  },
  {
    "id": "5eb82288b0c8f4f04e09f219",
    "name": "Maldonado Hammond",
    "gender": "male",
    "totalSales": 6700.868946,
    "firstOrderDate": "2019-05-23",
    "avgOrderSize": 243.567832,
    "AvgDaysBetweenOrders": 130
  },
  {
    "id": "5eb822888bec61b0019231f5",
    "name": "Buckley Rosario",
    "gender": "male",
    "totalSales": 1867.27152,
    "firstOrderDate": "2018-11-30",
    "avgOrderSize": 215.61085,
    "AvgDaysBetweenOrders": 16
  },
  {
    "id": "5eb8228842963e8643dc7e67",
    "name": "Darcy Mcguire",
    "gender": "female",
    "totalSales": 9188.598091,
    "firstOrderDate": "2017-11-30",
    "avgOrderSize": 85.789486,
    "AvgDaysBetweenOrders": 161
  },
  {
    "id": "5eb822885d9e0030c457930e",
    "name": "Mari Osborn",
    "gender": "female",
    "totalSales": 917.316152,
    "firstOrderDate": "2014-05-04",
    "avgOrderSize": 41.989303,
    "AvgDaysBetweenOrders": 230
  },
  {
    "id": "5eb82288f5ca6ae444bbad6c",
    "name": "Mable Barnes",
    "gender": "female",
    "totalSales": 7782.211718,
    "firstOrderDate": "2015-05-01",
    "avgOrderSize": 298.999202,
    "AvgDaysBetweenOrders": 250
  },
  {
    "id": "5eb822889ba2a1241d07b7cd",
    "name": "Bessie Parker",
    "gender": "female",
    "totalSales": 5371.210984,
    "firstOrderDate": "2017-01-25",
    "avgOrderSize": 101.336659,
    "AvgDaysBetweenOrders": 161
  },
  {
    "id": "5eb822886da92a7e9cc6be73",
    "name": "Casey Steele",
    "gender": "male",
    "totalSales": 2660.317824,
    "firstOrderDate": "2015-03-16",
    "avgOrderSize": 281.307603,
    "AvgDaysBetweenOrders": 15
  },
  {
    "id": "5eb82288b8bedcabb2168584",
    "name": "Underwood Forbes",
    "gender": "male",
    "totalSales": 5256.918596,
    "firstOrderDate": "2017-09-22",
    "avgOrderSize": 230.540784,
    "AvgDaysBetweenOrders": 151
  },
  {
    "id": "5eb822882d077c56837cc95d",
    "name": "Bobbie Estrada",
    "gender": "female",
    "totalSales": 9144.7845,
    "firstOrderDate": "2014-03-24",
    "avgOrderSize": 207.376219,
    "AvgDaysBetweenOrders": 47
  },
  {
    "id": "5eb82288455c3b96a5e2f609",
    "name": "Luisa Langley",
    "gender": "female",
    "totalSales": 1333.487114,
    "firstOrderDate": "2020-01-08",
    "avgOrderSize": 98.544408,
    "AvgDaysBetweenOrders": 98
  },
  {
    "id": "5eb822884203270a1b226221",
    "name": "Willis Young",
    "gender": "male",
    "totalSales": 6465.352642,
    "firstOrderDate": "2017-04-04",
    "avgOrderSize": 96.407716,
    "AvgDaysBetweenOrders": 166
  },
  {
    "id": "5eb82288ed9494375e4cc1ae",
    "name": "Thornton Collins",
    "gender": "male",
    "totalSales": 6860.727242,
    "firstOrderDate": "2019-06-29",
    "avgOrderSize": 226.739713,
    "AvgDaysBetweenOrders": 55
  },
  {
    "id": "5eb82288f5219124e844a339",
    "name": "Vasquez Palmer",
    "gender": "male",
    "totalSales": 2351.637987,
    "firstOrderDate": "2018-01-28",
    "avgOrderSize": 242.189837,
    "AvgDaysBetweenOrders": 265
  },
  {
    "id": "5eb82288435c0465ae84ccec",
    "name": "Morgan Pratt",
    "gender": "female",
    "totalSales": 6638.377203,
    "firstOrderDate": "2019-11-03",
    "avgOrderSize": 258.390815,
    "AvgDaysBetweenOrders": 63
  },
  {
    "id": "5eb82288264a7cb1e4c9c8d3",
    "name": "Sparks Ewing",
    "gender": "male",
    "totalSales": 8905.631545,
    "firstOrderDate": "2018-12-30",
    "avgOrderSize": 293.134471,
    "AvgDaysBetweenOrders": 59
  },
  {
    "id": "5eb822885c524053b77ea456",
    "name": "Fran Rollins",
    "gender": "female",
    "totalSales": 8690.733542,
    "firstOrderDate": "2018-10-14",
    "avgOrderSize": 77.717896,
    "AvgDaysBetweenOrders": 6
  },
  {
    "id": "5eb822884f3def61cce8d48d",
    "name": "Goodwin Huber",
    "gender": "male",
    "totalSales": 1060.762465,
    "firstOrderDate": "2019-12-08",
    "avgOrderSize": 285.2668,
    "AvgDaysBetweenOrders": 124
  },
  {
    "id": "5eb82288a83d35df9a168a40",
    "name": "Martha Irwin",
    "gender": "female",
    "totalSales": 9125.674812,
    "firstOrderDate": "2018-09-21",
    "avgOrderSize": 206.1588,
    "AvgDaysBetweenOrders": 24
  },
  {
    "id": "5eb82288a55feaa057965176",
    "name": "Morales Burris",
    "gender": "male",
    "totalSales": 910.999189,
    "firstOrderDate": "2018-11-09",
    "avgOrderSize": 124.834976,
    "AvgDaysBetweenOrders": 242
  },
  {
    "id": "5eb82288e405cc209dfe68af",
    "name": "Nicole Stone",
    "gender": "female",
    "totalSales": 7560.107392,
    "firstOrderDate": "2019-01-12",
    "avgOrderSize": 129.216486,
    "AvgDaysBetweenOrders": 179
  },
  {
    "id": "5eb8228862a4b82d9c7e8a71",
    "name": "Warren Curry",
    "gender": "male",
    "totalSales": 4671.580386,
    "firstOrderDate": "2019-02-19",
    "avgOrderSize": 223.043593,
    "AvgDaysBetweenOrders": 164
  },
  {
    "id": "5eb82288d0d35bd87b5808cb",
    "name": "Schroeder Pitts",
    "gender": "male",
    "totalSales": 8703.731788,
    "firstOrderDate": "2016-06-01",
    "avgOrderSize": 235.00488,
    "AvgDaysBetweenOrders": 136
  },
  {
    "id": "5eb822888371e3043ded7c30",
    "name": "Gray Cruz",
    "gender": "male",
    "totalSales": 3792.643003,
    "firstOrderDate": "2014-07-24",
    "avgOrderSize": 34.396871,
    "AvgDaysBetweenOrders": 49
  },
  {
    "id": "5eb822881db4eabd5cf9d191",
    "name": "Gibson York",
    "gender": "male",
    "totalSales": 8671.761366,
    "firstOrderDate": "2015-08-08",
    "avgOrderSize": 218.461972,
    "AvgDaysBetweenOrders": 187
  },
  {
    "id": "5eb8228807d3888884f69953",
    "name": "Cathy Petty",
    "gender": "female",
    "totalSales": 519.091953,
    "firstOrderDate": "2015-02-17",
    "avgOrderSize": 100.511503,
    "AvgDaysBetweenOrders": 165
  },
  {
    "id": "5eb822887df6e80c5aff2934",
    "name": "Josefa Noel",
    "gender": "female",
    "totalSales": 7438.133729,
    "firstOrderDate": "2018-08-23",
    "avgOrderSize": 226.841193,
    "AvgDaysBetweenOrders": 131
  },
  {
    "id": "5eb82288421cc0274fcda42b",
    "name": "Rosanna Salazar",
    "gender": "female",
    "totalSales": 443.106125,
    "firstOrderDate": "2019-03-09",
    "avgOrderSize": 20.290048,
    "AvgDaysBetweenOrders": 109
  },
  {
    "id": "5eb822882cc068213f72418c",
    "name": "Pena Morton",
    "gender": "male",
    "totalSales": 5884.911722,
    "firstOrderDate": "2016-11-22",
    "avgOrderSize": 35.310936,
    "AvgDaysBetweenOrders": 190
  },
  {
    "id": "5eb82288b5da429df529cfd3",
    "name": "Alissa Joyce",
    "gender": "female",
    "totalSales": 5083.612999,
    "firstOrderDate": "2014-12-30",
    "avgOrderSize": 278.806232,
    "AvgDaysBetweenOrders": 64
  },
  {
    "id": "5eb82288b8566498aecaea27",
    "name": "Nikki Buckner",
    "gender": "female",
    "totalSales": 5251.812843,
    "firstOrderDate": "2015-04-24",
    "avgOrderSize": 23.871651,
    "AvgDaysBetweenOrders": 148
  },
  {
    "id": "5eb82288f745170897b76d2a",
    "name": "Dona Dominguez",
    "gender": "female",
    "totalSales": 7905.160061,
    "firstOrderDate": "2016-05-09",
    "avgOrderSize": 181.898287,
    "AvgDaysBetweenOrders": 211
  },
  {
    "id": "5eb822880a803238c2e14a12",
    "name": "Solomon Perry",
    "gender": "male",
    "totalSales": 555.357811,
    "firstOrderDate": "2019-05-21",
    "avgOrderSize": 60.311444,
    "AvgDaysBetweenOrders": 14
  },
  {
    "id": "5eb822887d79b1359ce90572",
    "name": "Carlene Brown",
    "gender": "female",
    "totalSales": 8775.081974,
    "firstOrderDate": "2019-12-11",
    "avgOrderSize": 178.775466,
    "AvgDaysBetweenOrders": 239
  },
  {
    "id": "5eb8228896af690f3a488ad8",
    "name": "Tommie Morse",
    "gender": "female",
    "totalSales": 4527.006216,
    "firstOrderDate": "2019-10-08",
    "avgOrderSize": 2.983373,
    "AvgDaysBetweenOrders": 162
  },
  {
    "id": "5eb822887192751f23b23e86",
    "name": "Jaime Simon",
    "gender": "female",
    "totalSales": 6733.64632,
    "firstOrderDate": "2014-05-12",
    "avgOrderSize": 151.069978,
    "AvgDaysBetweenOrders": 220
  },
  {
    "id": "5eb82288ae29cc604bd817f5",
    "name": "Ayala Schmidt",
    "gender": "male",
    "totalSales": 6316.49867,
    "firstOrderDate": "2018-01-26",
    "avgOrderSize": 180.960096,
    "AvgDaysBetweenOrders": 299
  },
  {
    "id": "5eb82288da298f0a0799dc38",
    "name": "Kayla Cotton",
    "gender": "female",
    "totalSales": 6070.106171,
    "firstOrderDate": "2014-05-08",
    "avgOrderSize": 78.42476,
    "AvgDaysBetweenOrders": 2
  },
  {
    "id": "5eb8228868e4652598a60757",
    "name": "Martina Cannon",
    "gender": "female",
    "totalSales": 5716.277803,
    "firstOrderDate": "2014-07-31",
    "avgOrderSize": 88.656248,
    "AvgDaysBetweenOrders": 244
  },
  {
    "id": "5eb8228802f73bd8c3a8d483",
    "name": "Liza Newman",
    "gender": "female",
    "totalSales": 8494.184303,
    "firstOrderDate": "2019-02-12",
    "avgOrderSize": 58.921604,
    "AvgDaysBetweenOrders": 131
  },
  {
    "id": "5eb822880a136ecffa82bf76",
    "name": "Helene Cline",
    "gender": "female",
    "totalSales": 4576.485638,
    "firstOrderDate": "2017-07-26",
    "avgOrderSize": 299.013244,
    "AvgDaysBetweenOrders": 236
  },
  {
    "id": "5eb822886fd1534b3cbc7f74",
    "name": "Sheila Odonnell",
    "gender": "female",
    "totalSales": 5918.610067,
    "firstOrderDate": "2017-02-26",
    "avgOrderSize": 162.328906,
    "AvgDaysBetweenOrders": 212
  },
  {
    "id": "5eb82288dd8373b5137d8a97",
    "name": "Marcy Moss",
    "gender": "female",
    "totalSales": 41.276859,
    "firstOrderDate": "2019-09-30",
    "avgOrderSize": 297.045152,
    "AvgDaysBetweenOrders": 229
  },
  {
    "id": "5eb8228895417d319e6fdedd",
    "name": "Karla Horn",
    "gender": "female",
    "totalSales": 520.915878,
    "firstOrderDate": "2016-03-14",
    "avgOrderSize": 246.339576,
    "AvgDaysBetweenOrders": 71
  },
  {
    "id": "5eb822889f36fb6b6658ea9a",
    "name": "Guerrero Daniels",
    "gender": "male",
    "totalSales": 3830.277639,
    "firstOrderDate": "2016-10-16",
    "avgOrderSize": 120.988739,
    "AvgDaysBetweenOrders": 298
  },
  {
    "id": "5eb82288afe04ab72aea9450",
    "name": "Drake Graham",
    "gender": "male",
    "totalSales": 3882.907374,
    "firstOrderDate": "2018-06-10",
    "avgOrderSize": 141.320056,
    "AvgDaysBetweenOrders": 149
  },
  {
    "id": "5eb82288806d1c05d3c36d71",
    "name": "Felicia Faulkner",
    "gender": "female",
    "totalSales": 3498.02553,
    "firstOrderDate": "2018-01-07",
    "avgOrderSize": 150.510247,
    "AvgDaysBetweenOrders": 299
  },
  {
    "id": "5eb82288dc1feefe64e9e42b",
    "name": "Hoover Durham",
    "gender": "male",
    "totalSales": 4649.150739,
    "firstOrderDate": "2014-03-10",
    "avgOrderSize": 243.013004,
    "AvgDaysBetweenOrders": 200
  },
  {
    "id": "5eb822889f56d652f5bfd117",
    "name": "Forbes Fuentes",
    "gender": "male",
    "totalSales": 4293.097744,
    "firstOrderDate": "2014-08-22",
    "avgOrderSize": 196.002158,
    "AvgDaysBetweenOrders": 110
  },
  {
    "id": "5eb822889793697261e70580",
    "name": "Christine Sexton",
    "gender": "female",
    "totalSales": 3513.202256,
    "firstOrderDate": "2014-11-08",
    "avgOrderSize": 249.990874,
    "AvgDaysBetweenOrders": 154
  },
  {
    "id": "5eb822887ad86a5e72d811f9",
    "name": "Allyson Fletcher",
    "gender": "female",
    "totalSales": 3741.35494,
    "firstOrderDate": "2016-02-22",
    "avgOrderSize": 26.599935,
    "AvgDaysBetweenOrders": 60
  },
  {
    "id": "5eb822883225dc5b85ba5719",
    "name": "Elaine Mcgowan",
    "gender": "female",
    "totalSales": 1054.56154,
    "firstOrderDate": "2016-04-05",
    "avgOrderSize": 153.225086,
    "AvgDaysBetweenOrders": 57
  },
  {
    "id": "5eb82288c2ccb299e3334bce",
    "name": "Glenna Ramos",
    "gender": "female",
    "totalSales": 1645.33684,
    "firstOrderDate": "2015-01-17",
    "avgOrderSize": 298.831247,
    "AvgDaysBetweenOrders": 58
  },
  {
    "id": "5eb8228835aa5782b04bebcc",
    "name": "Murphy Glass",
    "gender": "male",
    "totalSales": 8866.813159,
    "firstOrderDate": "2016-08-15",
    "avgOrderSize": 54.24743,
    "AvgDaysBetweenOrders": 221
  },
  {
    "id": "5eb82288a88d0a77c4fdd872",
    "name": "Norma Cole",
    "gender": "female",
    "totalSales": 1173.157138,
    "firstOrderDate": "2020-04-29",
    "avgOrderSize": 229.122181,
    "AvgDaysBetweenOrders": 75
  },
  {
    "id": "5eb822881d5601d663425fa2",
    "name": "Puckett Floyd",
    "gender": "male",
    "totalSales": 1560.328965,
    "firstOrderDate": "2020-01-30",
    "avgOrderSize": 294.20044,
    "AvgDaysBetweenOrders": 98
  },
  {
    "id": "5eb82288baed5026090f05f9",
    "name": "Kathryn Gill",
    "gender": "female",
    "totalSales": 350.208771,
    "firstOrderDate": "2014-03-09",
    "avgOrderSize": 99.704565,
    "AvgDaysBetweenOrders": 68
  },
  {
    "id": "5eb82288871be53725a12937",
    "name": "Hoffman Avila",
    "gender": "male",
    "totalSales": 6639.374963,
    "firstOrderDate": "2015-10-28",
    "avgOrderSize": 206.166547,
    "AvgDaysBetweenOrders": 25
  },
  {
    "id": "5eb82288cf254f74a473fd7c",
    "name": "Ramona Hoffman",
    "gender": "female",
    "totalSales": 6484.049699,
    "firstOrderDate": "2015-10-11",
    "avgOrderSize": 233.954595,
    "AvgDaysBetweenOrders": 66
  },
  {
    "id": "5eb8228826e03ca2af0336e8",
    "name": "Hyde Cantu",
    "gender": "male",
    "totalSales": 7107.11029,
    "firstOrderDate": "2018-04-01",
    "avgOrderSize": 134.213253,
    "AvgDaysBetweenOrders": 148
  },
  {
    "id": "5eb82288d4d01ead24e6ae0c",
    "name": "Colleen Mann",
    "gender": "female",
    "totalSales": 5450.548892,
    "firstOrderDate": "2017-11-30",
    "avgOrderSize": 265.023455,
    "AvgDaysBetweenOrders": 53
  },
  {
    "id": "5eb82288d60faeadaa502da9",
    "name": "Chelsea Elliott",
    "gender": "female",
    "totalSales": 1.501277,
    "firstOrderDate": "2019-01-29",
    "avgOrderSize": 107.556319,
    "AvgDaysBetweenOrders": 228
  },
  {
    "id": "5eb82288bef91dd18b9485ed",
    "name": "Aurelia Davidson",
    "gender": "female",
    "totalSales": 9448.726534,
    "firstOrderDate": "2018-02-19",
    "avgOrderSize": 173.257311,
    "AvgDaysBetweenOrders": 146
  },
  {
    "id": "5eb82288ffd6ee42d96f20a5",
    "name": "Marylou Giles",
    "gender": "female",
    "totalSales": 1309.589275,
    "firstOrderDate": "2014-04-15",
    "avgOrderSize": 89.592233,
    "AvgDaysBetweenOrders": 254
  },
  {
    "id": "5eb822889c24b3752078ef72",
    "name": "Melisa Kim",
    "gender": "female",
    "totalSales": 1099.367256,
    "firstOrderDate": "2020-03-01",
    "avgOrderSize": 8.30693,
    "AvgDaysBetweenOrders": 120
  },
  {
    "id": "5eb822884fdcbcf1fc22110e",
    "name": "Margery Conley",
    "gender": "female",
    "totalSales": 7390.233796,
    "firstOrderDate": "2016-03-27",
    "avgOrderSize": 16.526447,
    "AvgDaysBetweenOrders": 253
  },
  {
    "id": "5eb82288a084145cd1d19ea5",
    "name": "Jenna Knox",
    "gender": "female",
    "totalSales": 2647.88518,
    "firstOrderDate": "2015-03-31",
    "avgOrderSize": 62.892409,
    "AvgDaysBetweenOrders": 262
  },
  {
    "id": "5eb82288a7f70ea1d0cb0dd3",
    "name": "Cornelia Greene",
    "gender": "female",
    "totalSales": 6571.677248,
    "firstOrderDate": "2016-04-13",
    "avgOrderSize": 114.966831,
    "AvgDaysBetweenOrders": 114
  },
  {
    "id": "5eb822888f25eecf69439211",
    "name": "Ruthie Tyler",
    "gender": "female",
    "totalSales": 875.477744,
    "firstOrderDate": "2017-01-18",
    "avgOrderSize": 93.172871,
    "AvgDaysBetweenOrders": 26
  },
  {
    "id": "5eb822887a22752d80834f14",
    "name": "Iva Walsh",
    "gender": "female",
    "totalSales": 9933.951115,
    "firstOrderDate": "2017-04-29",
    "avgOrderSize": 10.970345,
    "AvgDaysBetweenOrders": 225
  },
  {
    "id": "5eb82288b75e799cee5888b1",
    "name": "Herring Fry",
    "gender": "male",
    "totalSales": 4489.826943,
    "firstOrderDate": "2016-01-26",
    "avgOrderSize": 255.096434,
    "AvgDaysBetweenOrders": 65
  },
  {
    "id": "5eb82288e10d7a7fa6909562",
    "name": "Hopkins Carlson",
    "gender": "male",
    "totalSales": 6245.432225,
    "firstOrderDate": "2017-04-01",
    "avgOrderSize": 244.506115,
    "AvgDaysBetweenOrders": 179
  },
  {
    "id": "5eb82288e906ab6a5888e41f",
    "name": "Joseph Ford",
    "gender": "male",
    "totalSales": 2848.986418,
    "firstOrderDate": "2016-03-27",
    "avgOrderSize": 57.298751,
    "AvgDaysBetweenOrders": 43
  },
  {
    "id": "5eb82288ed6ef2e66d7b86bf",
    "name": "Meadows Duncan",
    "gender": "male",
    "totalSales": 341.375429,
    "firstOrderDate": "2017-01-08",
    "avgOrderSize": 103.37139,
    "AvgDaysBetweenOrders": 7
  },
  {
    "id": "5eb82288c83db30c98a94c32",
    "name": "Kane Foley",
    "gender": "male",
    "totalSales": 7845.123107,
    "firstOrderDate": "2016-01-09",
    "avgOrderSize": 93.84435,
    "AvgDaysBetweenOrders": 230
  },
  {
    "id": "5eb822881c2b1ec9b0f14733",
    "name": "Elena Goodman",
    "gender": "female",
    "totalSales": 8811.212169,
    "firstOrderDate": "2017-02-08",
    "avgOrderSize": 98.599416,
    "AvgDaysBetweenOrders": 33
  },
  {
    "id": "5eb82288f54e9fa948f6f3c7",
    "name": "Ramsey Malone",
    "gender": "male",
    "totalSales": 2875.844585,
    "firstOrderDate": "2020-03-26",
    "avgOrderSize": 238.976858,
    "AvgDaysBetweenOrders": 150
  },
  {
    "id": "5eb82288cbb96a0aa49aa082",
    "name": "Williamson Macias",
    "gender": "male",
    "totalSales": 5690.723663,
    "firstOrderDate": "2018-01-02",
    "avgOrderSize": 204.5503,
    "AvgDaysBetweenOrders": 253
  },
  {
    "id": "5eb82288797c5886331455e5",
    "name": "Mills Glenn",
    "gender": "male",
    "totalSales": 311.799695,
    "firstOrderDate": "2019-10-29",
    "avgOrderSize": 136.39258,
    "AvgDaysBetweenOrders": 171
  },
  {
    "id": "5eb822880f54b566e45e93b6",
    "name": "Britney Lynch",
    "gender": "female",
    "totalSales": 1299.852348,
    "firstOrderDate": "2017-12-14",
    "avgOrderSize": 220.282463,
    "AvgDaysBetweenOrders": 46
  },
  {
    "id": "5eb82288054cf4fd4875e6ab",
    "name": "Boyle Kline",
    "gender": "male",
    "totalSales": 130.881334,
    "firstOrderDate": "2015-10-02",
    "avgOrderSize": 239.105819,
    "AvgDaysBetweenOrders": 104
  },
  {
    "id": "5eb822887e6e96809a071513",
    "name": "Edwards Gilbert",
    "gender": "male",
    "totalSales": 6459.670168,
    "firstOrderDate": "2015-11-04",
    "avgOrderSize": 58.933285,
    "AvgDaysBetweenOrders": 17
  },
  {
    "id": "5eb822883b2a1ce8900bead9",
    "name": "Julianne Hayden",
    "gender": "female",
    "totalSales": 3931.511451,
    "firstOrderDate": "2018-06-20",
    "avgOrderSize": 166.417009,
    "AvgDaysBetweenOrders": 226
  },
  {
    "id": "5eb822885a25ac9b74899e40",
    "name": "Millicent Le",
    "gender": "female",
    "totalSales": 957.703416,
    "firstOrderDate": "2016-08-14",
    "avgOrderSize": 91.898367,
    "AvgDaysBetweenOrders": 251
  },
  {
    "id": "5eb82288e41059cc98e029a5",
    "name": "Milagros Perkins",
    "gender": "female",
    "totalSales": 338.587294,
    "firstOrderDate": "2014-04-15",
    "avgOrderSize": 124.791194,
    "AvgDaysBetweenOrders": 39
  },
  {
    "id": "5eb822889b1626fbb9039efc",
    "name": "Noemi Sykes",
    "gender": "female",
    "totalSales": 765.908062,
    "firstOrderDate": "2018-06-18",
    "avgOrderSize": 281.983538,
    "AvgDaysBetweenOrders": 157
  },
  {
    "id": "5eb8228867f38fc7c8e4c629",
    "name": "Ingrid Strickland",
    "gender": "female",
    "totalSales": 7438.865029,
    "firstOrderDate": "2019-12-09",
    "avgOrderSize": 52.442255,
    "AvgDaysBetweenOrders": 209
  },
  {
    "id": "5eb8228896df9515fd1f4321",
    "name": "Robbie Stevenson",
    "gender": "female",
    "totalSales": 9596.56758,
    "firstOrderDate": "2015-08-06",
    "avgOrderSize": 239.541559,
    "AvgDaysBetweenOrders": 9
  },
  {
    "id": "5eb822886d73de3c8eec92c1",
    "name": "Burks Lowe",
    "gender": "male",
    "totalSales": 8930.586764,
    "firstOrderDate": "2015-04-20",
    "avgOrderSize": 257.605709,
    "AvgDaysBetweenOrders": 274
  },
  {
    "id": "5eb82288f9761433c8b4dfbd",
    "name": "Wanda Stewart",
    "gender": "female",
    "totalSales": 9089.801001,
    "firstOrderDate": "2014-12-15",
    "avgOrderSize": 187.119938,
    "AvgDaysBetweenOrders": 144
  },
  {
    "id": "5eb82288888bc41ce7f8c0aa",
    "name": "Tina Douglas",
    "gender": "female",
    "totalSales": 2061.413425,
    "firstOrderDate": "2019-09-27",
    "avgOrderSize": 173.42022,
    "AvgDaysBetweenOrders": 9
  },
  {
    "id": "5eb8228801c9145f24d3d636",
    "name": "Joanne Robles",
    "gender": "female",
    "totalSales": 9289.943104,
    "firstOrderDate": "2015-02-02",
    "avgOrderSize": 229.074949,
    "AvgDaysBetweenOrders": 117
  },
  {
    "id": "5eb82288af751ebd3392ac90",
    "name": "Laura Abbott",
    "gender": "female",
    "totalSales": 8499.806767,
    "firstOrderDate": "2020-04-28",
    "avgOrderSize": 90.451031,
    "AvgDaysBetweenOrders": 200
  },
  {
    "id": "5eb8228859da0e1b65593a55",
    "name": "Ferguson Greer",
    "gender": "male",
    "totalSales": 7974.842202,
    "firstOrderDate": "2018-11-14",
    "avgOrderSize": 83.348438,
    "AvgDaysBetweenOrders": 274
  },
  {
    "id": "5eb822888b46a636c4cc6695",
    "name": "Stella Collier",
    "gender": "female",
    "totalSales": 3856.508098,
    "firstOrderDate": "2015-12-24",
    "avgOrderSize": 68.458397,
    "AvgDaysBetweenOrders": 55
  },
  {
    "id": "5eb82288d54097bb50ad30d0",
    "name": "Ashley Cameron",
    "gender": "female",
    "totalSales": 9512.605012,
    "firstOrderDate": "2014-08-07",
    "avgOrderSize": 227.975136,
    "AvgDaysBetweenOrders": 79
  },
  {
    "id": "5eb8228801dceeb2f6557b83",
    "name": "Lizzie Barrera",
    "gender": "female",
    "totalSales": 1145.555007,
    "firstOrderDate": "2014-09-02",
    "avgOrderSize": 176.452965,
    "AvgDaysBetweenOrders": 167
  },
  {
    "id": "5eb8228822cd437040965169",
    "name": "Park Bonner",
    "gender": "male",
    "totalSales": 1434.087727,
    "firstOrderDate": "2016-08-08",
    "avgOrderSize": 75.083589,
    "AvgDaysBetweenOrders": 154
  },
  {
    "id": "5eb82288162df4f4cd2b8a6d",
    "name": "Sandoval Wilder",
    "gender": "male",
    "totalSales": 9908.564573,
    "firstOrderDate": "2015-08-01",
    "avgOrderSize": 207.653673,
    "AvgDaysBetweenOrders": 83
  },
  {
    "id": "5eb822885af16ce13d3168bf",
    "name": "Madelyn Nash",
    "gender": "female",
    "totalSales": 8363.977246,
    "firstOrderDate": "2014-05-04",
    "avgOrderSize": 228.298395,
    "AvgDaysBetweenOrders": 20
  },
  {
    "id": "5eb82288ce7ae18fee5f67ee",
    "name": "Watkins Vargas",
    "gender": "male",
    "totalSales": 160.976148,
    "firstOrderDate": "2014-09-05",
    "avgOrderSize": 163.638493,
    "AvgDaysBetweenOrders": 68
  },
  {
    "id": "5eb822887191cc36e64d1a99",
    "name": "Cline Gordon",
    "gender": "male",
    "totalSales": 566.577268,
    "firstOrderDate": "2015-08-05",
    "avgOrderSize": 19.496496,
    "AvgDaysBetweenOrders": 283
  },
  {
    "id": "5eb822885a06174fb1c9020d",
    "name": "Carmela Cooke",
    "gender": "female",
    "totalSales": 2399.8744,
    "firstOrderDate": "2017-01-07",
    "avgOrderSize": 267.725238,
    "AvgDaysBetweenOrders": 110
  },
  {
    "id": "5eb82288e04aee070e7723e6",
    "name": "Guy Mayer",
    "gender": "male",
    "totalSales": 5934.792824,
    "firstOrderDate": "2016-06-20",
    "avgOrderSize": 272.188141,
    "AvgDaysBetweenOrders": 282
  },
  {
    "id": "5eb82288f09071ee924a8d04",
    "name": "Tanner Daugherty",
    "gender": "male",
    "totalSales": 1848.32033,
    "firstOrderDate": "2016-11-30",
    "avgOrderSize": 239.212965,
    "AvgDaysBetweenOrders": 172
  },
  {
    "id": "5eb822886e892246e00d5895",
    "name": "Margie Rogers",
    "gender": "female",
    "totalSales": 2903.719457,
    "firstOrderDate": "2018-02-01",
    "avgOrderSize": 205.465792,
    "AvgDaysBetweenOrders": 145
  },
  {
    "id": "5eb822884ab1aecb12c7b3d2",
    "name": "May Avery",
    "gender": "male",
    "totalSales": 3662.238883,
    "firstOrderDate": "2019-06-19",
    "avgOrderSize": 34.653084,
    "AvgDaysBetweenOrders": 195
  },
  {
    "id": "5eb822888f6549bc8d21bdf2",
    "name": "Elsie Reid",
    "gender": "female",
    "totalSales": 6560.018484,
    "firstOrderDate": "2018-02-27",
    "avgOrderSize": 23.431469,
    "AvgDaysBetweenOrders": 94
  },
  {
    "id": "5eb82288d9f17c1d7759fff0",
    "name": "Rosalyn Hart",
    "gender": "female",
    "totalSales": 5815.705752,
    "firstOrderDate": "2018-07-08",
    "avgOrderSize": 265.174969,
    "AvgDaysBetweenOrders": 272
  },
  {
    "id": "5eb8228859c002bf6fbde787",
    "name": "Rachel Mcbride",
    "gender": "female",
    "totalSales": 5701.362093,
    "firstOrderDate": "2019-01-07",
    "avgOrderSize": 277.883543,
    "AvgDaysBetweenOrders": 215
  },
  {
    "id": "5eb82288053d75c4eaab0c33",
    "name": "Kelli Best",
    "gender": "female",
    "totalSales": 8866.483774,
    "firstOrderDate": "2019-11-17",
    "avgOrderSize": 81.026294,
    "AvgDaysBetweenOrders": 286
  },
  {
    "id": "5eb822880e9411edb0de9c5c",
    "name": "Bates Callahan",
    "gender": "male",
    "totalSales": 500.109345,
    "firstOrderDate": "2017-04-27",
    "avgOrderSize": 274.55492,
    "AvgDaysBetweenOrders": 76
  },
  {
    "id": "5eb82288b7360f8e4cfb0ba2",
    "name": "Raquel Schultz",
    "gender": "female",
    "totalSales": 4754.665477,
    "firstOrderDate": "2019-07-07",
    "avgOrderSize": 180.411743,
    "AvgDaysBetweenOrders": 68
  },
  {
    "id": "5eb8228894fda801c92998b2",
    "name": "Eva Reynolds",
    "gender": "female",
    "totalSales": 7925.239726,
    "firstOrderDate": "2019-11-03",
    "avgOrderSize": 88.732975,
    "AvgDaysBetweenOrders": 47
  },
  {
    "id": "5eb822886c0dcada8a160438",
    "name": "Carroll Sutton",
    "gender": "male",
    "totalSales": 7235.772362,
    "firstOrderDate": "2014-10-19",
    "avgOrderSize": 42.799143,
    "AvgDaysBetweenOrders": 1
  },
  {
    "id": "5eb82288e488b47accf2e0e6",
    "name": "Holder Mckee",
    "gender": "male",
    "totalSales": 7414.755,
    "firstOrderDate": "2014-04-05",
    "avgOrderSize": 126.861282,
    "AvgDaysBetweenOrders": 219
  },
  {
    "id": "5eb82288dfe30eb62e190433",
    "name": "Bender Flores",
    "gender": "male",
    "totalSales": 5690.870121,
    "firstOrderDate": "2020-04-05",
    "avgOrderSize": 68.688948,
    "AvgDaysBetweenOrders": 230
  },
  {
    "id": "5eb82288ab98ede06c7c9ea4",
    "name": "Pace Ayers",
    "gender": "male",
    "totalSales": 7047.275309,
    "firstOrderDate": "2014-10-15",
    "avgOrderSize": 21.1986,
    "AvgDaysBetweenOrders": 189
  },
  {
    "id": "5eb82288f08e817684316bae",
    "name": "Farley Hess",
    "gender": "male",
    "totalSales": 7214.911282,
    "firstOrderDate": "2016-09-22",
    "avgOrderSize": 40.752747,
    "AvgDaysBetweenOrders": 185
  },
  {
    "id": "5eb822880c53c4d15fa64c38",
    "name": "Anthony Clay",
    "gender": "male",
    "totalSales": 4711.980763,
    "firstOrderDate": "2017-04-07",
    "avgOrderSize": 101.848894,
    "AvgDaysBetweenOrders": 138
  },
  {
    "id": "5eb82288b4015fd7fa3d9ee6",
    "name": "Pollard Carrillo",
    "gender": "male",
    "totalSales": 2127.127976,
    "firstOrderDate": "2015-12-23",
    "avgOrderSize": 125.251626,
    "AvgDaysBetweenOrders": 120
  },
  {
    "id": "5eb8228820154566a25e07de",
    "name": "Jillian Barry",
    "gender": "female",
    "totalSales": 9593.335904,
    "firstOrderDate": "2016-12-18",
    "avgOrderSize": 199.403387,
    "AvgDaysBetweenOrders": 11
  },
  {
    "id": "5eb8228818a2a3453bae6f6a",
    "name": "Gilliam Hewitt",
    "gender": "male",
    "totalSales": 4109.512947,
    "firstOrderDate": "2014-02-21",
    "avgOrderSize": 231.232157,
    "AvgDaysBetweenOrders": 157
  },
  {
    "id": "5eb822888b54e5278072f72f",
    "name": "Kendra Davenport",
    "gender": "female",
    "totalSales": 2556.351432,
    "firstOrderDate": "2014-06-29",
    "avgOrderSize": 243.47993,
    "AvgDaysBetweenOrders": 139
  },
  {
    "id": "5eb82288ef9338122d22cd7f",
    "name": "Marianne Mullins",
    "gender": "female",
    "totalSales": 2057.751509,
    "firstOrderDate": "2016-09-20",
    "avgOrderSize": 90.20433,
    "AvgDaysBetweenOrders": 208
  },
  {
    "id": "5eb82288eaf556f928247b26",
    "name": "Loraine Peters",
    "gender": "female",
    "totalSales": 4883.722739,
    "firstOrderDate": "2015-02-28",
    "avgOrderSize": 226.189265,
    "AvgDaysBetweenOrders": 202
  },
  {
    "id": "5eb82288a5d9f61fbfd3b2ca",
    "name": "Huffman Burke",
    "gender": "male",
    "totalSales": 1655.127299,
    "firstOrderDate": "2016-05-07",
    "avgOrderSize": 68.86966,
    "AvgDaysBetweenOrders": 236
  },
  {
    "id": "5eb82288870ef047ce7b5095",
    "name": "Wilma Day",
    "gender": "female",
    "totalSales": 7806.705842,
    "firstOrderDate": "2014-04-25",
    "avgOrderSize": 210.832261,
    "AvgDaysBetweenOrders": 231
  },
  {
    "id": "5eb82288768b6de103858ccb",
    "name": "Alma Bradley",
    "gender": "female",
    "totalSales": 799.633913,
    "firstOrderDate": "2018-03-31",
    "avgOrderSize": 74.314937,
    "AvgDaysBetweenOrders": 62
  },
  {
    "id": "5eb82288efc877ff59e8b7ff",
    "name": "Ada Hines",
    "gender": "female",
    "totalSales": 2517.243189,
    "firstOrderDate": "2018-01-07",
    "avgOrderSize": 112.027989,
    "AvgDaysBetweenOrders": 239
  },
  {
    "id": "5eb822887863e0390b494434",
    "name": "Vega Contreras",
    "gender": "male",
    "totalSales": 764.567184,
    "firstOrderDate": "2015-10-19",
    "avgOrderSize": 27.111819,
    "AvgDaysBetweenOrders": 129
  },
  {
    "id": "5eb82288f58a4ae325f8f9d3",
    "name": "Justice White",
    "gender": "male",
    "totalSales": 5259.261186,
    "firstOrderDate": "2017-07-02",
    "avgOrderSize": 36.537862,
    "AvgDaysBetweenOrders": 252
  },
  {
    "id": "5eb822880629b35211c19f62",
    "name": "Judith Owen",
    "gender": "female",
    "totalSales": 8249.126429,
    "firstOrderDate": "2017-02-22",
    "avgOrderSize": 55.04331,
    "AvgDaysBetweenOrders": 260
  },
  {
    "id": "5eb822888522b7747a7f5761",
    "name": "Austin Long",
    "gender": "male",
    "totalSales": 5229.066142,
    "firstOrderDate": "2014-08-15",
    "avgOrderSize": 119.80318,
    "AvgDaysBetweenOrders": 156
  },
  {
    "id": "5eb82288d8c10086d5941ca5",
    "name": "Meyers Sweet",
    "gender": "male",
    "totalSales": 6433.03462,
    "firstOrderDate": "2016-02-21",
    "avgOrderSize": 97.216546,
    "AvgDaysBetweenOrders": 200
  },
  {
    "id": "5eb82288448f3f1176c5546d",
    "name": "Leanna Maldonado",
    "gender": "female",
    "totalSales": 2457.162638,
    "firstOrderDate": "2020-02-09",
    "avgOrderSize": 189.22441,
    "AvgDaysBetweenOrders": 108
  },
  {
    "id": "5eb822889044fc97645caa21",
    "name": "Casandra Pearson",
    "gender": "female",
    "totalSales": 8308.988121,
    "firstOrderDate": "2014-11-12",
    "avgOrderSize": 12.558571,
    "AvgDaysBetweenOrders": 203
  },
  {
    "id": "5eb82288d1d359299b90e13d",
    "name": "Marquez Wade",
    "gender": "male",
    "totalSales": 5408.534708,
    "firstOrderDate": "2014-10-05",
    "avgOrderSize": 191.791917,
    "AvgDaysBetweenOrders": 114
  },
  {
    "id": "5eb8228868c8e4ca97e8d34e",
    "name": "Conner Velez",
    "gender": "male",
    "totalSales": 7608.284254,
    "firstOrderDate": "2016-12-07",
    "avgOrderSize": 295.519043,
    "AvgDaysBetweenOrders": 82
  },
  {
    "id": "5eb82288d0ce5bcf8f3e7751",
    "name": "Roy Mcclure",
    "gender": "male",
    "totalSales": 5196.640576,
    "firstOrderDate": "2017-02-09",
    "avgOrderSize": 210.802208,
    "AvgDaysBetweenOrders": 79
  },
  {
    "id": "5eb8228855978049c5100915",
    "name": "Fischer Hatfield",
    "gender": "male",
    "totalSales": 3844.7109,
    "firstOrderDate": "2015-09-29",
    "avgOrderSize": 244.518382,
    "AvgDaysBetweenOrders": 160
  },
  {
    "id": "5eb82288bbd4cd4eba1008d0",
    "name": "Clark Lewis",
    "gender": "male",
    "totalSales": 1014.692411,
    "firstOrderDate": "2014-04-30",
    "avgOrderSize": 257.447372,
    "AvgDaysBetweenOrders": 154
  },
  {
    "id": "5eb822882bdb77fc05f5e438",
    "name": "Obrien Carr",
    "gender": "male",
    "totalSales": 3410.190494,
    "firstOrderDate": "2017-04-04",
    "avgOrderSize": 157.30683,
    "AvgDaysBetweenOrders": 200
  },
  {
    "id": "5eb82288fd3e6e75dec2360d",
    "name": "Marquita Ayala",
    "gender": "female",
    "totalSales": 5185.59344,
    "firstOrderDate": "2018-06-18",
    "avgOrderSize": 54.279739,
    "AvgDaysBetweenOrders": 251
  },
  {
    "id": "5eb822887cd468f95dad6b30",
    "name": "Madge Ashley",
    "gender": "female",
    "totalSales": 1050.143231,
    "firstOrderDate": "2017-07-22",
    "avgOrderSize": 1.676857,
    "AvgDaysBetweenOrders": 48
  },
  {
    "id": "5eb8228893f640dc769678bc",
    "name": "Sheri Duke",
    "gender": "female",
    "totalSales": 867.298832,
    "firstOrderDate": "2015-09-15",
    "avgOrderSize": 179.649311,
    "AvgDaysBetweenOrders": 163
  },
  {
    "id": "5eb822886b96f0dc4a7ad6cd",
    "name": "Bell Nguyen",
    "gender": "male",
    "totalSales": 4373.919202,
    "firstOrderDate": "2018-04-25",
    "avgOrderSize": 124.788209,
    "AvgDaysBetweenOrders": 76
  },
  {
    "id": "5eb82288e56bafed6592a67a",
    "name": "Lakisha Wright",
    "gender": "female",
    "totalSales": 4105.009297,
    "firstOrderDate": "2014-04-30",
    "avgOrderSize": 217.698833,
    "AvgDaysBetweenOrders": 96
  },
  {
    "id": "5eb822889d68aeacd7d9b2de",
    "name": "Anna Jarvis",
    "gender": "female",
    "totalSales": 3635.054578,
    "firstOrderDate": "2018-03-23",
    "avgOrderSize": 195.283957,
    "AvgDaysBetweenOrders": 291
  },
  {
    "id": "5eb822880e59d5da14574953",
    "name": "Martin Webb",
    "gender": "male",
    "totalSales": 3283.329025,
    "firstOrderDate": "2019-07-25",
    "avgOrderSize": 5.739583,
    "AvgDaysBetweenOrders": 13
  },
  {
    "id": "5eb822883ff6f5af0d3259c4",
    "name": "Jacquelyn Franks",
    "gender": "female",
    "totalSales": 4948.025847,
    "firstOrderDate": "2017-11-06",
    "avgOrderSize": 248.992301,
    "AvgDaysBetweenOrders": 33
  },
  {
    "id": "5eb82288f0757a15f7201b57",
    "name": "Chen Mendoza",
    "gender": "male",
    "totalSales": 3584.14172,
    "firstOrderDate": "2014-04-09",
    "avgOrderSize": 297.564107,
    "AvgDaysBetweenOrders": 275
  },
  {
    "id": "5eb8228840bba15217e0593c",
    "name": "Penny Stein",
    "gender": "female",
    "totalSales": 1372.670645,
    "firstOrderDate": "2018-05-17",
    "avgOrderSize": 19.078837,
    "AvgDaysBetweenOrders": 96
  },
  {
    "id": "5eb8228885070b53a57702c9",
    "name": "Fay Donovan",
    "gender": "female",
    "totalSales": 3486.2904,
    "firstOrderDate": "2018-05-03",
    "avgOrderSize": 221.018548,
    "AvgDaysBetweenOrders": 48
  },
  {
    "id": "5eb82288efa33f1fa7871cc7",
    "name": "Everett Hayes",
    "gender": "male",
    "totalSales": 5612.754262,
    "firstOrderDate": "2019-11-05",
    "avgOrderSize": 134.656184,
    "AvgDaysBetweenOrders": 244
  },
  {
    "id": "5eb822884a6d5e5b5a4ecfaa",
    "name": "Barbara Byrd",
    "gender": "female",
    "totalSales": 7566.239339,
    "firstOrderDate": "2015-10-24",
    "avgOrderSize": 156.311224,
    "AvgDaysBetweenOrders": 279
  },
  {
    "id": "5eb8228866449af545dcc2f2",
    "name": "Debbie Cobb",
    "gender": "female",
    "totalSales": 3295.102871,
    "firstOrderDate": "2018-04-11",
    "avgOrderSize": 85.045124,
    "AvgDaysBetweenOrders": 157
  },
  {
    "id": "5eb82288c20ddc5f28710474",
    "name": "Acevedo England",
    "gender": "male",
    "totalSales": 3278.602939,
    "firstOrderDate": "2014-02-10",
    "avgOrderSize": 264.201042,
    "AvgDaysBetweenOrders": 37
  },
  {
    "id": "5eb82288548672a3d25cdab6",
    "name": "Trisha Thompson",
    "gender": "female",
    "totalSales": 3001.703345,
    "firstOrderDate": "2019-08-12",
    "avgOrderSize": 217.855315,
    "AvgDaysBetweenOrders": 272
  },
  {
    "id": "5eb82288048371cf66a0edc5",
    "name": "Jacobson Dunlap",
    "gender": "male",
    "totalSales": 9268.968782,
    "firstOrderDate": "2015-07-01",
    "avgOrderSize": 142.881209,
    "AvgDaysBetweenOrders": 106
  },
  {
    "id": "5eb82288e2bbc0f664b37742",
    "name": "Hinton Miles",
    "gender": "male",
    "totalSales": 6162.759984,
    "firstOrderDate": "2020-01-05",
    "avgOrderSize": 171.124016,
    "AvgDaysBetweenOrders": 253
  },
  {
    "id": "5eb82288dafa3880d616f2e4",
    "name": "Hebert Bradford",
    "gender": "male",
    "totalSales": 395.668406,
    "firstOrderDate": "2019-11-30",
    "avgOrderSize": 256.966556,
    "AvgDaysBetweenOrders": 52
  },
  {
    "id": "5eb8228857a150fe48cdc056",
    "name": "Reilly Melton",
    "gender": "male",
    "totalSales": 5751.235362,
    "firstOrderDate": "2017-08-24",
    "avgOrderSize": 159.162941,
    "AvgDaysBetweenOrders": 166
  },
  {
    "id": "5eb822886073a6c5aea5f12d",
    "name": "Benita Rose",
    "gender": "female",
    "totalSales": 2957.041857,
    "firstOrderDate": "2018-12-14",
    "avgOrderSize": 102.629067,
    "AvgDaysBetweenOrders": 66
  },
  {
    "id": "5eb8228863d022dbfe3ede40",
    "name": "Torres Trevino",
    "gender": "male",
    "totalSales": 7089.416007,
    "firstOrderDate": "2014-06-01",
    "avgOrderSize": 27.227568,
    "AvgDaysBetweenOrders": 20
  },
  {
    "id": "5eb822880b2e767d5a284005",
    "name": "Graves Hodges",
    "gender": "male",
    "totalSales": 1880.035435,
    "firstOrderDate": "2015-09-14",
    "avgOrderSize": 137.465109,
    "AvgDaysBetweenOrders": 177
  },
  {
    "id": "5eb8228841b223b4dc093fab",
    "name": "Samantha Walls",
    "gender": "female",
    "totalSales": 5378.490398,
    "firstOrderDate": "2019-01-31",
    "avgOrderSize": 261.575898,
    "AvgDaysBetweenOrders": 198
  },
  {
    "id": "5eb82288b46e1299c0bb7c07",
    "name": "Blair Burt",
    "gender": "male",
    "totalSales": 3827.032762,
    "firstOrderDate": "2018-11-30",
    "avgOrderSize": 97.228783,
    "AvgDaysBetweenOrders": 42
  },
  {
    "id": "5eb82288c2102a573173e68f",
    "name": "Lesa Neal",
    "gender": "female",
    "totalSales": 1107.050127,
    "firstOrderDate": "2015-06-14",
    "avgOrderSize": 286.36932,
    "AvgDaysBetweenOrders": 286
  },
  {
    "id": "5eb82288cf2ce24b2c5fe5b5",
    "name": "Dalton Holloway",
    "gender": "male",
    "totalSales": 4746.04999,
    "firstOrderDate": "2018-04-23",
    "avgOrderSize": 89.364515,
    "AvgDaysBetweenOrders": 32
  },
  {
    "id": "5eb82288101789304d91a21f",
    "name": "Willa Craft",
    "gender": "female",
    "totalSales": 2530.45579,
    "firstOrderDate": "2019-07-01",
    "avgOrderSize": 64.410377,
    "AvgDaysBetweenOrders": 189
  },
  {
    "id": "5eb82288e33e16e37eb5920b",
    "name": "Jamie Mejia",
    "gender": "female",
    "totalSales": 9934.812416,
    "firstOrderDate": "2019-08-21",
    "avgOrderSize": 102.226417,
    "AvgDaysBetweenOrders": 165
  },
  {
    "id": "5eb822883548e262ee8935f5",
    "name": "Robles Leblanc",
    "gender": "male",
    "totalSales": 8101.262574,
    "firstOrderDate": "2015-08-12",
    "avgOrderSize": 86.409561,
    "AvgDaysBetweenOrders": 91
  },
  {
    "id": "5eb82288de46418525b6503f",
    "name": "Nell Woods",
    "gender": "female",
    "totalSales": 2308.213942,
    "firstOrderDate": "2019-06-16",
    "avgOrderSize": 202.33283,
    "AvgDaysBetweenOrders": 41
  },
  {
    "id": "5eb82288d8e7ff78f4e241c0",
    "name": "Patterson Hendricks",
    "gender": "male",
    "totalSales": 6877.350074,
    "firstOrderDate": "2015-03-07",
    "avgOrderSize": 218.604082,
    "AvgDaysBetweenOrders": 176
  },
  {
    "id": "5eb82288080a9c86031e0e73",
    "name": "Tammy Hull",
    "gender": "female",
    "totalSales": 4425.839962,
    "firstOrderDate": "2019-05-29",
    "avgOrderSize": 240.782274,
    "AvgDaysBetweenOrders": 226
  },
  {
    "id": "5eb82288a5543043df2df137",
    "name": "Susanne Zamora",
    "gender": "female",
    "totalSales": 6351.903238,
    "firstOrderDate": "2016-09-03",
    "avgOrderSize": 24.434222,
    "AvgDaysBetweenOrders": 3
  },
  {
    "id": "5eb8228830b47b81a321c15f",
    "name": "Patsy Peterson",
    "gender": "female",
    "totalSales": 7241.146173,
    "firstOrderDate": "2015-07-10",
    "avgOrderSize": 60.148155,
    "AvgDaysBetweenOrders": 33
  },
  {
    "id": "5eb822887cef4a0063a4105c",
    "name": "Sherrie Hudson",
    "gender": "female",
    "totalSales": 5720.998318,
    "firstOrderDate": "2014-12-02",
    "avgOrderSize": 140.059845,
    "AvgDaysBetweenOrders": 64
  },
  {
    "id": "5eb822886cae301e6939a8f9",
    "name": "Randall Shepard",
    "gender": "male",
    "totalSales": 541.984604,
    "firstOrderDate": "2018-01-29",
    "avgOrderSize": 31.993185,
    "AvgDaysBetweenOrders": 225
  },
  {
    "id": "5eb822889705bc1e5a450337",
    "name": "Jefferson Norman",
    "gender": "male",
    "totalSales": 8734.725137,
    "firstOrderDate": "2018-09-05",
    "avgOrderSize": 168.786009,
    "AvgDaysBetweenOrders": 39
  },
  {
    "id": "5eb8228858f01e403871a8ca",
    "name": "Lillian Foreman",
    "gender": "female",
    "totalSales": 9457.947653,
    "firstOrderDate": "2020-02-27",
    "avgOrderSize": 165.729736,
    "AvgDaysBetweenOrders": 176
  },
  {
    "id": "5eb822880144b9bdaf646ce0",
    "name": "Jones Glover",
    "gender": "male",
    "totalSales": 8704.291379,
    "firstOrderDate": "2015-09-26",
    "avgOrderSize": 137.567795,
    "AvgDaysBetweenOrders": 206
  },
  {
    "id": "5eb82288adb6206535fac78a",
    "name": "Cross Erickson",
    "gender": "male",
    "totalSales": 2736.167746,
    "firstOrderDate": "2016-09-18",
    "avgOrderSize": 33.282003,
    "AvgDaysBetweenOrders": 87
  },
  {
    "id": "5eb8228801a9ec556b7e5f43",
    "name": "Lesley King",
    "gender": "female",
    "totalSales": 6417.024304,
    "firstOrderDate": "2019-08-24",
    "avgOrderSize": 95.898284,
    "AvgDaysBetweenOrders": 94
  },
  {
    "id": "5eb822887e2bd30020b37e5f",
    "name": "Sabrina Sims",
    "gender": "female",
    "totalSales": 1853.162141,
    "firstOrderDate": "2018-01-14",
    "avgOrderSize": 147.341692,
    "AvgDaysBetweenOrders": 191
  },
  {
    "id": "5eb8228801b3188dff5cb8fe",
    "name": "Monica Rasmussen",
    "gender": "female",
    "totalSales": 1597.695061,
    "firstOrderDate": "2018-08-05",
    "avgOrderSize": 187.902595,
    "AvgDaysBetweenOrders": 256
  },
  {
    "id": "5eb8228884fe68d685bb5a6a",
    "name": "Elnora Gallagher",
    "gender": "female",
    "totalSales": 3338.052845,
    "firstOrderDate": "2016-06-08",
    "avgOrderSize": 53.451204,
    "AvgDaysBetweenOrders": 227
  },
  {
    "id": "5eb8228896a3cc5d83cba460",
    "name": "Sandra Whitaker",
    "gender": "female",
    "totalSales": 6938.822775,
    "firstOrderDate": "2018-04-28",
    "avgOrderSize": 144.538876,
    "AvgDaysBetweenOrders": 83
  },
  {
    "id": "5eb8228867ab0620f83ee9c5",
    "name": "Lenora Guerra",
    "gender": "female",
    "totalSales": 3950.544381,
    "firstOrderDate": "2014-10-21",
    "avgOrderSize": 206.106723,
    "AvgDaysBetweenOrders": 155
  },
  {
    "id": "5eb82288ac12c6d29b83ad53",
    "name": "Neal Simmons",
    "gender": "male",
    "totalSales": 4957.11326,
    "firstOrderDate": "2016-09-25",
    "avgOrderSize": 239.094496,
    "AvgDaysBetweenOrders": 231
  },
  {
    "id": "5eb822889e2b590865bf43a6",
    "name": "Stacey Cardenas",
    "gender": "female",
    "totalSales": 3428.255036,
    "firstOrderDate": "2016-08-23",
    "avgOrderSize": 207.922501,
    "AvgDaysBetweenOrders": 170
  },
  {
    "id": "5eb82288f807245e0bed915d",
    "name": "Erickson Winters",
    "gender": "male",
    "totalSales": 9315.758889,
    "firstOrderDate": "2014-05-27",
    "avgOrderSize": 5.087427,
    "AvgDaysBetweenOrders": 30
  },
  {
    "id": "5eb822889903f2ccdaa20e4e",
    "name": "Rhea Hartman",
    "gender": "female",
    "totalSales": 8003.223711,
    "firstOrderDate": "2018-10-06",
    "avgOrderSize": 70.296793,
    "AvgDaysBetweenOrders": 183
  },
  {
    "id": "5eb8228880a7d97d611cf574",
    "name": "Foley Phelps",
    "gender": "male",
    "totalSales": 3808.7287,
    "firstOrderDate": "2018-02-11",
    "avgOrderSize": 133.64899,
    "AvgDaysBetweenOrders": 247
  },
  {
    "id": "5eb8228806fd756679cf9ca7",
    "name": "Hatfield Riggs",
    "gender": "male",
    "totalSales": 909.095089,
    "firstOrderDate": "2015-04-15",
    "avgOrderSize": 244.664664,
    "AvgDaysBetweenOrders": 55
  },
  {
    "id": "5eb82288653d5d0ee8958f33",
    "name": "Hart Cohen",
    "gender": "male",
    "totalSales": 7057.902842,
    "firstOrderDate": "2015-02-11",
    "avgOrderSize": 244.578596,
    "AvgDaysBetweenOrders": 7
  },
  {
    "id": "5eb8228832f902ee0c9a4219",
    "name": "Dale Stout",
    "gender": "male",
    "totalSales": 5790.622352,
    "firstOrderDate": "2014-04-15",
    "avgOrderSize": 27.873816,
    "AvgDaysBetweenOrders": 250
  },
  {
    "id": "5eb8228883bd2ea8fa11dfde",
    "name": "Elsa Orr",
    "gender": "female",
    "totalSales": 9527.54978,
    "firstOrderDate": "2019-02-13",
    "avgOrderSize": 78.129227,
    "AvgDaysBetweenOrders": 222
  },
  {
    "id": "5eb82288dacf9b1aff5de68a",
    "name": "Frieda Vega",
    "gender": "female",
    "totalSales": 3867.364502,
    "firstOrderDate": "2018-01-31",
    "avgOrderSize": 172.87787,
    "AvgDaysBetweenOrders": 64
  },
  {
    "id": "5eb82288ecb999bb807caa37",
    "name": "Hester Mosley",
    "gender": "female",
    "totalSales": 578.021557,
    "firstOrderDate": "2017-11-06",
    "avgOrderSize": 13.009449,
    "AvgDaysBetweenOrders": 199
  },
  {
    "id": "5eb822884b6af6b0c7443beb",
    "name": "Morton Green",
    "gender": "male",
    "totalSales": 1193.846284,
    "firstOrderDate": "2018-01-12",
    "avgOrderSize": 106.673403,
    "AvgDaysBetweenOrders": 45
  },
  {
    "id": "5eb8228827c638616fbba30e",
    "name": "Rena Herrera",
    "gender": "female",
    "totalSales": 3410.039904,
    "firstOrderDate": "2016-02-01",
    "avgOrderSize": 223.021519,
    "AvgDaysBetweenOrders": 128
  },
  {
    "id": "5eb82288b6c5d4af1ce182a2",
    "name": "Susana Williamson",
    "gender": "female",
    "totalSales": 8813.938156,
    "firstOrderDate": "2017-04-09",
    "avgOrderSize": 183.314832,
    "AvgDaysBetweenOrders": 53
  },
  {
    "id": "5eb8228894ea33c88f01f6df",
    "name": "Welch Huff",
    "gender": "male",
    "totalSales": 3902.636681,
    "firstOrderDate": "2018-11-09",
    "avgOrderSize": 168.401531,
    "AvgDaysBetweenOrders": 294
  },
  {
    "id": "5eb822881d907ea1f74ab9a8",
    "name": "Tabitha Delacruz",
    "gender": "female",
    "totalSales": 5239.789039,
    "firstOrderDate": "2018-09-05",
    "avgOrderSize": 155.066325,
    "AvgDaysBetweenOrders": 258
  },
  {
    "id": "5eb82288bfa771d205836afe",
    "name": "Figueroa Bass",
    "gender": "male",
    "totalSales": 741.967162,
    "firstOrderDate": "2016-02-25",
    "avgOrderSize": 191.425739,
    "AvgDaysBetweenOrders": 44
  },
  {
    "id": "5eb82288ae5f741c8a512994",
    "name": "Araceli Campos",
    "gender": "female",
    "totalSales": 241.519193,
    "firstOrderDate": "2020-04-21",
    "avgOrderSize": 180.639974,
    "AvgDaysBetweenOrders": 195
  },
  {
    "id": "5eb822886689e492c4ab89d0",
    "name": "Emily Sanders",
    "gender": "female",
    "totalSales": 2322.926641,
    "firstOrderDate": "2019-12-07",
    "avgOrderSize": 157.473642,
    "AvgDaysBetweenOrders": 164
  },
  {
    "id": "5eb8228827b1296fb1900973",
    "name": "Cleo Stokes",
    "gender": "female",
    "totalSales": 6453.324685,
    "firstOrderDate": "2019-09-28",
    "avgOrderSize": 131.115461,
    "AvgDaysBetweenOrders": 128
  },
  {
    "id": "5eb8228879fd8fa7a1d7fbaa",
    "name": "Lakeisha Mack",
    "gender": "female",
    "totalSales": 8397.993455,
    "firstOrderDate": "2019-12-25",
    "avgOrderSize": 252.818809,
    "AvgDaysBetweenOrders": 188
  },
  {
    "id": "5eb82288dcb87ac4f38853c1",
    "name": "Letha Cherry",
    "gender": "female",
    "totalSales": 7255.375248,
    "firstOrderDate": "2020-04-03",
    "avgOrderSize": 132.991276,
    "AvgDaysBetweenOrders": 47
  },
  {
    "id": "5eb822885a39d916ca9f9881",
    "name": "Marshall Price",
    "gender": "male",
    "totalSales": 648.386564,
    "firstOrderDate": "2014-01-09",
    "avgOrderSize": 182.114055,
    "AvgDaysBetweenOrders": 100
  },
  {
    "id": "5eb82288d0dbc9d30128d292",
    "name": "Margo Aguilar",
    "gender": "female",
    "totalSales": 5499.806936,
    "firstOrderDate": "2018-04-17",
    "avgOrderSize": 188.803819,
    "AvgDaysBetweenOrders": 210
  },
  {
    "id": "5eb82288fbe0fc82f69bedf6",
    "name": "Baldwin Hopkins",
    "gender": "male",
    "totalSales": 8691.658755,
    "firstOrderDate": "2015-07-10",
    "avgOrderSize": 193.254731,
    "AvgDaysBetweenOrders": 111
  },
  {
    "id": "5eb822887555fa3794a090c2",
    "name": "Elba Conrad",
    "gender": "female",
    "totalSales": 9992.557954,
    "firstOrderDate": "2018-12-08",
    "avgOrderSize": 226.498772,
    "AvgDaysBetweenOrders": 84
  },
  {
    "id": "5eb822881e76d0faae4f76a5",
    "name": "Chris Nieves",
    "gender": "female",
    "totalSales": 7470.483681,
    "firstOrderDate": "2018-12-11",
    "avgOrderSize": 1.076758,
    "AvgDaysBetweenOrders": 118
  },
  {
    "id": "5eb822889dabe01fd02d0147",
    "name": "Nolan Bishop",
    "gender": "male",
    "totalSales": 441.180077,
    "firstOrderDate": "2015-11-25",
    "avgOrderSize": 128.224446,
    "AvgDaysBetweenOrders": 200
  },
  {
    "id": "5eb822888c33051b08efe81f",
    "name": "Andrews Harrison",
    "gender": "male",
    "totalSales": 4791.611855,
    "firstOrderDate": "2019-12-29",
    "avgOrderSize": 15.821902,
    "AvgDaysBetweenOrders": 88
  },
  {
    "id": "5eb82288e874ab9dbf73159b",
    "name": "Mcbride Zimmerman",
    "gender": "male",
    "totalSales": 6203.268945,
    "firstOrderDate": "2017-12-11",
    "avgOrderSize": 132.572366,
    "AvgDaysBetweenOrders": 180
  },
  {
    "id": "5eb82288df047ed5ac54e5ae",
    "name": "Florence Vincent",
    "gender": "female",
    "totalSales": 5039.127126,
    "firstOrderDate": "2016-02-29",
    "avgOrderSize": 253.663352,
    "AvgDaysBetweenOrders": 146
  },
  {
    "id": "5eb822883119473975b373e5",
    "name": "Meyer Johns",
    "gender": "male",
    "totalSales": 5504.044468,
    "firstOrderDate": "2014-12-31",
    "avgOrderSize": 140.998308,
    "AvgDaysBetweenOrders": 162
  },
  {
    "id": "5eb82288dc57eda0657db8cf",
    "name": "Mcintyre Quinn",
    "gender": "male",
    "totalSales": 4649.817152,
    "firstOrderDate": "2014-08-18",
    "avgOrderSize": 84.58833,
    "AvgDaysBetweenOrders": 293
  },
  {
    "id": "5eb822889d0fe19b9d797d80",
    "name": "Alexis Crawford",
    "gender": "female",
    "totalSales": 4331.141315,
    "firstOrderDate": "2016-01-28",
    "avgOrderSize": 228.656386,
    "AvgDaysBetweenOrders": 105
  },
  {
    "id": "5eb82288c3f0924f52c41d53",
    "name": "Ana Roy",
    "gender": "female",
    "totalSales": 398.212762,
    "firstOrderDate": "2014-10-06",
    "avgOrderSize": 297.323165,
    "AvgDaysBetweenOrders": 176
  },
  {
    "id": "5eb82288c720546f73269345",
    "name": "Gill Haynes",
    "gender": "male",
    "totalSales": 9279.099263,
    "firstOrderDate": "2016-12-01",
    "avgOrderSize": 114.591982,
    "AvgDaysBetweenOrders": 204
  },
  {
    "id": "5eb8228810e3d48b2382c4b5",
    "name": "Maryann Rodriguez",
    "gender": "female",
    "totalSales": 2003.1892,
    "firstOrderDate": "2017-08-05",
    "avgOrderSize": 69.820035,
    "AvgDaysBetweenOrders": 122
  },
  {
    "id": "5eb82288fdb502e7f1e3fc2b",
    "name": "Essie Hill",
    "gender": "female",
    "totalSales": 8774.249475,
    "firstOrderDate": "2018-05-20",
    "avgOrderSize": 51.050344,
    "AvgDaysBetweenOrders": 129
  },
  {
    "id": "5eb822886192e322b1484d14",
    "name": "Knight Sherman",
    "gender": "male",
    "totalSales": 9893.250725,
    "firstOrderDate": "2015-02-07",
    "avgOrderSize": 32.488029,
    "AvgDaysBetweenOrders": 20
  },
  {
    "id": "5eb82288729b46b34a95e8dc",
    "name": "Harrington Owens",
    "gender": "male",
    "totalSales": 9187.593646,
    "firstOrderDate": "2016-08-25",
    "avgOrderSize": 197.384,
    "AvgDaysBetweenOrders": 183
  },
  {
    "id": "5eb822889695427a3e46b15f",
    "name": "Aisha Rios",
    "gender": "female",
    "totalSales": 4326.814055,
    "firstOrderDate": "2019-11-20",
    "avgOrderSize": 71.538944,
    "AvgDaysBetweenOrders": 6
  },
  {
    "id": "5eb8228899d75b040bc812b5",
    "name": "Berry Bird",
    "gender": "male",
    "totalSales": 7428.006098,
    "firstOrderDate": "2014-08-25",
    "avgOrderSize": 167.258725,
    "AvgDaysBetweenOrders": 135
  },
  {
    "id": "5eb822882f564105a255752a",
    "name": "Kelly Barton",
    "gender": "female",
    "totalSales": 9314.997779,
    "firstOrderDate": "2016-12-01",
    "avgOrderSize": 85.611966,
    "AvgDaysBetweenOrders": 294
  },
  {
    "id": "5eb82288a8a2a551dff32d34",
    "name": "Freida Luna",
    "gender": "female",
    "totalSales": 8953.369058,
    "firstOrderDate": "2014-06-06",
    "avgOrderSize": 65.188051,
    "AvgDaysBetweenOrders": 215
  },
  {
    "id": "5eb822883e4d8a42498709e2",
    "name": "Armstrong Fitzpatrick",
    "gender": "male",
    "totalSales": 7299.058302,
    "firstOrderDate": "2014-06-07",
    "avgOrderSize": 62.618287,
    "AvgDaysBetweenOrders": 51
  },
  {
    "id": "5eb82288fcf57f1a21a65e6e",
    "name": "Oliver Bradshaw",
    "gender": "male",
    "totalSales": 5935.718875,
    "firstOrderDate": "2015-11-01",
    "avgOrderSize": 262.199702,
    "AvgDaysBetweenOrders": 7
  },
  {
    "id": "5eb82288aba47025d5f894ee",
    "name": "Blackburn Figueroa",
    "gender": "male",
    "totalSales": 1631.989748,
    "firstOrderDate": "2018-10-10",
    "avgOrderSize": 131.2388,
    "AvgDaysBetweenOrders": 246
  },
  {
    "id": "5eb822889bbc572e14e09376",
    "name": "Tracie Keith",
    "gender": "female",
    "totalSales": 2031.832472,
    "firstOrderDate": "2017-05-19",
    "avgOrderSize": 189.213135,
    "AvgDaysBetweenOrders": 1
  },
  {
    "id": "5eb822887863cebca74192bb",
    "name": "Montoya Ochoa",
    "gender": "male",
    "totalSales": 9717.597467,
    "firstOrderDate": "2019-06-08",
    "avgOrderSize": 242.028143,
    "AvgDaysBetweenOrders": 262
  },
  {
    "id": "5eb82288d3f3549558f594a6",
    "name": "Lester Atkins",
    "gender": "male",
    "totalSales": 5298.475814,
    "firstOrderDate": "2016-07-28",
    "avgOrderSize": 243.165895,
    "AvgDaysBetweenOrders": 234
  },
  {
    "id": "5eb822882b9e9dcc14e417c3",
    "name": "Kline Estes",
    "gender": "male",
    "totalSales": 4264.664507,
    "firstOrderDate": "2015-11-24",
    "avgOrderSize": 266.198296,
    "AvgDaysBetweenOrders": 137
  },
  {
    "id": "5eb822887a865eac09896eb0",
    "name": "Georgina Murray",
    "gender": "female",
    "totalSales": 8678.642945,
    "firstOrderDate": "2015-05-18",
    "avgOrderSize": 211.339038,
    "AvgDaysBetweenOrders": 287
  },
  {
    "id": "5eb82288ed666f9282244452",
    "name": "Christi Evans",
    "gender": "female",
    "totalSales": 2012.164088,
    "firstOrderDate": "2015-02-04",
    "avgOrderSize": 235.060996,
    "AvgDaysBetweenOrders": 18
  },
  {
    "id": "5eb82288af0cc4f30657abdb",
    "name": "Gentry Mitchell",
    "gender": "male",
    "totalSales": 2312.816579,
    "firstOrderDate": "2019-05-13",
    "avgOrderSize": 224.806891,
    "AvgDaysBetweenOrders": 122
  },
  {
    "id": "5eb82288edf914c9b328217f",
    "name": "Carissa Copeland",
    "gender": "female",
    "totalSales": 8282.400562,
    "firstOrderDate": "2015-06-03",
    "avgOrderSize": 291.242555,
    "AvgDaysBetweenOrders": 109
  },
  {
    "id": "5eb822888b37da7092293202",
    "name": "Brock Webster",
    "gender": "male",
    "totalSales": 3321.284184,
    "firstOrderDate": "2016-03-03",
    "avgOrderSize": 17.669933,
    "AvgDaysBetweenOrders": 34
  },
  {
    "id": "5eb82288b94b56070d66db35",
    "name": "Day Reilly",
    "gender": "male",
    "totalSales": 5226.952849,
    "firstOrderDate": "2018-08-02",
    "avgOrderSize": 297.768455,
    "AvgDaysBetweenOrders": 216
  },
  {
    "id": "5eb822889ab83441a34e2640",
    "name": "Stacie Thornton",
    "gender": "female",
    "totalSales": 3614.488776,
    "firstOrderDate": "2017-12-23",
    "avgOrderSize": 145.291459,
    "AvgDaysBetweenOrders": 263
  },
  {
    "id": "5eb822883a51d98f24b414c3",
    "name": "Anderson Little",
    "gender": "male",
    "totalSales": 1224.841042,
    "firstOrderDate": "2017-12-13",
    "avgOrderSize": 296.227965,
    "AvgDaysBetweenOrders": 177
  },
  {
    "id": "5eb822884cc058ad5b87284f",
    "name": "Sonia Mcfarland",
    "gender": "female",
    "totalSales": 3578.008,
    "firstOrderDate": "2014-02-15",
    "avgOrderSize": 216.885875,
    "AvgDaysBetweenOrders": 270
  },
  {
    "id": "5eb82288565f9e8bf63ede64",
    "name": "Faye Britt",
    "gender": "female",
    "totalSales": 4881.684416,
    "firstOrderDate": "2019-09-27",
    "avgOrderSize": 12.766287,
    "AvgDaysBetweenOrders": 298
  },
  {
    "id": "5eb82288ad15e272a85ac509",
    "name": "Bettie Robinson",
    "gender": "female",
    "totalSales": 2251.87516,
    "firstOrderDate": "2016-02-04",
    "avgOrderSize": 213.957326,
    "AvgDaysBetweenOrders": 246
  },
  {
    "id": "5eb82288e9f6c94dd4799090",
    "name": "Annabelle Morgan",
    "gender": "female",
    "totalSales": 6007.059373,
    "firstOrderDate": "2017-04-09",
    "avgOrderSize": 244.069802,
    "AvgDaysBetweenOrders": 128
  },
  {
    "id": "5eb82288c6acc9ce86cd7645",
    "name": "Juliana Hickman",
    "gender": "female",
    "totalSales": 8716.441893,
    "firstOrderDate": "2017-02-05",
    "avgOrderSize": 152.682176,
    "AvgDaysBetweenOrders": 36
  },
  {
    "id": "5eb82288a9b35ed60869e97d",
    "name": "Hahn Ferrell",
    "gender": "male",
    "totalSales": 3870.262663,
    "firstOrderDate": "2015-01-12",
    "avgOrderSize": 148.792324,
    "AvgDaysBetweenOrders": 137
  },
  {
    "id": "5eb822888e1136da5e732e63",
    "name": "Sarah Wise",
    "gender": "female",
    "totalSales": 9418.397506,
    "firstOrderDate": "2018-11-18",
    "avgOrderSize": 204.079269,
    "AvgDaysBetweenOrders": 149
  },
  {
    "id": "5eb82288e653f49409a80fb7",
    "name": "Mcknight Romero",
    "gender": "male",
    "totalSales": 5135.779949,
    "firstOrderDate": "2016-01-10",
    "avgOrderSize": 105.326775,
    "AvgDaysBetweenOrders": 2
  },
  {
    "id": "5eb822885320f6b7fdb85f98",
    "name": "William Rutledge",
    "gender": "male",
    "totalSales": 8598.534506,
    "firstOrderDate": "2014-05-30",
    "avgOrderSize": 192.235081,
    "AvgDaysBetweenOrders": 255
  },
  {
    "id": "5eb8228801277f139a549a2a",
    "name": "Sanford Mcleod",
    "gender": "male",
    "totalSales": 1937.986019,
    "firstOrderDate": "2019-05-05",
    "avgOrderSize": 275.094444,
    "AvgDaysBetweenOrders": 295
  },
  {
    "id": "5eb82288539b04ccd70560be",
    "name": "Deanna Duran",
    "gender": "female",
    "totalSales": 4340.860288,
    "firstOrderDate": "2019-10-20",
    "avgOrderSize": 278.94514,
    "AvgDaysBetweenOrders": 134
  },
  {
    "id": "5eb82288d3e3d8696ac11dda",
    "name": "Yvette Kirkland",
    "gender": "female",
    "totalSales": 916.597518,
    "firstOrderDate": "2018-10-26",
    "avgOrderSize": 209.706278,
    "AvgDaysBetweenOrders": 281
  },
  {
    "id": "5eb8228894689be6dab6e492",
    "name": "Jimmie Bolton",
    "gender": "female",
    "totalSales": 1006.609917,
    "firstOrderDate": "2016-05-20",
    "avgOrderSize": 222.584324,
    "AvgDaysBetweenOrders": 60
  },
  {
    "id": "5eb82288362d052e85fa80bb",
    "name": "Contreras Harding",
    "gender": "male",
    "totalSales": 3067.082981,
    "firstOrderDate": "2017-09-18",
    "avgOrderSize": 105.358212,
    "AvgDaysBetweenOrders": 105
  },
  {
    "id": "5eb82288484fcb48dee4bb12",
    "name": "Barrera Mccormick",
    "gender": "male",
    "totalSales": 5375.540542,
    "firstOrderDate": "2018-05-23",
    "avgOrderSize": 99.83791,
    "AvgDaysBetweenOrders": 184
  },
  {
    "id": "5eb822883fd701fd83a7a23a",
    "name": "Terra Flowers",
    "gender": "female",
    "totalSales": 5626.456985,
    "firstOrderDate": "2018-04-13",
    "avgOrderSize": 142.29575,
    "AvgDaysBetweenOrders": 73
  },
  {
    "id": "5eb82288bf85a7bbf10ed750",
    "name": "Gail Dejesus",
    "gender": "female",
    "totalSales": 8591.875726,
    "firstOrderDate": "2015-03-16",
    "avgOrderSize": 90.64797,
    "AvgDaysBetweenOrders": 128
  },
  {
    "id": "5eb82288a6695ae6936f65e0",
    "name": "Soto Dean",
    "gender": "male",
    "totalSales": 806.100314,
    "firstOrderDate": "2018-03-27",
    "avgOrderSize": 18.365612,
    "AvgDaysBetweenOrders": 67
  },
  {
    "id": "5eb82288a1203066ae621290",
    "name": "Christian Fox",
    "gender": "male",
    "totalSales": 3415.689563,
    "firstOrderDate": "2017-07-06",
    "avgOrderSize": 264.332372,
    "AvgDaysBetweenOrders": 66
  },
  {
    "id": "5eb82288df29ab3dd3f49852",
    "name": "Moran Andrews",
    "gender": "male",
    "totalSales": 8832.521796,
    "firstOrderDate": "2016-08-07",
    "avgOrderSize": 244.419129,
    "AvgDaysBetweenOrders": 44
  },
  {
    "id": "5eb82288eb5c63681b8757a8",
    "name": "Conway Patton",
    "gender": "male",
    "totalSales": 1086.243674,
    "firstOrderDate": "2019-12-24",
    "avgOrderSize": 280.449677,
    "AvgDaysBetweenOrders": 139
  },
  {
    "id": "5eb82288c5f7c9fc631853bd",
    "name": "Parsons Lott",
    "gender": "male",
    "totalSales": 3062.339082,
    "firstOrderDate": "2017-06-18",
    "avgOrderSize": 201.469655,
    "AvgDaysBetweenOrders": 17
  },
  {
    "id": "5eb82288a21573f1ddfaeb12",
    "name": "Jerry Padilla",
    "gender": "female",
    "totalSales": 1955.797908,
    "firstOrderDate": "2018-02-06",
    "avgOrderSize": 9.220677,
    "AvgDaysBetweenOrders": 118
  },
  {
    "id": "5eb82288febdb9e827925df6",
    "name": "Strong Mckay",
    "gender": "male",
    "totalSales": 5760.391679,
    "firstOrderDate": "2014-05-19",
    "avgOrderSize": 40.555737,
    "AvgDaysBetweenOrders": 45
  },
  {
    "id": "5eb822882d320d66ba23049a",
    "name": "Lula Waller",
    "gender": "female",
    "totalSales": 8336.590092,
    "firstOrderDate": "2015-12-06",
    "avgOrderSize": 252.310651,
    "AvgDaysBetweenOrders": 78
  },
  {
    "id": "5eb82288d5eab2b62b8f16aa",
    "name": "Rosalie Key",
    "gender": "female",
    "totalSales": 2581.692878,
    "firstOrderDate": "2015-04-04",
    "avgOrderSize": 56.582822,
    "AvgDaysBetweenOrders": 5
  },
  {
    "id": "5eb82288f73704aedc313146",
    "name": "Holloway Wilcox",
    "gender": "male",
    "totalSales": 3830.79467,
    "firstOrderDate": "2018-08-23",
    "avgOrderSize": 167.753785,
    "AvgDaysBetweenOrders": 156
  },
  {
    "id": "5eb82288e5c9da2a6f978262",
    "name": "Hartman Whitley",
    "gender": "male",
    "totalSales": 2201.533229,
    "firstOrderDate": "2015-10-29",
    "avgOrderSize": 143.536316,
    "AvgDaysBetweenOrders": 48
  },
  {
    "id": "5eb82288960cc8f598651042",
    "name": "Sondra Marshall",
    "gender": "female",
    "totalSales": 2433.962898,
    "firstOrderDate": "2015-04-17",
    "avgOrderSize": 212.624394,
    "AvgDaysBetweenOrders": 212
  },
  {
    "id": "5eb82288b6babd9aaab724db",
    "name": "Michelle Jefferson",
    "gender": "female",
    "totalSales": 4303.681667,
    "firstOrderDate": "2017-03-20",
    "avgOrderSize": 100.562911,
    "AvgDaysBetweenOrders": 244
  },
  {
    "id": "5eb822889361be816e565b4d",
    "name": "Colon Chapman",
    "gender": "male",
    "totalSales": 619.432117,
    "firstOrderDate": "2018-03-24",
    "avgOrderSize": 202.532461,
    "AvgDaysBetweenOrders": 111
  },
  {
    "id": "5eb822880550e78e46f597ca",
    "name": "Beard Boyle",
    "gender": "male",
    "totalSales": 9000.547705,
    "firstOrderDate": "2016-10-07",
    "avgOrderSize": 260.38264,
    "AvgDaysBetweenOrders": 202
  },
  {
    "id": "5eb8228858d4d302f91fa404",
    "name": "Cherie Mathews",
    "gender": "female",
    "totalSales": 2251.168975,
    "firstOrderDate": "2018-01-14",
    "avgOrderSize": 9.181079,
    "AvgDaysBetweenOrders": 13
  },
  {
    "id": "5eb82288ab2987448ba6d621",
    "name": "Solis Swanson",
    "gender": "male",
    "totalSales": 8678.347127,
    "firstOrderDate": "2014-08-20",
    "avgOrderSize": 2.133458,
    "AvgDaysBetweenOrders": 104
  },
  {
    "id": "5eb82288761a45c78ab35746",
    "name": "Powell Dennis",
    "gender": "male",
    "totalSales": 9988.409269,
    "firstOrderDate": "2015-04-26",
    "avgOrderSize": 6.23575,
    "AvgDaysBetweenOrders": 254
  },
  {
    "id": "5eb822886e047b4f6f17b2c8",
    "name": "Morse Hurst",
    "gender": "male",
    "totalSales": 3801.967371,
    "firstOrderDate": "2018-05-17",
    "avgOrderSize": 165.169591,
    "AvgDaysBetweenOrders": 198
  },
  {
    "id": "5eb82288359d4036aaf39b7e",
    "name": "Fields Bray",
    "gender": "male",
    "totalSales": 9923.612479,
    "firstOrderDate": "2015-12-21",
    "avgOrderSize": 194.491449,
    "AvgDaysBetweenOrders": 149
  },
  {
    "id": "5eb82288c132f628f50187fd",
    "name": "Caldwell Marsh",
    "gender": "male",
    "totalSales": 3455.531024,
    "firstOrderDate": "2019-04-25",
    "avgOrderSize": 52.362562,
    "AvgDaysBetweenOrders": 159
  },
  {
    "id": "5eb822889bbdf41fd1a72070",
    "name": "Elinor Snyder",
    "gender": "female",
    "totalSales": 7362.561306,
    "firstOrderDate": "2018-11-08",
    "avgOrderSize": 283.824537,
    "AvgDaysBetweenOrders": 256
  },
  {
    "id": "5eb8228888e4c572b4e5c9ae",
    "name": "Slater Bruce",
    "gender": "male",
    "totalSales": 3274.466812,
    "firstOrderDate": "2015-09-17",
    "avgOrderSize": 229.040938,
    "AvgDaysBetweenOrders": 133
  },
  {
    "id": "5eb82288a99a238671b7c206",
    "name": "Arlene Sharpe",
    "gender": "female",
    "totalSales": 8989.170271,
    "firstOrderDate": "2018-06-18",
    "avgOrderSize": 80.262017,
    "AvgDaysBetweenOrders": 71
  },
  {
    "id": "5eb822887a8aca5d8c546caa",
    "name": "Diann Ware",
    "gender": "female",
    "totalSales": 7855.899193,
    "firstOrderDate": "2014-11-02",
    "avgOrderSize": 113.686085,
    "AvgDaysBetweenOrders": 72
  },
  {
    "id": "5eb8228826fbe2e648b31ede",
    "name": "Harriett Benjamin",
    "gender": "female",
    "totalSales": 1817.829681,
    "firstOrderDate": "2014-08-24",
    "avgOrderSize": 93.463214,
    "AvgDaysBetweenOrders": 287
  },
  {
    "id": "5eb8228881033b74ecba759e",
    "name": "Curtis Hardin",
    "gender": "male",
    "totalSales": 5397.835769,
    "firstOrderDate": "2016-01-01",
    "avgOrderSize": 233.667877,
    "AvgDaysBetweenOrders": 21
  },
  {
    "id": "5eb822887519e95caadc5561",
    "name": "Nielsen Alexander",
    "gender": "male",
    "totalSales": 7502.639981,
    "firstOrderDate": "2016-10-01",
    "avgOrderSize": 31.220112,
    "AvgDaysBetweenOrders": 195
  },
  {
    "id": "5eb82288d0524aae742383d2",
    "name": "Donna Holt",
    "gender": "female",
    "totalSales": 6664.25193,
    "firstOrderDate": "2016-06-26",
    "avgOrderSize": 43.520577,
    "AvgDaysBetweenOrders": 300
  },
  {
    "id": "5eb822880606ad6908a67a2e",
    "name": "Wiley Randall",
    "gender": "male",
    "totalSales": 1843.80924,
    "firstOrderDate": "2016-05-03",
    "avgOrderSize": 58.043385,
    "AvgDaysBetweenOrders": 268
  },
  {
    "id": "5eb8228855caca428de5a27f",
    "name": "Guthrie Buck",
    "gender": "male",
    "totalSales": 4766.130662,
    "firstOrderDate": "2018-08-29",
    "avgOrderSize": 36.364092,
    "AvgDaysBetweenOrders": 54
  },
  {
    "id": "5eb822883bd7a8cbb9d40296",
    "name": "Stafford Hamilton",
    "gender": "male",
    "totalSales": 2280.102006,
    "firstOrderDate": "2017-03-03",
    "avgOrderSize": 163.133325,
    "AvgDaysBetweenOrders": 52
  },
  {
    "id": "5eb82288bf3c450de7d09889",
    "name": "Bethany Morris",
    "gender": "female",
    "totalSales": 1785.465384,
    "firstOrderDate": "2016-01-09",
    "avgOrderSize": 207.573595,
    "AvgDaysBetweenOrders": 248
  },
  {
    "id": "5eb82288545ac431a6a0e4b1",
    "name": "Angie Reyes",
    "gender": "female",
    "totalSales": 8945.922007,
    "firstOrderDate": "2017-03-04",
    "avgOrderSize": 19.764367,
    "AvgDaysBetweenOrders": 203
  },
  {
    "id": "5eb822888bb65808cb1158c2",
    "name": "Rosalinda Parks",
    "gender": "female",
    "totalSales": 108.343306,
    "firstOrderDate": "2018-10-20",
    "avgOrderSize": 20.536631,
    "AvgDaysBetweenOrders": 233
  },
  {
    "id": "5eb822887c0336669b44f01b",
    "name": "Harrison Hood",
    "gender": "male",
    "totalSales": 7393.713683,
    "firstOrderDate": "2015-12-09",
    "avgOrderSize": 293.790249,
    "AvgDaysBetweenOrders": 208
  },
  {
    "id": "5eb822884e2bdd38eabaf731",
    "name": "Klein Murphy",
    "gender": "male",
    "totalSales": 9086.115263,
    "firstOrderDate": "2017-08-03",
    "avgOrderSize": 180.752055,
    "AvgDaysBetweenOrders": 65
  },
  {
    "id": "5eb8228894d2bbd449bd1995",
    "name": "Peterson Alford",
    "gender": "male",
    "totalSales": 2763.224741,
    "firstOrderDate": "2014-05-20",
    "avgOrderSize": 81.260713,
    "AvgDaysBetweenOrders": 66
  },
  {
    "id": "5eb822885a7e7bc04a195e69",
    "name": "Helena Ratliff",
    "gender": "female",
    "totalSales": 6214.984177,
    "firstOrderDate": "2014-06-02",
    "avgOrderSize": 33.720005,
    "AvgDaysBetweenOrders": 199
  },
  {
    "id": "5eb82288c15e1fe353eb8dc1",
    "name": "Boyd Pacheco",
    "gender": "male",
    "totalSales": 8184.99603,
    "firstOrderDate": "2017-12-07",
    "avgOrderSize": 263.158431,
    "AvgDaysBetweenOrders": 32
  },
  {
    "id": "5eb8228897ae5e0c79f4a0f4",
    "name": "Mcneil Ballard",
    "gender": "male",
    "totalSales": 7426.010403,
    "firstOrderDate": "2017-05-09",
    "avgOrderSize": 232.340649,
    "AvgDaysBetweenOrders": 115
  },
  {
    "id": "5eb8228818ab861168fe6175",
    "name": "Mcdowell Herring",
    "gender": "male",
    "totalSales": 7401.771944,
    "firstOrderDate": "2014-05-27",
    "avgOrderSize": 114.686431,
    "AvgDaysBetweenOrders": 60
  },
  {
    "id": "5eb82288671cb7fedeac2b1c",
    "name": "Roberts Park",
    "gender": "male",
    "totalSales": 357.128749,
    "firstOrderDate": "2017-11-10",
    "avgOrderSize": 220.258302,
    "AvgDaysBetweenOrders": 165
  },
  {
    "id": "5eb82288d6df6e5932d89380",
    "name": "Dawn Griffin",
    "gender": "female",
    "totalSales": 4311.319106,
    "firstOrderDate": "2016-05-19",
    "avgOrderSize": 15.954664,
    "AvgDaysBetweenOrders": 292
  },
  {
    "id": "5eb822883031d8e097d73858",
    "name": "Autumn Monroe",
    "gender": "female",
    "totalSales": 8863.727604,
    "firstOrderDate": "2014-06-20",
    "avgOrderSize": 20.137568,
    "AvgDaysBetweenOrders": 160
  },
  {
    "id": "5eb82288bd43c427ef9e952e",
    "name": "Ophelia Montoya",
    "gender": "female",
    "totalSales": 3142.720339,
    "firstOrderDate": "2017-01-01",
    "avgOrderSize": 124.436214,
    "AvgDaysBetweenOrders": 20
  },
  {
    "id": "5eb822885c796b6ccc5f8d34",
    "name": "Patricia Holman",
    "gender": "female",
    "totalSales": 2249.217641,
    "firstOrderDate": "2018-11-30",
    "avgOrderSize": 162.670944,
    "AvgDaysBetweenOrders": 233
  },
  {
    "id": "5eb82288746357a8f7c4217d",
    "name": "Anastasia Harmon",
    "gender": "female",
    "totalSales": 8804.550687,
    "firstOrderDate": "2020-03-04",
    "avgOrderSize": 54.883509,
    "AvgDaysBetweenOrders": 73
  },
  {
    "id": "5eb82288b2b5a766ea91f366",
    "name": "Fannie Miller",
    "gender": "female",
    "totalSales": 3633.779983,
    "firstOrderDate": "2014-02-26",
    "avgOrderSize": 298.671088,
    "AvgDaysBetweenOrders": 82
  },
  {
    "id": "5eb82288a017dc2ef582e815",
    "name": "Maureen Dudley",
    "gender": "female",
    "totalSales": 4765.136692,
    "firstOrderDate": "2015-08-19",
    "avgOrderSize": 255.872962,
    "AvgDaysBetweenOrders": 99
  },
  {
    "id": "5eb82288f15f82ac4b8ad450",
    "name": "Joy Robertson",
    "gender": "female",
    "totalSales": 1320.021828,
    "firstOrderDate": "2019-09-04",
    "avgOrderSize": 114.576466,
    "AvgDaysBetweenOrders": 40
  },
  {
    "id": "5eb82288142532fa3b7c05a3",
    "name": "Merle Hunter",
    "gender": "female",
    "totalSales": 6389.034529,
    "firstOrderDate": "2016-04-25",
    "avgOrderSize": 58.423366,
    "AvgDaysBetweenOrders": 194
  },
  {
    "id": "5eb82288ecfae0f3cbc1e886",
    "name": "Guadalupe Whitney",
    "gender": "female",
    "totalSales": 2438.236149,
    "firstOrderDate": "2015-10-02",
    "avgOrderSize": 96.742719,
    "AvgDaysBetweenOrders": 104
  },
  {
    "id": "5eb82288a7e496e8e25b2d5c",
    "name": "Barnes Mcintosh",
    "gender": "male",
    "totalSales": 8421.927858,
    "firstOrderDate": "2015-09-05",
    "avgOrderSize": 203.957818,
    "AvgDaysBetweenOrders": 160
  },
  {
    "id": "5eb82288f50777542af5c98b",
    "name": "Cameron Prince",
    "gender": "male",
    "totalSales": 9474.274693,
    "firstOrderDate": "2016-05-19",
    "avgOrderSize": 84.837898,
    "AvgDaysBetweenOrders": 259
  },
  {
    "id": "5eb82288f3adad7aaddb9c98",
    "name": "Dillon Cleveland",
    "gender": "male",
    "totalSales": 8421.9789,
    "firstOrderDate": "2018-02-27",
    "avgOrderSize": 177.737108,
    "AvgDaysBetweenOrders": 211
  },
  {
    "id": "5eb8228886e1533913afb472",
    "name": "Wall Knapp",
    "gender": "male",
    "totalSales": 2849.031277,
    "firstOrderDate": "2018-01-16",
    "avgOrderSize": 205.7935,
    "AvgDaysBetweenOrders": 86
  },
  {
    "id": "5eb82288b7f00bdd1f30a585",
    "name": "Hancock Dyer",
    "gender": "male",
    "totalSales": 4466.028082,
    "firstOrderDate": "2018-11-26",
    "avgOrderSize": 228.644854,
    "AvgDaysBetweenOrders": 246
  },
  {
    "id": "5eb8228816c7bda34f49df01",
    "name": "Bird Cunningham",
    "gender": "male",
    "totalSales": 4366.818136,
    "firstOrderDate": "2019-03-28",
    "avgOrderSize": 147.675702,
    "AvgDaysBetweenOrders": 264
  },
  {
    "id": "5eb822886f4dd437fa3004dd",
    "name": "Imogene Odom",
    "gender": "female",
    "totalSales": 303.203305,
    "firstOrderDate": "2015-08-07",
    "avgOrderSize": 65.90223,
    "AvgDaysBetweenOrders": 261
  },
  {
    "id": "5eb822884126d715ae7c3e79",
    "name": "Holman William",
    "gender": "male",
    "totalSales": 6934.792739,
    "firstOrderDate": "2016-07-19",
    "avgOrderSize": 28.974889,
    "AvgDaysBetweenOrders": 91
  },
  {
    "id": "5eb82288ad0d587f53899574",
    "name": "Rodriguez Witt",
    "gender": "male",
    "totalSales": 6942.26611,
    "firstOrderDate": "2020-04-26",
    "avgOrderSize": 275.677523,
    "AvgDaysBetweenOrders": 190
  },
  {
    "id": "5eb82288763ac314306449d3",
    "name": "Herminia Oneil",
    "gender": "female",
    "totalSales": 7253.696909,
    "firstOrderDate": "2018-09-04",
    "avgOrderSize": 123.272742,
    "AvgDaysBetweenOrders": 217
  },
  {
    "id": "5eb82288ab0961818c061cb9",
    "name": "Tiffany Weber",
    "gender": "female",
    "totalSales": 6976.050216,
    "firstOrderDate": "2019-08-17",
    "avgOrderSize": 191.112576,
    "AvgDaysBetweenOrders": 48
  },
  {
    "id": "5eb822881973371f879cc956",
    "name": "Wyatt Mccray",
    "gender": "male",
    "totalSales": 2626.86195,
    "firstOrderDate": "2014-05-11",
    "avgOrderSize": 77.320458,
    "AvgDaysBetweenOrders": 185
  },
  {
    "id": "5eb82288ebf8003f59eb7b13",
    "name": "Pickett Koch",
    "gender": "male",
    "totalSales": 906.274946,
    "firstOrderDate": "2017-01-31",
    "avgOrderSize": 252.221878,
    "AvgDaysBetweenOrders": 236
  },
  {
    "id": "5eb822880e6d523bd81f13cb",
    "name": "Reid Hanson",
    "gender": "male",
    "totalSales": 3833.437401,
    "firstOrderDate": "2019-05-26",
    "avgOrderSize": 16.600222,
    "AvgDaysBetweenOrders": 288
  },
  {
    "id": "5eb822884c3c1d3d4c0c99b7",
    "name": "Leonard Nunez",
    "gender": "male",
    "totalSales": 1323.31839,
    "firstOrderDate": "2017-07-29",
    "avgOrderSize": 272.282958,
    "AvgDaysBetweenOrders": 71
  },
  {
    "id": "5eb822888e181d86cf09b659",
    "name": "Rodriquez Klein",
    "gender": "male",
    "totalSales": 4809.225553,
    "firstOrderDate": "2017-05-10",
    "avgOrderSize": 229.063231,
    "AvgDaysBetweenOrders": 205
  },
  {
    "id": "5eb82288441feeabf407d116",
    "name": "Mccoy Rodriquez",
    "gender": "male",
    "totalSales": 8416.987983,
    "firstOrderDate": "2018-11-14",
    "avgOrderSize": 157.285311,
    "AvgDaysBetweenOrders": 179
  },
  {
    "id": "5eb82288bb2b807714f2f39c",
    "name": "Hickman Maxwell",
    "gender": "male",
    "totalSales": 8088.297956,
    "firstOrderDate": "2018-01-07",
    "avgOrderSize": 294.157611,
    "AvgDaysBetweenOrders": 50
  },
  {
    "id": "5eb8228898fbc4c42cb490c6",
    "name": "Cindy Vaughn",
    "gender": "female",
    "totalSales": 3747.03485,
    "firstOrderDate": "2019-06-23",
    "avgOrderSize": 47.308552,
    "AvgDaysBetweenOrders": 93
  },
  {
    "id": "5eb82288f109368a406450db",
    "name": "Holden Mays",
    "gender": "male",
    "totalSales": 5544.294586,
    "firstOrderDate": "2019-08-09",
    "avgOrderSize": 220.005613,
    "AvgDaysBetweenOrders": 169
  },
  {
    "id": "5eb82288f7c7ed3a6bffef99",
    "name": "Wade Oneill",
    "gender": "male",
    "totalSales": 1594.212832,
    "firstOrderDate": "2014-05-07",
    "avgOrderSize": 17.792373,
    "AvgDaysBetweenOrders": 218
  },
  {
    "id": "5eb822884adf32fd0cd31108",
    "name": "Webb Dalton",
    "gender": "male",
    "totalSales": 2548.095316,
    "firstOrderDate": "2018-07-30",
    "avgOrderSize": 100.465745,
    "AvgDaysBetweenOrders": 233
  },
  {
    "id": "5eb8228821b52df2734ea957",
    "name": "Bradley Pittman",
    "gender": "male",
    "totalSales": 7935.778602,
    "firstOrderDate": "2017-05-27",
    "avgOrderSize": 226.36356,
    "AvgDaysBetweenOrders": 64
  },
  {
    "id": "5eb82288f4da09e10652b976",
    "name": "Carole Huffman",
    "gender": "female",
    "totalSales": 4023.098614,
    "firstOrderDate": "2018-02-13",
    "avgOrderSize": 138.07913,
    "AvgDaysBetweenOrders": 29
  },
  {
    "id": "5eb8228800cb0995e5f4db24",
    "name": "Zamora Lucas",
    "gender": "male",
    "totalSales": 4826.196025,
    "firstOrderDate": "2017-08-18",
    "avgOrderSize": 24.885866,
    "AvgDaysBetweenOrders": 291
  },
  {
    "id": "5eb82288d257846de67179f4",
    "name": "Cannon Petersen",
    "gender": "male",
    "totalSales": 4127.964108,
    "firstOrderDate": "2014-08-26",
    "avgOrderSize": 36.278033,
    "AvgDaysBetweenOrders": 20
  },
  {
    "id": "5eb82288782ef34245030d52",
    "name": "Dianna Solomon",
    "gender": "female",
    "totalSales": 6485.104179,
    "firstOrderDate": "2017-06-26",
    "avgOrderSize": 227.5134,
    "AvgDaysBetweenOrders": 36
  },
  {
    "id": "5eb82288eba7e1d6e4a768b7",
    "name": "Paul Robbins",
    "gender": "male",
    "totalSales": 5292.941467,
    "firstOrderDate": "2019-11-22",
    "avgOrderSize": 132.073059,
    "AvgDaysBetweenOrders": 202
  },
  {
    "id": "5eb8228850106ee084d1b2fd",
    "name": "Peters Roberts",
    "gender": "male",
    "totalSales": 7749.577429,
    "firstOrderDate": "2018-11-01",
    "avgOrderSize": 201.142135,
    "AvgDaysBetweenOrders": 60
  },
  {
    "id": "5eb822881c30a0af18b4cd16",
    "name": "Deborah Hubbard",
    "gender": "female",
    "totalSales": 1984.298982,
    "firstOrderDate": "2014-05-06",
    "avgOrderSize": 254.377038,
    "AvgDaysBetweenOrders": 49
  },
  {
    "id": "5eb82288167ba13ecfb4a950",
    "name": "Sanders Acevedo",
    "gender": "male",
    "totalSales": 1462.03595,
    "firstOrderDate": "2019-11-03",
    "avgOrderSize": 30.143752,
    "AvgDaysBetweenOrders": 4
  },
  {
    "id": "5eb8228817e783126a2a5de7",
    "name": "Lacey Martinez",
    "gender": "female",
    "totalSales": 8181.059861,
    "firstOrderDate": "2017-02-03",
    "avgOrderSize": 211.313294,
    "AvgDaysBetweenOrders": 52
  },
  {
    "id": "5eb82288e5adeeeae01e00d3",
    "name": "Karina Warren",
    "gender": "female",
    "totalSales": 1473.26037,
    "firstOrderDate": "2014-05-07",
    "avgOrderSize": 169.750802,
    "AvgDaysBetweenOrders": 1
  },
  {
    "id": "5eb82288e6d5973d62aeee76",
    "name": "Edna Tanner",
    "gender": "female",
    "totalSales": 9446.267992,
    "firstOrderDate": "2016-09-21",
    "avgOrderSize": 86.2574,
    "AvgDaysBetweenOrders": 295
  },
  {
    "id": "5eb822882aedcd10e85750f3",
    "name": "Leola Warner",
    "gender": "female",
    "totalSales": 7674.103725,
    "firstOrderDate": "2019-02-05",
    "avgOrderSize": 232.011402,
    "AvgDaysBetweenOrders": 122
  },
  {
    "id": "5eb822880335b5fbadfa5a59",
    "name": "Dyer Good",
    "gender": "male",
    "totalSales": 8409.556351,
    "firstOrderDate": "2019-03-16",
    "avgOrderSize": 186.38234,
    "AvgDaysBetweenOrders": 18
  },
  {
    "id": "5eb822889660436c568ca41b",
    "name": "Blanca Ray",
    "gender": "female",
    "totalSales": 5738.278303,
    "firstOrderDate": "2015-07-24",
    "avgOrderSize": 201.483945,
    "AvgDaysBetweenOrders": 193
  },
  {
    "id": "5eb8228801ca777d0c1e6f2b",
    "name": "Adele Potts",
    "gender": "female",
    "totalSales": 7004.575305,
    "firstOrderDate": "2016-02-29",
    "avgOrderSize": 166.44909,
    "AvgDaysBetweenOrders": 206
  },
  {
    "id": "5eb82288977e62f5acaa568b",
    "name": "Leona Sullivan",
    "gender": "female",
    "totalSales": 3770.329256,
    "firstOrderDate": "2014-07-12",
    "avgOrderSize": 115.439412,
    "AvgDaysBetweenOrders": 73
  },
  {
    "id": "5eb8228810ba395aeb79fb35",
    "name": "Tara Shelton",
    "gender": "female",
    "totalSales": 7748.432797,
    "firstOrderDate": "2015-12-23",
    "avgOrderSize": 198.42428,
    "AvgDaysBetweenOrders": 154
  },
  {
    "id": "5eb82288f60110bd0c4db4a4",
    "name": "Mosley Santana",
    "gender": "male",
    "totalSales": 2742.815681,
    "firstOrderDate": "2018-01-19",
    "avgOrderSize": 3.1153,
    "AvgDaysBetweenOrders": 173
  },
  {
    "id": "5eb822885fe7393b6a700890",
    "name": "Bridget Guthrie",
    "gender": "female",
    "totalSales": 9436.175826,
    "firstOrderDate": "2016-09-07",
    "avgOrderSize": 243.853714,
    "AvgDaysBetweenOrders": 25
  },
  {
    "id": "5eb822881dcd4ec0f5d1e2a3",
    "name": "Esther Clark",
    "gender": "female",
    "totalSales": 6667.144872,
    "firstOrderDate": "2014-12-25",
    "avgOrderSize": 236.018826,
    "AvgDaysBetweenOrders": 289
  },
  {
    "id": "5eb82288d22582423ce6199e",
    "name": "Adrian Pate",
    "gender": "female",
    "totalSales": 1164.187352,
    "firstOrderDate": "2018-02-26",
    "avgOrderSize": 95.109557,
    "AvgDaysBetweenOrders": 288
  },
  {
    "id": "5eb822882f414f661886301c",
    "name": "Lessie Page",
    "gender": "female",
    "totalSales": 5039.50431,
    "firstOrderDate": "2015-08-22",
    "avgOrderSize": 14.213317,
    "AvgDaysBetweenOrders": 101
  },
  {
    "id": "5eb82288cb3058dd34389501",
    "name": "Deleon Sargent",
    "gender": "male",
    "totalSales": 6725.763296,
    "firstOrderDate": "2015-10-17",
    "avgOrderSize": 1.115446,
    "AvgDaysBetweenOrders": 120
  },
  {
    "id": "5eb8228862d317977fe4f331",
    "name": "Newman Terry",
    "gender": "male",
    "totalSales": 4195.431215,
    "firstOrderDate": "2020-04-04",
    "avgOrderSize": 279.454489,
    "AvgDaysBetweenOrders": 300
  },
  {
    "id": "5eb82288ad17a181feec7d74",
    "name": "Deana Taylor",
    "gender": "female",
    "totalSales": 7064.602285,
    "firstOrderDate": "2015-03-17",
    "avgOrderSize": 77.818781,
    "AvgDaysBetweenOrders": 74
  },
  {
    "id": "5eb82288699af244cfcc8127",
    "name": "Gamble Wheeler",
    "gender": "male",
    "totalSales": 6135.17086,
    "firstOrderDate": "2018-06-19",
    "avgOrderSize": 30.6771,
    "AvgDaysBetweenOrders": 7
  },
  {
    "id": "5eb822880662afc15b82dc7c",
    "name": "Berger Clemons",
    "gender": "male",
    "totalSales": 8642.934998,
    "firstOrderDate": "2019-09-20",
    "avgOrderSize": 133.190563,
    "AvgDaysBetweenOrders": 202
  },
  {
    "id": "5eb82288561646f4b29a9a1d",
    "name": "Hewitt Butler",
    "gender": "male",
    "totalSales": 9683.700243,
    "firstOrderDate": "2018-04-14",
    "avgOrderSize": 63.376811,
    "AvgDaysBetweenOrders": 206
  },
  {
    "id": "5eb82288f7f396591f2e1c60",
    "name": "Davidson Carpenter",
    "gender": "male",
    "totalSales": 4477.766549,
    "firstOrderDate": "2019-09-20",
    "avgOrderSize": 125.460066,
    "AvgDaysBetweenOrders": 162
  },
  {
    "id": "5eb82288628191a3833209f7",
    "name": "Elma Mcdowell",
    "gender": "female",
    "totalSales": 5010.844856,
    "firstOrderDate": "2017-03-24",
    "avgOrderSize": 237.614928,
    "AvgDaysBetweenOrders": 24
  },
  {
    "id": "5eb82288495ebf6e6babf41b",
    "name": "Adkins Reese",
    "gender": "male",
    "totalSales": 7985.018067,
    "firstOrderDate": "2014-05-11",
    "avgOrderSize": 242.178991,
    "AvgDaysBetweenOrders": 266
  },
  {
    "id": "5eb82288095ec674de757729",
    "name": "Manning Baldwin",
    "gender": "male",
    "totalSales": 9141.087263,
    "firstOrderDate": "2014-01-17",
    "avgOrderSize": 157.402787,
    "AvgDaysBetweenOrders": 268
  },
  {
    "id": "5eb82288aa0f0029670dd771",
    "name": "David Mcmahon",
    "gender": "male",
    "totalSales": 1033.879342,
    "firstOrderDate": "2019-04-07",
    "avgOrderSize": 231.720702,
    "AvgDaysBetweenOrders": 255
  },
  {
    "id": "5eb822880ada5f11c46b2ee9",
    "name": "Villarreal Compton",
    "gender": "male",
    "totalSales": 3154.05295,
    "firstOrderDate": "2019-11-30",
    "avgOrderSize": 212.26027,
    "AvgDaysBetweenOrders": 276
  },
  {
    "id": "5eb82288e2672ffa3d9b034f",
    "name": "Meagan Levy",
    "gender": "female",
    "totalSales": 4669.062096,
    "firstOrderDate": "2018-03-21",
    "avgOrderSize": 89.725369,
    "AvgDaysBetweenOrders": 265
  },
  {
    "id": "5eb82288d96b94be383266bf",
    "name": "Lynette Barrett",
    "gender": "female",
    "totalSales": 957.468215,
    "firstOrderDate": "2014-08-23",
    "avgOrderSize": 216.519453,
    "AvgDaysBetweenOrders": 144
  },
  {
    "id": "5eb822885216a70e2e61ab79",
    "name": "Bishop Parsons",
    "gender": "male",
    "totalSales": 7562.310754,
    "firstOrderDate": "2017-01-25",
    "avgOrderSize": 219.636351,
    "AvgDaysBetweenOrders": 139
  },
  {
    "id": "5eb8228801e0cc8e44f28857",
    "name": "Debra Hunt",
    "gender": "female",
    "totalSales": 6095.155926,
    "firstOrderDate": "2018-05-19",
    "avgOrderSize": 141.222595,
    "AvgDaysBetweenOrders": 219
  }
]
export default data;