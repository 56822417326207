import { register_user, load_user, set_user_checking, set_user_checked, set_user_profile } from "./constant";

export const registerUser = val => ({
  type: register_user,
  payload: val
});

export const setUser = val => ({
  type: load_user,
  payload: val
});

export const setUserChecking = val => ({
  type: set_user_checking,
  payload: val
});

export const setUserProfile = val => ({
  type: set_user_profile,
  payload: val
});

export const setUserChecked = val => ({
  type: set_user_checked,
  payload: val
});