import React from 'react';
import { Link } from 'react-router-dom'


export default function NotFound(props) {

  return (
    <div className="page-body">
      <h1>Page Not Found</h1>
      <p>The page you were looking for was not found</p>
      <br/><br/>
      <Link to='/'>Home</Link>
    </div>)
}