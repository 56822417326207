import React, { useRef } from 'react';

export const Icon = ({ type, active, size, onClick, id }) => {

  
  const svgRef = useRef(null);

  if (!size) size=24

  let icon = null
  switch(type) {
    case 'barChart':
      icon = 
        <>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="1" x2="1" y2="22" />
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="22" x2="22" y2="22"/>
          <line style={{ strokeWidth: '3px'}} x1="5" y1="5" x2="5" y2="20"/>
          <line style={{ strokeWidth: '3px'}} x1="10" y1="10" x2="10" y2="20"/>
          <line style={{ strokeWidth: '3px'}} x1="15" y1="1" x2="15" y2="20"/>
          <line style={{ strokeWidth: '3px'}} x1="20" y1="14" x2="20" y2="20"/>
        </>
      break;
    case 'lineChart':
      icon = 
        <>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="1" x2="1" y2="22"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="22" x2="22" y2="22"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="3" y1="20" x2="10" y2="12"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="10" y1="12" x2="17" y2="15"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="17" y1="15" x2="23" y2="8"/>
        </>
      break;
    case 'list':
      icon = 
        <>
          <rect x="1"  y="1" width="22" height="22" rx="0" style={{fill:'none'}} />
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="5" y1="6" x2="19" y2="6"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="5" y1="12" x2="19" y2="12"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="5" y1="18" x2="19" y2="18"/>
        </>
      break;
    case 'left':
      icon = 
        <>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="12" x2="8" y2="5"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="12" x2="21" y2="12"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="12" x2="8" y2="19"/>
        </>
      break;
    case 'person':
      icon = 
        <>
          <circle cx="12" cy="7" r="5" style={{strokeLinecap: 'round', strokeWidth: '1px', fill:'none'}} />
          <circle cx="12" cy="23" r="11" style={{strokeLinecap: 'round', strokeWidth: '1px', fill:'none'}} />
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="23.5" x2="22" y2="23.5"/>
        </>
      break;
    case 'play':
      icon = 
        <>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="2" y1="2" x2="22" y2="12"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="22" y1="12" x2="2" y2="22"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="2" y1="22" x2="2" y2="2"/>
        </>
      break;
    case 'pause':
      icon = 
        <>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="6" y1="2" x2="10" y2="2"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="10" y1="2" x2="10" y2="22"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="10" y1="22" x2="6" y2="22"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="6" y1="22" x2="6" y2="2"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="14" y1="2" x2="18" y2="2"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="18" y1="2" x2="18" y2="22"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="18" y1="22" x2="14" y2="22"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="14" y1="22" x2="14" y2="2"/>
        </>
      break;
    case 'compare':
      icon = 
        <>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="23" y1="9" x2="15" y2="9"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="15" y1="9" x2="19" y2="1"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="19" y1="1" x2="23" y2="9"/>
          <path className="fill" style={{strokeLinecap: 'round', strokeWidth: '1px'}} d="M 23 9 Q 19 13 15 9"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="13" x2="9" y2="13"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="1" y1="13" x2="9" y2="13"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="5" y1="5" x2="1" y2="13"/>
          <path className="fill" style={{strokeLinecap: 'round', strokeWidth: '1px'}} d="M 1 13 Q 5 17 9 13"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="5" y1="5" x2="9" y2="13"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="5" y1="5" x2="19" y2="1"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="12" y1="2" x2="12" y2="22"/>
          <line style={{strokeLinecap: 'round', strokeWidth: '1px'}} x1="5" y1="22" x2="19" y2="22"/>
        </>
      break;
    default:
      icon = null
  }


  const click = (e) => {
    onClick(type,svgRef.current.id)
  }


  return (
    <svg ref={svgRef} id={id} className={"icon pointer" + (active?' active':'')} onClick={click}  width={size} height={size} viewBox="0 0 24 24">
      {icon}
    </svg>
  );
}
