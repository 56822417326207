// import rootReducer from "./combineReducers.js";
import { createStore, applyMiddleware, compose } from "redux";
// import { createLogger } from "redux-logger";
import { combineReducers } from 'redux'
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { connectRouter } from "connected-react-router";
import { remote } from './utils/remote'
// import { init as remoteInit, remote } from "./modules/Remote/remote";

const initialState = {
  history: createBrowserHistory(),
  remote: remote,
  devToolFunc: f => f,
  middleware: []
};

const configureStore = (reducers) => {
  const history = createBrowserHistory(); 
  // const history = createBrowserHistory(
  //   { basename: config.basename ? `/${config.basename}` : "" }
  //   );

//   remoteInit(config.baseURL, config.x_cc_app || "");

  initialState.middleware.push(thunk.withExtraArgument({ history, remote }));
  initialState.middleware.push(routerMiddleware(history));

//   // Development Options for Redux.
//   if (process.env.NODE_ENV !== "production") {
//     initialState.middleware.push(
//       createLogger({
//         collapsed: true,
//         diff: false
//       })
//     );

//     if ("function" === typeof window.__REDUX_DEVTOOLS_EXTENSION__) {
//       initialState.devToolFunc = window.__REDUX_DEVTOOLS_EXTENSION__();
//     }
//   }

  const store = createStore(
    combineReducers({
      ...reducers,
      // remote: remoteReducer,
      // config: configReducer,
      // security: securityReducer,
      // header: headerReducer,
      router: connectRouter(history)
    }),
    {},
    compose(
      applyMiddleware(...initialState.middleware),
      initialState.devToolFunc
    )
  );

  return { history, store };
};

export default configureStore;
