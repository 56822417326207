import React, { useRef, useEffect, useState } from 'react';
import { select, scaleBand, scaleLinear, axisLeft, axisBottom, format, max, line } from 'd3';
import { useResizeObserver } from '../hooks/useResizeObserver';
import { touch2Mouse, findAncestor, dollarFormat, months, roundNumber, getMonth } from '../utils/utils'
import PropTypes from 'prop-types';
import '../css/SplitDiv.css'; 


export const SplitDiv = ({value, pct, onChange}) => {

  const wrapperRef = useRef(null);
  const dimensions = useResizeObserver(wrapperRef);

  const [dragging,_setDragging] = useState(false)
  const draggingRef = React.useRef(dragging);
  const setDragging = data => {
    draggingRef.current = data;
    _setDragging(data);
  };


  useEffect(()=> {
    window.addEventListener("mouseup", (e)=>{setDragging(false)})
    window.addEventListener('touchout', touch2Mouse,true)
    return () => {
      window.removeEventListener("mouseup", (e)=>{setDragging(false)})
      window.removeEventListener('touchout', touch2Mouse,true)
    }
  },[])
  
  useEffect(
    () => {
      
        const width = (dimensions || wrapperRef.current.getBoundingClientRect()).width
        let xPosition = width*pct/100
        let value1 = value*pct/100
        let value2 = value * ((100-pct)/100)

        const handleMouseMove = (e) => {
          console.log('move')
          let x = e.clientX-wrapperRef.current.getBoundingClientRect().x
          let p = Math.round(x/width*100)
          if (draggingRef.current && p!==pct) onChange(p)
        }

        const handleMouseDown = (e) => {
          console.log('down')
          let x = e.clientX-wrapperRef.current.getBoundingClientRect().x
          if (x>xPosition-5 && x<xPosition+5)
            setDragging(true)
        }
        wrapperRef.current.addEventListener('touchstart', touch2Mouse,true )
        wrapperRef.current.addEventListener('touchmove', touch2Mouse,true )
        wrapperRef.current.addEventListener('mousedown',handleMouseDown,true)
        window.addEventListener('mousemove',handleMouseMove,true)

        return (()=>{
          wrapperRef.current.removeEventListener('mousedown',handleMouseDown,true)
          window.removeEventListener('mousemove',handleMouseMove,true)
        })
      },

      [ value, pct, dimensions ])

      
  return (
      <div className='row' ref={wrapperRef} style={{height:'30px', width: '100%'}}>
        <div className='left' style={{width: pct+'%' }}>{value*pct/100}</div>
        <div className='right' style={{width: (100-pct)+'%'}}>{value * ((100-pct)/100)}</div>
      </div>
  )
}

SplitDiv.propTypes = {
  /**
   * value that will be split between two sides
   */
  value: PropTypes.number,
  /**
   * the percent of the value that belongs on the left side
   */
  pct: PropTypes.number,
  /**
   * function called when pct changes
   */
  onChange: PropTypes.func,
};

SplitDiv.defaultProps = {
  value: 100,
  pct: 50,
  onChange: null
};