import React,{useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link,useNavigate } from 'react-router-dom'
import { getDatasets } from '../thunks/datasets'
import Spinner from '../components/Spinner'
import cloudIcon from '../images/cloud-icon.svg'


export default function Datasets() {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const datasets = useSelector(state => state.datasets);

  useEffect(() => {
    if (datasets.status==='empty') {
      dispatch(getDatasets());
    }
  }, [dispatch,datasets.status]);


  if (datasets.status!=='loaded') { 
    return (
      <div className="panel">
        <Spinner />
      </div>)
  }

  const gotoUpload = (e) => {
    navigate("/upload");
  }

  let list = (datasets.data.length === 0) 
    ? <tr><td>You have no datasets to view</td></tr> 
    : datasets.data.map((item, index) => {
      let status = 'Updating'
      if (item.lastChunkID===0) {
        status = 'Pending'
      } else if (item.lastChunkID===item.lastReadyChunk) {
        status = 'Ready'
      } 
      return (<tr key={index}>
        {item.lastReadyChunk===0?<td>{item.datasetName}</td>:
        <td><Link to={'/dataset/'+item.datasetID}>{item.datasetName}</Link></td>}
        <td>{item.datasetTypeName}</td>
        <td>{status}</td>
        <td>{new Date(item.lastChunkDate).toDateString()}</td>
        <td><Link to={'/shares/'+item.datasetID}>{item.numShares} users</Link></td>
        <td><Link to={'/append/'+item.datasetID}>Add Data</Link></td></tr>
        )
      })
  
  return (
    <div className="page-body">
      <h1>My Datasets</h1>
      <table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Last Update</th>
                <th>Share</th>
            </tr>
        </thead>
        <tbody>
           {list}
        </tbody>
      </table>
      <button onClick={gotoUpload}><img src={cloudIcon} alt="upload icon"/>Upload Data</button>
    </div>)
}