import React from 'react';
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from "react-redux";
import Spinner from '../components/Spinner'


export const ProtectedRoute = ({ children }) => {

  
  const user = useSelector(state => state.user);
  const location = useLocation()
       
  console.log(user)
  if (!user.checked || user.checking ) {
    return <Spinner />
  }

  if (!user.loaded || !user.profileLoaded) {
    return <Navigate to='/login' state={{ path: location.pathname }} />
  }

  return children
}
