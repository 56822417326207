import React from 'react';
import Dial from '../components/TimeDial'


export default function DialPage() {

  return (
    <div className="page-body">
      <h1>Dial3 Component</h1>
      <Dial ticks={60} />
    </div>)
}
