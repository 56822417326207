import { load_user, set_user_checking, set_user_checked, set_user_profile } from "../actions/constant";

const initialState = {
  checked: false,
  checking: false,
  loaded: false,
  loading: false,
  profile: {},
  profileLoaded:false,
  error:'',
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case load_user:
      return {
        ...state,
        loaded: action.payload.loaded,
        loading: action.payload.loading,
        error: action.payload.error,
        data: action.payload.data
      };

    case set_user_profile:
      return {
        ...state,
        profileLoaded: action.payload.profileLoaded,
        profile: action.payload.profile
      };

    case set_user_checking:
      return {
        ...state,
        checking: action.payload.checking
      };

    case set_user_checked:
      return {
        ...state,
        checked: action.payload.checked
      };
  
    default:
      return state;
  }
};