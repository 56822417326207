import React from 'react';
// import { Link } from 'react-router-dom'
import cloudIcon from '../images/cloud-icon.svg'
import { useNavigate } from 'react-router-dom'


export default function Reset(props) {

  const navigate = useNavigate()

  const gotoUpload = (e) => {
    navigate("/upload");
  }

  return (
    <div className="page-body">
      <h1>Dataset Types</h1>
      <table className="dictionary">
        <tbody>
        <tr>
          <td><h4 className="word">Single Column</h4></td>
          <td><p>This dataset has a single column of data points. Clarity will provide a visual analysis of the points including their value distribution and summary statistics.</p></td>
        </tr>
        <tr>
          <td><h4 className="word">Time Series</h4></td>
          <td><p>This dataset has two columns, a timestamp and a summable value. Clarity will make it easy to view the data for any time granularity and time window as well as compare two different time windows.</p></td>
        </tr>
        <tr>
          <td><h4 className="word">Order Items</h4></td>
          <td><p>This dataset contains item level data for a company's orders. Clarity will provide the Time Series view for this data plus provide an array of analyses that allow you to understand and view customer behavior.</p></td>
        </tr>
        </tbody>
      </table>
      <br/><br/>
      <button onClick={gotoUpload}><img src={cloudIcon} alt="upload icon"/>Upload Data</button>
      <br/><br/><br/><br/>
    </div>)
}