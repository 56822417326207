import axios from "axios";

export const request = async (method, path, body=null, credentials=true) => {
  
  const axiosConfig = {
    baseURL: 'https://api.clarityquick.com/',
    timeout: 180000, // 3 minute timeout
    responseType: "json",
    withCredentials: credentials
  };
  // const onSuccess = (response) => response
  // const onError = (error) => {
  //   return error
  // }

  const newInstance = axios.create(axiosConfig);
  return newInstance[method](path, body)//.then(onSuccess).catch(onError)
 
};
