import { setUser, setUserChecked, setUserChecking, setUserProfile } from "../actions/user";
import { setDatasets } from "../actions/datasets";

let timer = null;

export const createUser = (payload) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setUser({ loaded: false, loading: true, data: {}, error:'' }));

    const response = await remote(
      "post",
      `/user`,
      payload,
      "registering new user"
    );

    
    if (response.status === 200) {

      // now that user is registered, log them in
      dispatch(loginUser(payload));

    } else {
      dispatch(setUser({ loaded: false, loading:false, data: {}, error:response.data.message }));
    }

  } catch (error) {
    console.log(error);
    dispatch(setUser({ loaded: false, loading:false, data: {}, error:'create user failed, please try again' }));
  }
};


export const loginUser = (payload) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setUser({ loaded: false, loading:true, data: {}, error:'' }));

    const response = await remote(
      "post",
      `/user/login`,
      payload,
      "logging in new user"
    );
  
    if (response.status !== 200) {
      dispatch(setUser({ loaded: false, loading:false, data: {}, error:response.data.message }));
      return;
    } else {
      dispatch(setUser({ loaded: true, loading:false, data: response.data, error:'' }));
      if (!response.data.autoRenew) {
        clearTimeout(timer);
        let timeLeft = response.data.expires - Date.now()
        timer = setTimeout(() => { console.log('timer expired'); dispatch(checkUser())}, timeLeft+1000); 
      }
      dispatch(getUserProfile())
    }
  } catch (error) {
    console.log(error);
    dispatch(setUser({ loaded: false, loading:false, data: {}, error:'login failed, please try again' }));
  }
};


export const checkUser = () => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setUserChecking({ checking: true }));

    const response = await remote(
      "get",
      `/user/check`,
      null,
      "checking for logged in user"
    );

    if (response.status === 200) {
      if (!response.data.autoRenew) {
        clearTimeout(timer);
        let timeLeft = response.data.expires - Date.now()
        timer = setTimeout(() => { console.log('timer expired'); dispatch(checkUser())}, timeLeft+1000); 
      }
      dispatch(setUser({ loaded: true, loading:false, data: response.data, error:'' }));
      dispatch(getUserProfile())
    } else {
      dispatch(setUser({ loaded: false, loading:false, data: {}, error:'' }));
    }
    dispatch(setUserChecked({ checked: true }));
    dispatch(setUserChecking({ checking: false }));

  } catch (error) {
    console.log('error' ,error);
    dispatch(setUser({ loaded: false, loading:false, data: {}, error:'' }));
    dispatch(setUserChecked({ checked: true }));
    dispatch(setUserChecking({ checking: false }));
  }
};


export const logoutUser = () => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setUserChecking({ checking: true }));
    dispatch(setDatasets({ status:'empty', data:[], error:'' }));
    dispatch(setUser({ loaded: false, loading:false, data: {}, error:'' }));
    dispatch(setUserProfile({ profileLoaded: false, profile: {} }));

    await remote(
      "delete",
      `/user/logout`,
      null,
      "logging out user"
    );
    dispatch(setUserChecking({ checking: false }));

  } catch (error) {
    console.log('error' ,error);
    dispatch(setUser({ loaded: false, loading:false, data: {}, error:'' }));
    dispatch(setUserProfile({ profileLoaded: false, profile: {} }));
    dispatch(setUserChecking({ checking: false }));
  }
};


export const getUserProfile = () => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setUserProfile({ profileLoaded: false, profile: {} }));

    const response = await remote(
      "get",
      `/user/profile`,
      null,
      "getting user profile"
    );

    if (response.status === 200) {
      dispatch(setUserProfile({ profileLoaded: true, profile:response.data }));
      // console.log(history)
      // if (history.location?.state) {
      //   history.push(history.location.state.from)
      // } else {
      //   history.push("/datasets");
      // }
    } else {
      dispatch(setUserProfile({ profileLoaded: false, profile:{} }));
    }

  } catch (error) {
    console.log('error' ,error);
    dispatch(setUserProfile({ profileLoaded: false, profile: {}}));
  }
};


export const forgotUser = (payload) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setUser({ loaded: false, loading:true, data: {}, error:'' }));

    const response = await remote(
      "post",
      `/user/forgot`,
      payload,
      "requesting forgot password email"
    );

    if (response.status === 200) {
      dispatch(setUser({ loaded: false, loading:false, data: {}, error:'' }));
    } else {
      dispatch(setUser({ loaded: false, loading:false, data: {}, error:response.data.message }));
    }

  } catch (error) {
    console.log('error' ,error);
    dispatch(setUser({ loaded: false, loading:false, data: {}, error:'' }));
  }
};


export const resetPassword = (payload) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setUser({ loaded: false, loading:true, data: {}, error:'' }));

    const response = await remote(
      "post",
      `/user/reset`,
      payload,
      "resetting password"
    );

    if (response.status === 200) {
      dispatch(setUser({ loaded: false, loading:false, data: {}, error:'' }));
      history.push("/resetsuccess");
    } else {
      dispatch(setUser({ loaded: false, loading:false, data: {}, error:response.data.message }));
    }

  } catch (error) {
    console.log('error' ,error);
    dispatch(setUser({ loaded: false, loading:false, data: {}, error:'' }));
  }
};


export const updatePassword = (payload) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {

    dispatch(setUser({ loaded: true, loading:true, data: {}, error:'' }));

    const response = await remote(
      "put",
      `/user/password`,
      payload,
      "updating password"
    );

    if (response.status === 200) {
      dispatch(setUser({ loaded: true, loading:false, data: {}, error:'' }));
      history.push("/pwupdatesuccess");
    } else {
      dispatch(setUser({ loaded: true, loading:false, data: {}, error:response.data.message }));
    }

  } catch (error) {
    console.log('error' ,error);
    dispatch(setUser({ loaded: true, loading:false, data: {}, error:'' }));
  }
};