import React,{useEffect,useState} from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { request } from '../utils/request'
import { useNavigate,useParams,useLocation,useHistory } from 'react-router-dom'
import Spinner from '../components/Spinner'
import {Icon} from '../components/Icon'
import { isValidEmail } from '../utils/utils'
import toast from "react-hot-toast"


export default function Datasets() {

  
  const navigate = useNavigate()
  const location = useLocation()
  let { id } = useParams();
  const [email,setEmail] = useState('')
  // const [error,setError] = useState('')
  const [removing,setRemoving] = useState([])
  const [adding,setAdding] = useState(false)

  const queryClient = useQueryClient()
  
  const fetchDataset = ({ queryKey }) => {
    return request('get', `/datasets/${queryKey[1]}`,null,true)
  }
  
  const deleteShare = ({id,userid}) => {
    return request('delete', `/datasets/${id}/shares/${userid}`,null, true)
  }
  
  const postShare = ({id,email}) => {
    return request('post', `/datasets/${id}/shares`,{id,email},true)
  }

  // this function removes the userid from the removals array now that the removal is complete
  const removeEnd = ( e )=> { 
    let user = e.config.url.split('/')[4];
    setRemoving(old => {
      let temp = [ ...old]
      const index = temp.indexOf(user);
      if (index > -1) {
        temp.splice(index, 1); // 2nd parameter means remove one item only
      }
      return temp
    })
    toast.success('share removed')
    queryClient.invalidateQueries(['dataset',id])
  }

  const { mutate: removeShare } = useMutation(deleteShare,{
    onSuccess: removeEnd,
    onError: (e) => {
      console.log('error',{e})
      if (!e.response) {
        toast.error('Removing share failed. Please check network connection and try again.')
      }
      else if (e.response.status === 401) {
        navigate("/login"+location.search,{ state:{from:location}});
      }
      else toast.error(e.response.data.message)
      setAdding(false)
    }
  })


  const { mutate: addShare } = useMutation(postShare,{
    onSuccess: (e) => {
      queryClient.invalidateQueries(['dataset',id])
      toast.success('share added')
      setAdding(false) } ,
    onError: (e) => {
      console.log('error',{e})
      if (!e.response) toast.error('Adding share failed. Please check network connection and try again.')
      else if (e.response.status === 401) {
        navigate("/login"+location.search,{ state:{from:location}});
      }
      else toast.error(e.response.data.message)
      setAdding(false)
    }
  })


  const { data:dataset ,isError, isSuccess, error:fetchError } = useQuery(['dataset',id],fetchDataset,{
    keepPreviousData:true,
    staleTime:60000,
    onError: (e) => {
      console.log('error',{e})
      if (!e.response) toast.error('Getting shares failed. Please check network connection and try again.')
      else if (e.response.status === 401) {
        navigate("/login"+location.search,{ state:{from:location}});
      }
      else toast.error(e.response.data.message)
    }
    ,})


  if (isError) { 
    return (
      <div className="panel">
        <h3>{fetchError.message}</h3>
      </div>)
  }

  if (!isSuccess) { 
    return (
      <div className="panel">
        <Spinner />
      </div>)
  }

  function sendShare(e) {
    e.preventDefault()

    // validate fields
    if (!isValidEmail(email)) {
      toast.error('invalid email')
      return
    }
    setAdding(true)
    addShare({id,email})

    // dispatch(postShare(id, email));
  }

  function remove(e) {
    e.preventDefault()
    let userid = e.target.id.split('-')[1]
    setRemoving(list => [...list, userid])
    removeShare({id,userid})

  }

  function changeEmail(e) {
    setEmail(e.target.value)
  }

  const back = (e) => {
    navigate(-1);
  }

  let list = (dataset.data.dataset.shares.length === 0) 
    ?<tr><td>This dataset is not shared with anyone</td></tr>
    :dataset.data.dataset.shares.map((item, index) => {
      return (<tr key={index}>
        <td>{item.email}</td>
        <td>{false?<Spinner />:<button id={`remove-${item.userID}`} disabled={removing.includes(String(item.userID))} className='small' onClick={remove}>Remove</button>}</td></tr>)})
  
  return (
    <div className="page-body">
    <div className="row">
      <Icon onClick={back} type="left" active={false} />
      <h3>Shares for {dataset.data.dataset.datasetName}</h3>
      <p></p>
    </div>
      <table style={{textAlign:'center'}}>
        <thead>
            <tr>
                <th colSpan={2}>THIS DATASET IS SHARED WITH</th>
            </tr>
        </thead>
        <tbody>
           {list}
           <tr><td colSpan={2}><hr/></td></tr>
           {/* {error?<tr><td colSpan={2} className='error'>{error}</td></tr>: null} */}
          <tr>
            <td colSpan={2}>SHARE WITH ... <input type="text" name="email" placeholder="email" value={email} onChange={changeEmail} />&nbsp;&nbsp;&nbsp;&nbsp;{adding?<Spinner />:<button onClick={sendShare}>Send Share Notice</button>}</td>
          </tr>
        </tbody>
      </table>
    </div>)
}