import React, { useRef, useEffect } from 'react';
import { select, scaleBand, scaleLinear, axisLeft, axisBottom, format, max, line } from 'd3';
import { useResizeObserver } from '../hooks/useResizeObserver';
import { touch2Mouse, findAncestor, dollarFormat, months, roundNumber } from '../utils/utils'


export const BarPlot = ({ data, plotType, compareData, timeGrain, labelIndex, valueIndex, title, leftTitle, rightTitle }) => {

  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const dimensions = useResizeObserver(wrapperRef);
  
  useEffect(
    () => {

      const svg = select(svgRef.current)

      if (!data || data.length===0) {
        svg.selectAll('.bar').remove()
        svg.selectAll('.bar2').remove()
        svg.selectAll('.path').remove()
        svg.selectAll('.path2').remove()
        return
      }

      // create the plot data
      let len = data.length
      if (compareData && compareData.length>data.length) {
        len = compareData.length
      }
      let plotData = []
      for (let i =0;i<len;i++) {
        plotData[i] = []
        plotData[i][0] = i<data.length ? data[i][labelIndex] : 0
        if (valueIndex === null) {
          plotData[i][0] = i<data.length ? data[i] : 0
        } else {
          plotData[i][0] = i<data.length ? data[i][valueIndex] : 0
        }
        if (compareData) { 
          if (valueIndex === null) {
            plotData[i][1] = i<compareData.length ? compareData[i] : 0
          } else {
            plotData[i][1] = i<compareData.length ? compareData[i][valueIndex] : 0
          }
        }
      }
      let lineData = []
      let lineData2 = []
      let sum = 0
      if (plotType==='lineChart') {
        for (let i=0;i<plotData.length;i++) {
          sum += Number(plotData[i][0])
          lineData[i] = sum
        }
        sum = 0
        if (compareData) {
          for (let i=0;i<compareData.length;i++) {
            sum += Number(plotData[i][1])
            lineData2[i] = sum
          }
        }
      }
      
      const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

      let blue="#16B1FD"
      let green="#2E9F85"
      let red="#B93541"
      // let color6="#9776DA"
      // let purple="#6341f6"
      let tan="#F4BB45"


      let form = null;
      let bottomTitle = 'Years'
        if (timeGrain === 'year') {
          form = (i) => data[i].year
        } else if (timeGrain === 'month') {
          form = (i) => months[i]
          bottomTitle = 'Months'
        } else if (timeGrain === 'day') {
          form = (i) => i+1
          bottomTitle = 'Days'
        } else if (timeGrain === 'hour') {
          form = (i) => i
          bottomTitle = 'Hours'
        }

      // get the maximum label length and use it to determine the bottom margin
      let maxLabelLength = 0
      for (let i=0;i<data.length;i++){
        maxLabelLength = Math.max(maxLabelLength,form(i).toString().length)
      }
      let bottomMargin = 100
      if (maxLabelLength<8) {
        bottomMargin = 60;
      }
      
      let margin = ({top:30, right: 10, bottom:bottomMargin, left: 30});
      const tickDensity = width>780 ? 1 : 5

      let xAxis, yAxis = null;

      const x = scaleBand()
        .range([margin.left, width - margin.right])
        .domain(plotData.map((d,i) => i ))
        .padding(0.2)

      const invertX = (pixels) => {
        const totalBandwidth = (width - margin.left - margin.right) / (plotData.length)
        if (pixels<margin.left) return -1
        let index = Number(Math.floor((pixels-margin.left)/totalBandwidth))
        return index
      }

      let maxValue = 0;
      if (plotType==='lineChart') {
        if (compareData) {
          maxValue = Math.max(lineData[lineData.length-1],lineData2[lineData2.length-1])
        } else {
          maxValue = lineData[lineData.length-1]
        }
      } else {
        if (compareData) {
          maxValue = max(plotData, d => Math.max(Number(d[0]),Number(d[1])))
        } else {
          maxValue = max(plotData, d => Number(d[0]))
        }
      }
      
      let y = scaleLinear()
        .domain([0, (maxValue)]).nice()
        .range([height - margin.bottom, margin.top])

      xAxis = g => g
          .attr("transform", `translate(0,${height - margin.bottom + 3})`)
          .call(axisBottom(x)
                  .tickSize(0)
                  .tickValues(x.domain().filter(function(d,i){ return !(i%tickDensity)}))
                  .tickFormat(form))
                  // .tickFormat(labelFunction))
          .call(g => g.select(".domain").remove())
          
      yAxis = g => g
        .attr("transform", `translate(${width-10},0)`)
        .call(axisLeft(y).tickSize(width-margin.left-margin.right).ticks(2, format("3s")))
        .call(g => g.select(".domain").remove())
      
        if (plotType==='lineChart') {
          svg.selectAll('.bar').remove()
          svg.selectAll('.bar2').remove()
          svg.select("path")
          .datum(lineData)
          .attr("fill", "none")
          .attr("stroke",blue)
          .attr("stroke-width", 1.5)
          .attr("stroke-opacity", 1.0)
          .attr("d", line()
            .x(function(d,i) { return x(i)+x.bandwidth()/2 })
            .y(function(d,i) { return y(d) })
            )

          svg.selectAll('.path2').attr("stroke-opacity", 0.0)
          if (compareData) {
            svg.selectAll('.path2')
            .datum(lineData2)
            .attr("fill", "none")
            .attr("stroke",tan)
            .attr("stroke-width", 1.5)
            .attr("stroke-opacity", 1.0)
            .attr("d", line()
              .x(function(d,i) { return x(i)+x.bandwidth()/2 })
              .y(function(d,i) { return y(d) })
              )
          }
          
        } else {
          svg.select(".path").attr("stroke-opacity", 0.0)
          svg.select(".path2").attr("stroke-opacity", 0.0)
          svg.selectAll('.bar')
            .data(plotData)
            .join('rect')  
            .attr('class','bar')
            .attr("fill", blue)
            .style('transform','scale(1,-1')
            .attr("x", (d, i) => x(i))
            .attr("y", -y(0))
            .attr("width", compareData ? x.bandwidth()/2 : x.bandwidth())
            .attr("height", d => y(0) - y(Number(d[0])))
          
          if (compareData) {

            svg.selectAll('.bar2')
              .data(plotData)
              .join('rect')  
              .attr('class','bar2')
              .attr("fill", tan)
              .style('transform','scale(1,-1')
              .attr("x", (d, i) => x(i) + x.bandwidth()/2)
              .attr("y", -y(0))
              .attr("width", x.bandwidth()/2)
              .attr("height", d => y(0) - y(Number(d[1])))
          
            } else {
              svg.selectAll('.bar2').remove()
            }
          }

          svg.select('.y-axis')
            .call(yAxis)
            .select('.tick').remove()

          svg.select('.x-axis')
            .call(xAxis)
            .selectAll("text")
              .attr("y", 0)
              .attr("x", 9)
              .attr("dy", ".35em")
              .attr("transform", 'rotate(90)')
              .style("text-anchor", "start");
            
          svg.select('.title').remove()
          svg.append('text')
          .attr('class','title')
          .attr("x", width/2)
          .attr("y", 15)
          .attr("text-anchor", "middle")
          .text(title);

          svg.select('.leftTitle').remove()
          svg.append('text')
          .attr('class','leftTitle title')
          .attr("x", margin.left)
          .attr("y", 15)
          .attr("text-anchor", "start")
          .text(leftTitle);

          svg.select('.rightTitle').remove()
          svg.append('text')
          .attr('class','rightTitle title')
          .attr("x", width-margin.right)
          .attr("y", 15)
          .attr("text-anchor", "end")
          .text(rightTitle);

          svg.select('.bottomTitle').remove()
          svg.append('text')
          .attr('class','bottomTitle title-small')
          .attr("x", width/2)
          .attr("y", height-margin.bottom + 55)
          .attr("text-anchor", "middle")
          .text(bottomTitle);

        let p = findAncestor(svgRef.current,"plot")
        let t = p.querySelector('.tooltip')
        t.style.visibility = 'hidden'
        svg.selectAll('.highlight').remove()

        
        svg.on('touchstart', touch2Mouse,true )
        svg.on('touchmove', touch2Mouse,true )
        svg.on('touchout', touch2Mouse,true )

        const handle = (e) => {
          let x1 = e.clientX-svgRef.current.getBoundingClientRect().x
          let y1 = e.clientY-svgRef.current.getBoundingClientRect().y

          let index = invertX(x1)
          index = index>=plotData.length ? index=plotData.length-1: index
          if (index<0 || y1<margin.top || x1>width-margin.right) {
            t.style.visibility = "hidden"
            svg.selectAll('.highlight').remove()
            return
          }

          let htmlString = `<h5 class="title" style="color:#444">${form(index)}</h5>`;
          if (plotType==='lineChart') {
            htmlString += `<p class="value" style="color:${blue}">$ ${dollarFormat(lineData[index])}</p>`;
          } else {
            htmlString += `<p class="value" style="color:${blue}">$ ${dollarFormat(plotData[index][0])}</p>`;
          }
          let delta = 0
          let pct = 0
          if (compareData) {
            if (plotType==='lineChart') {
              htmlString += `<p class="value" style="color:${tan}">$ ${dollarFormat(lineData2[index])}</p>`;
              delta = lineData[index]-lineData2[index]
              pct = delta/lineData2[index]*100
              htmlString += `<p class="value" style="color:${delta>=0?green:red}">$ ${dollarFormat(delta)}</p>`;
              htmlString += `<p class="value" style="color:${delta>=0?green:red}">${delta>=0?'+':''} ${roundNumber(pct,2)}%</p>`;
            } else {
              htmlString += `<p class="value" style="color:${tan}">$ ${dollarFormat(plotData[index][1])}</p>`;
              delta = plotData[index][0]-plotData[index][1]
              pct = delta/plotData[index][1]*100
              htmlString += `<p class="value" style="color:${delta>=0?green:red}">$ ${dollarFormat(delta)}</p>`;
              htmlString += `<p class="value" style="color:${delta>=0?green:red}">${delta>=0?'+':''} ${roundNumber(pct,2)}%</p>`;
            }
          }
          if (t) {
            t.style.visibility = "visible"
            let left = 'unset'
            let right = 'unset'
            let position = x(index)+x.bandwidth()/2
            if (e.clientX<width/2) {
              left = position+5 + 'px'
            } else {
              right = width-position+10 + 'px'
            }
            t.style.left = left
            t.style.right = right
            t.innerHTML = htmlString;
          }
          svg.selectAll('.highlight').remove()
          if (plotType === 'lineChart') {
            svg.append('line')
                .attr('class','highlight')
                .attr('x1', x(index)+x.bandwidth()/2)
                .attr('y1', margin.top)
                .attr('x2', x(index)+x.bandwidth()/2)
                .attr('y2', height-margin.bottom)
                .attr('stroke',blue)
                .attr('fill','none')
            svg.append('circle')
                .attr('class','highlight')
                .attr('cx', x(index)+x.bandwidth()/2)
                .attr('cy', y(lineData[index]))
                .attr('r', 5)
                .attr('stroke',blue)
                .attr('fill','none')
            if (compareData) {
              svg.append('circle')
                  .attr('class','highlight')
                  .attr('cx', x(index)+x.bandwidth()/2)
                  .attr('cy', y(lineData2[index]))
                  .attr('r', 5)
                  .attr('stroke',tan)
                  .attr('fill','none')
            }
          } else {
            svg.append('rect')
                .attr('class','highlight')
                .attr('x', x(index))
                .attr('y', margin.top)
                .attr('width', x.bandwidth())
                .attr('height', height-margin.bottom-margin.top)
                .attr('fill-opacity',0.0)
                .attr('stroke','#000')
          }
        }
        svg.on('mousedown', handle,true )
        svg.on('mousemove', handle,true)
      },

      [ data, compareData, plotType, labelIndex, valueIndex, timeGrain, title, leftTitle, rightTitle, dimensions ])

      
  return (
    <div className="row" >
      <div className="plot" ref={wrapperRef} style={{height:'170px',marginRight:'10px'}}>
        <div className="tooltip" styles={{visibility:'hidden'}}></div>
        <svg ref={svgRef} style={{width:'100%',height:'100%'}}>
          <g className="x-axis" />
          <g className="y-axis" />
          <path className="path" />
          <path className="path2" />
        </svg>
      </div>
    </div>
  );
}