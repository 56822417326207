import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { isValidEmail, isValidPassword } from '../utils/utils';
import Checkbox from '../components/Checkbox'
import { loginUser } from '../thunks/user'
import Spinner from '../components/Spinner'
import toast, { Toaster } from "react-hot-toast"


export default function Login() {


  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()
  let [searchParams] = useSearchParams();
  let initialEmail = searchParams.get("email");

  const [password,setPassword] = useState('')
  const [email,setEmail] = useState('')
  const [checked,setChecked] = useState(true)

  const formElement = useRef(null);
  
  const user = useSelector(state => state.user);

  useEffect(()=>{if (initialEmail) setEmail(initialEmail)},[initialEmail])

  useEffect(() => {
    if (user.profileLoaded) {
      if(location?.state?.from){
        navigate(location.state.from)
      } else {
        navigate('/datasets')
      }
    }
  }
  ,[user.profileLoaded,navigate,location])
  

  function buttonPress(e) {
    e.preventDefault()

    // validate fields
    if (!isValidEmail(email)) {
      toast.error('invalid email')
      return
    }
    else if (!isValidPassword(password)) {
      toast.error('password must be between 5 and 30 characters long')
      return
    }

    dispatch(loginUser({ email:email, password:password, autoRenew:checked }));

  }

  function changeEmail(e) {
    setEmail(e.target.value)
  }

  function changePassword(e) {
    setPassword(e.target.value)
  }

  function changeChecked(e) {
    setChecked(e.target.checked)
  }

  return (
    <div className="page-body">
      <h1>Login</h1>
      <form  onSubmit={buttonPress} ref={formElement}>
        <input type="text" name="email" placeholder="email" value={email} onChange={changeEmail} />
        <input type="password" placeholder="password" value={password} onChange={changePassword} />
        {/* {error ? <p className='error'>{error}</p>: '' } */}
        {user.error !== '' ? <p className='error'>{user.error}</p>: '' }
        <Checkbox text="Keep me logged in on this device." checked={checked} onChange={changeChecked}/>
        <br/>
      {user.loading ? <Spinner />:<button>Login</button>}
      <br/><br/>
      <Link className="link-small" to="/forgot">Forgot Password?</Link>
      <hr/>
      <p>Don't yet have an account?</p>
      {/* this link will pass along the email to the signup page in the url if it exists */}
      <Link to={"/signup" + (email !== '' ? "?email=" + email : "")} state={location.state}>Sign up</Link>
        
      </form>
      <Toaster />
    </div>)
}